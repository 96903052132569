import { reactive } from "vue"
import { API, login, logout, model } from "../../model"
import { Button } from "../../ui/Button"
import { Input } from "../../ui/Input"
import { LosangeBackground } from "../../ui/LosangeBackground"
import { Screen } from "../../ui/Screen"
import { Spinner } from "../../ui/Spinner"
import { modal } from "../../ui/Modal"
import { Dialog } from "../../ui/Dialog"
import './Login.scss'
import { ERR, INFO } from "../../ui/Toasts"
import { validateEmail } from "../../util"
import { useRoute } from "vue-router"
import { router } from "../../main"
import { t } from "../../i18n"

export const Login = {
    setup() {
        model.token = localStorage.removeItem("token")

        const ui = reactive({
            email:"",password:"",
            loading:false,
        })

        const redirect = useRoute().query.redirect
        
        function forgotPassword() {
            const data = reactive({email:ui.email})
            modal(({close})=>
                <Dialog close={close} onSubmit={()=>close(data.email)}
                    header="Demander un nouveau mot de passe"
                    body={<>
                        <p>Votre demande sera immédiatement transmise à votre administrateur</p>
                        <Input autofocus label="Votre adresse email" value={data.email} onInput={e=>data.email=e.target.value} validate={validateEmail} />
                    </>}
                    buttons={<>
                        <Button secondary onClick={()=>close(null)}>Annuler</Button>
                        <Button type="submit" disabled={!validateEmail(data.email)}>Envoyer</Button>
                    </>}
                />
            ).then(async email=>{
                if(!email) return;
                await API.requestPasswordReset(email)
                INFO("Une demande de réinitialisation de votre mot de passe a été transmise à votre administrateur")
            })
        }

        return ()=><Screen>
            <div id="login" class="boom">

                <LosangeBackground/>
                <form onSubmit={e=>{
                    e.preventDefault(); 
                    if(!ui.email || !ui.password) return;
                    ui.loading = true;
                    login(ui.email, ui.password)
                        .then(async ()=>{
                            model.user = await API.getCurrentUser()
                            router.push(redirect || '/')
                        })
                        .catch(()=>ERR(t("Invalid email or password")))
                        .finally(()=>ui.loading=false)
                }}>
                <img src="/assets/astraia-logo.png"/>
                <div>
                    <Input name="email" autofocus label="Email" value={ui.email} onInput={({target:{value}})=>ui.email=value} validate={validateEmail}></Input>
                    <Input name="password" label="Password" type="password" value={ui.password} onInput={({target:{value}})=>ui.password=value}></Input>
                    <a onClick={forgotPassword}>Mot de passe oublié ?</a>
                    <Button type="submit" disabled={ui.loading || !validateEmail(ui.email) || !ui.password}>Login</Button>
                </div>
                </form>
            </div>
        </Screen>
    }
}