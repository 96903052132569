import { reactive } from '@vue/reactivity'
import { goTo } from '../../utils/routing'
import { Avatar } from '../../ui/Avatar'
import { Form } from '../../ui/Form'
import { validateEmail } from '../../util'
import { t } from '../../i18n'
import './NewUser.scss'
import { API } from '../../model'


export const NewUser = {
    setup() {
        const data = reactive({
            display_name:"",
            email:"",
        })

        function save() {
            API.createUser(data)
            goTo("/users")
        }

        function cancel() {
            goTo("/users")
        }

        return ()=><div id="new-user">
            <h1><Avatar user={data}/> {data.display_name}</h1>
            <Form object={data} fields={{
                    display_name:{name:t("Name")},
                    email:{validate:validateEmail},
                    avatar:{},
                }} 
                onChange={x=>Object.assign(data, x)}
                onSave={save}
                onCancel={cancel}
            />
        </div>
    }
}