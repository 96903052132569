import { API, hasRole, model } from "../model"
import { DataTable } from "../ui/DataTable"
import { t } from "../i18n"
import { Avatar } from "../ui/Avatar"
import { Spinner } from "../ui/Spinner"
import { AddButton } from "../ui/AddButton"
import { reactive } from "@vue/reactivity"
import { modal } from "../ui/Modal"
import { Dialog } from "../ui/Dialog"
import { Dropdown, DropdownItem } from "../ui/Dropdown"
import { capitalize } from "../util"
import { Button } from "../ui/Button"
import './Members.scss'
import { FilteredDropdown } from "../ui/FilteredDropdown"


const allowed_roles = ['manager', 'operator', 'analyst', 'project_manager']


export const Members = {
    setup() {
        const data = reactive({})

        function newMember() { newMemberDialog().then(data=>data && addMember(data)) }
        function addMember({user, role}) { API.addMember({user_id:user.id, scope_id:model.scopeId, role}) } 
        function removeMember({id}) { API.removeMember({id}) }
        function setRole({id}, role) { API.setMember(id, {role}) }

        const filter = members => members.filter(m=>!data.search || (
            m.user.display_name.toLowerCase().includes(data.search.toLowerCase()) ||
            m.role.toLowerCase().includes(data.search.toLowerCase()) 
        ))

        return ()=>{
            if(!model.members) return <Spinner/>
            const RW = hasRole("project_manager")
            return <div id="members">
                <DataTable
                    header={RW && <>
                        <AddButton secondary onClick={newMember}>{t("Add member")}</AddButton>
                    </>}
                    data={filter(model.members)}
                    rowClass={x=>!x.user.activated && "unactive"}
                    columns={[
                        {render:x=><Avatar user={x.user}/>},
                        {title:t('Name'), render:x=>x.user.display_name},
                        {title:t("Ajouté le"), type:"date", render:x=>x.created_at},
                        {title:t("Last login"), type:"date", render:x=>x.last_connection},
                        {title:t("Rôle"), type:"role", noLink:true, render:
                            x=>RW ? 
                            <Dropdown class='role' button={capitalize(t(x.role))}>
                                {allowed_roles.map(r=><DropdownItem onClick={()=>setRole(x,r)}>{capitalize(t(r))}</DropdownItem>)}
                            </Dropdown>
                            : t(x.role)
                        },
                    ]}
                    onDelete={RW && removeMember}
                    update={x=>data.search=x.search}
                />
            </div>
        }
    }
}



function newMemberDialog() {
    const queryUsers = async filter => API.getUsers({search:filter})

    const data = reactive({user:null, role:"manager"})

    return modal(({close})=>
        <Dialog class='new-member' close={close} onSubmit={()=>close(data)}
            header={t("Add scope member")}
            body={<>
                <p>
                    {t("User")}
                    <FilteredDropdown menuClass='choose-new-member'
                        query={queryUsers} onSelect={u=>data.user = u}
                        button={<div class="participant"><Avatar class='small' user={data.user}/>{data.user?.display_name || t("Select user...")}</div>}  
                        render={u=>u.id !== model.user.id && <div class="participant"><Avatar class='small' user={u}/>{u.display_name}</div>}
                    />
                </p>
                <p>
                    {t("Role")}
                    <Dropdown class='role' button={capitalize(t(data.role))}>
                        {allowed_roles.map(r=><DropdownItem onClick={()=>data.role=r}>{capitalize(t(r))}</DropdownItem>)}
                    </Dropdown>
                </p>
            </>}
            buttons={<>
                <Button secondary onClick={()=>close(null)}>{t("Cancel")}</Button>
                <Button type="submit" disabled={!data.user || !data.role}>{t("Add")}</Button>
            </>}
        />
    )
}