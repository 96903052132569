import { ICON } from "./icons";
import { IconButton } from "./Button";
import './Topbar.scss'
import { Dropdown, DropdownItem } from "./Dropdown";
import { model, logout } from "../model";
import { BreadCrumb } from "./BreadCrumb";
import { Avatar } from "./Avatar";
import { Badge } from "./Badge";
import { UI } from "../App";
import { t, setLang } from "../i18n";
import { link } from "../utils/routing";

export const Topbar = () => <div id="top">
    <div>
        <BreadCrumb/>
    </div>
    <div>
        {UI.dbg && <Badge>DEBUG MODE</Badge>}
        <IconButton onClick={()=>setLang("en")}>{ICON("flag-icon en")}</IconButton>
        <IconButton onClick={()=>setLang("fr")}>{ICON("flag-icon fr")}</IconButton>
        {model.user && 
            <Dropdown button={<><div class='user'>{model.user.display_name} <i>{t(model.scope?.role)}</i></div><Avatar user={model.user}/></>}>
                <DropdownItem to={link('/profile')}>{t("Profile")}</DropdownItem>
                <DropdownItem onClick={logout}>{t("Sign out")}</DropdownItem>
            </Dropdown>
        }
    </div>
</div>