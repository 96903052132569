import {reactive, onMounted, watchEffect} from 'vue'
import { Chain } from "../ui/Chain"
import { Donut } from "../ui/Donut";
import './Dashboard.scss'
import { Badge } from "../ui/Badge"
import { DataTable } from '../ui/DataTable';
import {readableNumber, animateNumber, wait, first_paragraph} from "../util";
import { optionsFromQueryParams, queryParams, setQueryParams, watchQueryParams } from "../utils/queryParams";
import { Spinner } from "../ui/Spinner";
import { model } from "../model";
import { ICON } from "../ui/icons";
import { LiveQuery } from "../api/livequery";
import { Recos } from "../ui/Recos";
import {t_n, t_n_fem, t} from '../i18n'
import { Button } from "../ui/Button";
import { PALETTE } from "../ui/ui"
import {Link} from "../utils/router";
import {link, gotoScope, scopeLink} from "../utils/routing";
import {Tags} from "../ui/Tags";
import {md} from "../ui/Markdown";


export const Dashboard = {
    setup() {
        
        const lq = LiveQuery("cases", `{
            items {
                id, scope_id, title, body, conclusion
                created_at, updated_at, 
                nb_alerts, type,
                recos_by_status, status, steps, tags
            }, 
            count, pages
        }`, c=>model.dashboard_cases=c,
        ()=>!!model.scopeId && (queryParams().sort || queryParams().order) && [{
            scope_id:model.scopeId,
            ...optionsFromQueryParams(),
            days: 30,
            is_public: true,
        }, {scope_id:model.scopeId}], null, true
        )
        
        LiveQuery("scope", `{
            cases_by_status, cases_by_type, recos_by_status, kpis,
        }`, c=>model.scope_stats=c,
        ()=>!!model.scopeId && [{id:model.scopeId}], "scope_stats", true
        )
        
        LiveQuery("reports", "{year, month}", 
        i=>model.reports=i?.map(({month,year})=>({month:(""+month).padStart(2,"0"),year:""+year})), 
        ()=>!!model.scopeId && [{
            scope_id:model.scopeId,
        }], null, true
        )
        
        // Enforce default sort/order
        watchQueryParams(({sort,order})=>{
            if(!sort && !order) setQueryParams({sort:"updated_at", order:"desc"})
        })
        
        const kpi = reactive({
            network:0,
            system:0,
            cloud:0,
        })
        
        function animateNumber(kpiName, number, time){
            let start = 0;
            const end = parseInt(readableNumber(number).substring(0,3))
            if(start >= end){
                return
            }
            let incrementTime = time / 100
            let timer = setInterval(() => {
                    start+=end/100
                    if(start > end) start = end
                    $(`#${kpiName}`).text(Math.floor(start) + readableNumber(number).substring(3))
                    if(start >= end) clearInterval(timer)
                }, incrementTime)
        }

        watchEffect(async ()=>{
            if(model.scope_stats?.kpis){
                animateNumber("agents", model.scope_stats.kpis?.agents, 750)
                animateNumber("network", model.scope_stats.kpis?.network, 750)
                animateNumber("cloud", model.scope_stats.kpis?.cloud, 750)
            }
        })
        

        return ()=>{
            const {scope, scope_stats} = model
            if(!scope || !scope_stats) return <Spinner/>



            const casesByStatus = [
                {label:t_n_fem(scope_stats.cases_by_status.open, "Case open"), data: scope_stats.cases_by_status.open, color:PALETTE.open, goTo: () => gotoScope("cases?closed=false&days=30&is_public=true")},
                {label:t_n_fem(scope_stats.cases_by_status.closed, "Case closed"), data: scope_stats.cases_by_status.closed, color:PALETTE.closed, goTo: () => gotoScope("cases?closed=true&days=30&is_public=true")},
            ]

            const casesByType = [
                {label:t_n_fem(scope_stats.cases_by_type.detection, "detection"), data: scope_stats.cases_by_type.detection, color:PALETTE.detection, showZero:true, goTo: () => gotoScope("cases?case_type=detection&days=30&is_public=true")},
                {label:t_n_fem(scope_stats.cases_by_type.patrol, "patrol"), data: scope_stats.cases_by_type.patrol, color:PALETTE.patrol, showZero:true, goTo: () => gotoScope("cases?case_type=patrol&days=30&is_public=true")},
                {label:t_n(scope_stats.cases_by_type.audit, "audit"), data: scope_stats.cases_by_type.audit, color:PALETTE.audit, showZero:true, goTo: () => gotoScope("cases?case_type=audit&days=30&is_public=true")},
                {label:t_n(scope_stats.cases_by_type.incident, "incident"), data: scope_stats.cases_by_type.incident, color:PALETTE.incident, showZero:true, goTo: () => gotoScope("cases?case_type=incident&days=30&is_public=true")},
            ]

            const recosByStatus = [
                {label:t_n(scope_stats.recos_by_status.open, "Recommendation pending"), data: scope_stats.recos_by_status.open, color:"#bbb", goTo: () => gotoScope("recommendations?days=30&is_public=true&closed=false")},
                {label:t_n(scope_stats.recos_by_status.closed, "Recommendation validated"), data: scope_stats.recos_by_status.closed, color:PALETTE.open, goTo: () => gotoScope("recommendations?days=30&is_public=true&closed=true")},
            ]

            return <div id="manager-dashboard">
                        <div><h1><span className="timespan"> {t("Last 30 days")}</span></h1></div>
                    <div id="charts">
                        <div class="kpis-container">
                        <div id='kpis'>
                            <KPI title={t("Network logs")} id="network" icon={ICON('network')} value={scope_stats.kpis.network > 0 ? "1" : ""} />
                            <KPI title={t("System logs")} id="agents" icon={ICON('computer')} value={scope_stats.kpis.agents > 0 ? "1" : ""} />
                            <KPI title={t("Cloud logs")} id="cloud" icon={ICON('cloud')} value={scope_stats.kpis.cloud > 0 ? "1" : ""}/>
                    </div>
                    </div>
                        <Donut class='responsive' data={casesByStatus} />
                        <Donut class='responsive' data={casesByType} />
                        { (scope_stats.recos_by_status.open||0) + (scope_stats.recos_by_status.closed||0) > 0 ? <Donut class='responsive' data={recosByStatus} /> : <div class='chart donut responsive no-box'><p>{t("No recommendation")}</p></div>}
                        {/* <img src={require('./risk.png').default} width="320px"/> */}
                    </div>
            
                    <div>
                        <DataTable
                        header={
                            model.reports?.length>0 && <Button secondary to={scopeLink("reports")}>{ICON("documents")} {t("Monthly reports")}</Button>
                        }
                        loading={lq.loading}
                        sortFields={{
                                created_at:t("first activity"),
                                updated_at:t("last activity"),
                                case_type:t("type"),
                                body:t("description"),
                                status:t("status"),
                                recommendations:t("recommendations"),
                                recommendations_pending:t("recommendations pending")
                        }}
                        data={model.dashboard_cases}
                        columns={[
                            {title:t('First activity'), type:'date', render:x=>x.created_at},
                            {title:t('Last activity'), type:'date', render:x=>x.updated_at},
                            {title:"Type", render:({type="detection", nb_alerts, tags})=><div class='type-column'>
                                <div class={{type:true, [type]:true}}>
                                    <span class={type}/>
                                    {t(type)}
                                    {!!nb_alerts && <Badge icon={ICON('alert')}>{nb_alerts}</Badge>}
                                </div>
                                <Tags tags={tags}/>
                            </div>},
                            {title:"Description", render:x=><div class='description'>
                                <div><Link href={link(`scope/${x.scope_id}/case/${x.id}`)}>{x.title}</Link></div>
                                <div v-html={md.render(first_paragraph(x.body))}/>
                                {!!x.conclusion && <div class='conclusion'>{ICON("case")} <span v-html={md.render(first_paragraph(x.conclusion))}/></div>}
                            </div>},
                            {title:t("Status"), type:'status-chain', render:Chain},
                            {title:t("Recommendations"), render:Recos},
                        ]}
                        emptyText={<div class="empty">{t("No case active in last 30 days")}</div>}
                    />
                    </div>
                </div>
        }
    }
}

export const KPI = ({title,value,icon,id}) => <div class={{withSparkline:Array.isArray(value), kpi:true}}>
    <div id={title} class='icon'>{icon}</div>
    <div class='title'>{title}</div>
    <div class='value'>{
        value !== "" ? <><text id={id}>{value}</text><small>evts</small></>
        : <p>Non déployé sur<br/>votre périmètre</p>
    }</div>
    
</div>

export const Sparkline = ({values}) => {
    const min = 0;
    const max = Math.max(...values)
    const d = "M0 100 L0 10 " + values.map((v,i)=>"L"+(i/values.length*32)+" "+((max-v)/(max-min)*10)+" ").join("") + " L32 100Z"
    return <div class="sparkline">
        <svg viewBox='1 0 29 10'><path d={d}/></svg>
    </div>
}
