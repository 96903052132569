import { reactive, watchEffect } from "@vue/runtime-core"
import { t } from "../i18n"
import { Button } from "./Button"

import './CodeEditor.scss'

export const CodeEditor = {
    props:["source", 'onSave','onCancel'],
    setup(props) {
        const data = reactive({
            value:props.source||"",
            dirty:false,
        })
        watchEffect(()=>{data.value = props.source})

        function save() {
            data.dirty = false
            props?.onSave?.(data.value)
        }

        function cancel() {
            data.value = props.source
            props?.onCancel?.()
        }

        return ()=>{
            return <div class="code-editor">
                <textarea spellcheck="false" value={data.value} onInput={e=>{data.dirty=true; data.value = e.target.value}}></textarea>
                <div>
                    <Button disabled={!data.dirty} onClick={save}>{t("Save")}</Button>
                    <Button secondary onClick={cancel}>{t("Cancel")}</Button>
                </div>
            </div>
        }
    }
}