import { t } from '../i18n';
import './Accordion.scss'
import { ICON } from './icons'

export const Accordion = (_,{slots}) => <div class="accordion">
    {slots?.default?.()}
  </div>

export const AccordionItem = {
  props:['icon', 'title'],
  setup(props, {slots}) {
    let el;
    let timeout;
    
    function init(e) {
      if(e) el = e;
    }

    function toggle() {
      if(!el) return;
      if(timeout) clearTimeout(timeout)
      if(el.classList.contains("collapsed")) {
        el.style.height = el.scrollHeight + "px"
        el.classList.remove("collapsed")
        el.parentElement.classList.remove("collapsed")
        timeout = setTimeout(()=>{
          el.removeAttribute("style")
          timeout = null;
        },225)
      } else {
        el.style.height = el.scrollHeight + "px"
        setTimeout(()=>{
          el.classList.add("collapsed")
          el.parentElement.classList.add("collapsed")
          el.removeAttribute("style")
        },10)
      }
    }

    return ()=>{
      const {title, icon} = props
      return <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button" onClick={toggle}>
          <div>{icon} {title}</div>{ICON("chevron-right")}
        </button>
      </h2>
      <div class="accordion-collapse" ref={init}>
        <div class="accordion-body">
          {slots?.default?.()}
        </div>
      </div>
      </div>
    }
  }
}




export const Section = ({icon,title,badges},{slots}) => <AccordionItem icon={icon} title={<><span>{t(title)}</span>{badges}</>}>
    {slots?.default?.()}
</AccordionItem>