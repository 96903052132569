import './Chain.scss'
import { capitalize, range } from "../util";
import { ICON } from "./icons";
import { Dropdown, DropdownItem } from "./Dropdown";
import { t } from '../i18n';


const decorateSteps = (steps, curstep, s) => {
    let i=0, curi;
    let out = []
    for(var s of steps) {
        if(s===null) i++;
        if(s===curstep) curi = i;
    }
    i=0;
    for(var s of steps) {
        if(s!==null) out.push([s,curi>i||(curi===i&&i===0),curi<i])
        else i++;
    }
    return out
}

export const Chain = ({N=4, steps,status,type, allowed_steps, onChange}) => {
    if(!steps) return;
    const allStepsNull = steps.filter(s=>!!s).length===0
    const curStep = [...steps].reverse().find(x=>!!x)
    return <div class="steps">
        {!allStepsNull && range(N-1).map(i=><Step key={i} showLine={i > 0 && steps.slice(0,i).filter(s=>!!s).length !== 0}
                  icon={t(steps[i])?.substr(0, 1).toUpperCase()}
                  done={isDone(status) || !!steps[i] || steps.slice(i+1).filter(s=>!!s).length !== 0}
                  color={(!isDone(status) && type === "incident") ? "red" : "yellow"}
            >{t(steps[i], {type: type + "-step", fem: ["patrol", "detection"].includes(type)})}</Step>
        )}

        {allowed_steps && onChange ? 

            <Dropdown button={
                <Step icon={StatusIcon(status)} showLine={N-1>0} done={isDone(status)}>{capitalize(t(status, {type, fem:["patrol","detection"].includes(type)}))}</Step>
            }>

                {decorateSteps(allowed_steps, curStep, steps)?.map(([x,prev,next])=><DropdownItem onClick={()=>onChange?.(x)}>
                    {prev && ICON("prev")}
                    {next && ICON("next")}
                    {x===curStep ? 
                        <b>{capitalize(t(x, {type:type+"-step",fem:["patrol","detection"].includes(type)}))}</b> : 
                            capitalize(t(x, {type:type+"-step",fem:["patrol","detection"].includes(type)}))
                    }
                </DropdownItem>)}
            </Dropdown>

            : 

            <Step icon={StatusIcon(status)} showLine={allStepsNull ? false : N-1 > 0} done={isDone(status)}>{t(status, {type, fem:["patrol","detection"].includes(type)})}</Step>
        }
    </div>
}


export const StatusIcon = (status)=>({
    "done": ICON("done"),
    "incident": <div style={{color:'#cb4b4b'}}>{ICON('incident')}</div>,
    "canceled": ICON("canceled"),
}[status] || ICON("busy"))

export const isDone = (status) => ["done","incident","canceled"].includes(status)

export const Step = ({icon, done, showLine, color="yellow"}, {slots}) => <div class={{"chain-step":true, done, hide:!icon, [color]:true}}>
    <div>{icon}</div>
    <p>{slots?.default?.()}</p>
    {showLine && <span class="line"/>}
</div>
