import { reactive, watchEffect } from "vue";
import { t } from "../i18n";
import { Button } from "./Button"
import './Editor.scss'
import { ICON } from "./icons";

const EDITOR_MODE = "CKEDITOR5"

export const Editor = {
    props:['onInput', 'source', 'onSave', 'saveButton', 'onCancel', 'onEditorInit', 'placeholder', 'fetchUsers', 'fetchObjects'],
    setup(props) {
        var editor = null;
        var editor_md = null;
        const data = reactive({
            data:props.source||"",
            editMode: "Rich_edit"
        })

        // @jfellus : Do we want to watch external source updates ? not sure...
        // watchEffect(()=>{
        //     var s = props.source
        //     if(editor) editor.setData(s)
        // })

        var updating = false;
        function onChange(el) {
            if(updating) return;
            updating = true;
            if(el===editor) editor_md?.value(editor.getData())
            else editor.setData(editor_md.value())
            props.onInput?.(editor.getData())
            updating = false;
        }

        function renderSuggestion({text}) {
            const [,type,id,title] = text.match(/#\[([a-z]+):([a-zA-Z0-9]+)\]\((.+)\)/)
            return $(`<div class="suggestion">
                <span>${ICON(type).el.outerHTML}</span>
                <span class="id">#${id}</span>
                <span>${title}</span>
            </div>`)[0]
        }


        function ckeditor(e) {
            if(EDITOR_MODE!=="CKEDITOR5") {
                $(e).text(data.data)
                $(e).on("input", ()=>{
                    data.data = e.value
                })
                return;
            }
            if(editor) return;
            ClassicEditor.create(e, {
                toolbar: {
					items: ['heading','|','bold','italic','underline','link','bulletedList','numberedList','|','outdent','indent','|','imageUpload','blockQuote','insertTable','undo','redo']
                },
                language: 'fr',
                table: {
					contentToolbar: [
						'tableColumn',
						'tableRow',
						'mergeTableCells'
					]
                },
                mention: {
                    feeds: [
                        {
                            marker: '@',
                            feed: props.fetchUsers,
                            minimumCharacters: 0
                        },
                        { 
                            marker:'#',
                            feed: props.fetchObjects,
                            minimumCharacters: 0,
                            itemRenderer:renderSuggestion
                        }
                    ].filter(({feed})=>!!feed)
                }
            } )
            .then(ed=>{
                editor=ed;
                ed.setData(props.source||"")
                editor.model.document.on( 'change:data', ()=>onChange(editor))
                editor.editing.view.document.on( 'change:isFocused', ( evt, data, isFocused ) => {
                    $(editor.ui.element).toggleClass("focused", isFocused)
                } );
                props.onEditorInit?.(editor)

                // Hook toolbar additions
                // const toolbar = $(editor.ui.view.toolbar.element).find(".ck-toolbar__items")
                // toolbar.append($(`<span class="ck ck-toolbar__separator"></span>`))
                // const btn = $(`<button class="ck ck-button toggle-edit-mode"/>`)
                // btn.text(data.editMode==="Rich_edit"?"Markdown":"Rich edit")
                // btn.click(toggleEditMode)
                // toolbar.append(btn)
            })
        }

        function simplemde(el) {
            if(!el) return;
            if(editor_md) return;
            editor_md = new SimpleMDE({
                element:el,
                toolbar: ["heading-1", "heading-2", "heading-3", "|", "bold", "italic", "strikethrough", "|", "unordered-list", "ordered-list", "quote", "code", "link", "image", "table", "|", "preview"],
                status:false,
                spellChecker: false,
            })
            editor_md.value(props.source||"")
            editor_md.codemirror.on("change", ()=>onChange(editor_md))

            // Hook toolbar additions
            const toolbar = $(editor_md.gui.toolbar)
            const btn = $(`<button class="ck ck-button toggle-edit-mode"/>`)
            btn.text(data.editMode==="Rich_edit"?"Markdown":"Rich edit")
            btn.click(toggleEditMode)
            toolbar.append(btn)
        }

        function getData() {
            if(editor) return editor.getData()
            else return data.data
        }

        function toggleEditMode(e) {
            data.editMode = data.editMode==="Rich_edit"?"Markdown":"Rich_edit"
            $(editor.ui.view.toolbar.element)
                .find(".toggle-edit-mode").text(data.editMode==="Rich_edit"?"Markdown":"Rich edit")
            $(editor_md.gui.toolbar)
                .find(".toggle-edit-mode").text(data.editMode==="Rich_edit"?"Markdown":"Rich edit")

            if(data.editMode==="Rich_edit") {
                setTimeout(()=>editor.ui.view.editable.element.focus(), 10)
            } else {
                setTimeout(()=>editor_md.codemirror.focus(), 10)
            }

            e.stopPropagation(); e.preventDefault();
        }

        return ()=><div class="editor" class={["mode-"+data.editMode]}>
            <textarea ref={ckeditor} placeholder={props.placeholder}></textarea>
            <textarea ref={simplemde} placeholder={props.placeholder}></textarea>
            <div class='controls'>
                {props.onSave && <Button onClick={()=>props.onSave(getData())}>{t(props.saveButton||"Save")}</Button>}
                {props.onCancel && <Button secondary onClick={()=>props.onCancel(getData())}>{t("Cancel")}</Button>}
            </div>
        </div>
    }
}
