import { reactive } from "vue";
import { useRoute } from "vue-router";
import './css/App.scss'
import './ui/ui.scss'
import { Menu } from "./ui/Menu";
import { Topbar } from "./ui/Topbar";
import { model, connect, hasRole, init_livequeries } from "./model";
import { Toasts } from "./ui/Toasts";
import { Spinner } from "./ui/Spinner";
import { Screen } from "./ui/Screen";
import { DBG } from "./ui/DBG";
import { NotFound } from "./ui/NotFound";
import { goTo, gotoScope } from "./utils/routing";
import {graphql} from "./api/graphql";

window.addEventListener("scroll", ()=>document.body.classList.toggle("scrolled", window.pageYOffset))


export const UI = reactive({
    loading:false,
    dbg:false,
})


let ROUTES = []
export const getRouteDef = (route) => ROUTES.find(r=>r.path===route.matched[0]?.path)
export function setRoutes(r) { ROUTES = r; }


export const App = {
    setup() {
        const route = useRoute()
        
        init_livequeries();

        if(localStorage.getItem("token")) connect(localStorage.getItem("token"))
        else model.user = null;

        return ()=>{
            const {anonymous, menu, multiscope, admin, name, role} = getRouteDef(route) || {}

            if(!route.matched[0] || model.user===false) return <Screen><Spinner/></Screen>
            if(!model.user && !anonymous) {
                let redirect = route.redirectedFrom?.fullPath || route.fullPath
                if(["/", "/scopes", "/fr/scopes", "/en/scopes", "/fr/login", "/en/login", "/login"].includes(redirect)) redirect = null
                else redirect = encodeURIComponent(redirect)
                goTo("/login" + (redirect ? `?redirect=${redirect}`: ""))
                return;
            }

            if(model.user && !model.scopes) return <Screen><Spinner/></Screen>
            if(model.scopes?.length<1 && name!=="noscope" && !model.user.is_admin) return goTo("noscope")
            if((admin && !model.user.is_admin) || (role && role==='supervisor' && (!model.user.is_supervisor && !model.user.is_admin))) return goTo("/")
            if(multiscope && (model.scopes?.length<2 && !model.user.is_admin && !model.user.is_supervisor)) return goTo(`/scope/${model.scopes[0].id}/`)

            if(model.scope && role && !hasRole(role)) return gotoScope("/")

            if (graphql.is404) return <NotFound/>

            return <div>
                {(!anonymous && menu!==false) ? <>
                    <Topbar/>
                    <Menu/>
                    <div id="page">
                        <router-view/>
                    </div>
                </> : <router-view/>}
                {UI.dbg && <DBG/>}
                <Toasts/>
            </div>
        }
    }
}