import { t } from '../i18n';
import { queryParams, setQueryParams } from '../utils/queryParams';
import './Filter.scss'
import { ICON } from './icons';
import { Switch } from './Switch';

export const Filter = ({type, data, title, render, defaultFilter=[]})=>{
    if(!data || !render) return;
    return <div class="filter">
        <h1>{t(title)}</h1>
        {Object.keys(data).map(k=>{
            const filter = queryParams()[type]?.split(",") || defaultFilter
            const show = filter.includes(k)

            function toggle () {
                const q = {}
                q[type] = (show ? filter.filter(x=>x!==k) : [...filter, k]).join(",")
                q.page = 1
                setQueryParams(q)
            }

            const [icon,title] = render(k) || []
            return <div>
                    <b>{icon} {data[k]}</b>{t(title,{nb:data[k]})}
                    <Switch value={show} disabled={show && filter.length<=1} onClick={toggle}>{ICON(show ? "eye" : "no-eye")}</Switch>
                </div>
        })}
    </div>
}
