import { range } from '../util'
import './Avatar.scss'

export const Avatar = ({user}) => {
    if(!user) return null;
    const x = initials(user.display_name)
    return <div class='avatar' style={{background:color(x)}}>
        {user?.avatar ? <img src={user?.avatar}/>
        : <span class={`n-${x?.length}`}>{x}</span>}
    </div>
}

const initials = name => name?.split(" ").map(x=>x[0]).slice(0,3).join("").toUpperCase()

const color = initials => PALETTE[initials?.charCodeAt(0) % PALETTE.length]
const PALETTE = range(50).map(i=>`hsl(${i/50*360}, 40%, 68%)`)