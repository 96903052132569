import { model, API, hasRole } from "../model"
import { DataTable } from "../ui/DataTable"
import { optionsFromQueryParams, queryParams, setQueryParams } from "../utils/queryParams"
import { t } from "../i18n"
import { LiveQuery } from "../api/livequery"
import { ICON } from "../ui/icons"
import './Recommendations.scss'
import { createTagDialog, Tags } from "../ui/Tags"
import { Severity } from "../ui/Severity"
import {gotoScope} from "../utils/routing"
import {Link} from "../utils/router"
import {link} from "../utils/routing"
import { AddButton } from "../ui/AddButton"
import { ScopeItem } from "./Alerts"
import { reactive } from "@vue/reactivity"
import { RecommendationStatus } from "./Recommendation"
import { Checkbox } from "../ui/Checkbox"
import { Switch } from "../ui/Switch";
import { Tabs, Tab } from "../ui/Tabs";
import {Badge} from "../ui/Badge";
import {Dropdown, DropdownItem} from "../ui/Dropdown";
import {md} from "../ui/Markdown";

export const Recommendations = {
    setup() {
        const data = reactive({
            show_privates: !queryParams().is_public,
            days: parseInt(queryParams().days),
        })

        const lq = LiveQuery("recommendations", `{
            count,
            items { 
                id, scope_id, title, body, priority, tags, created_at, updated_at, status, is_public
            }
        }`, r=>model.recommendations=r,
            ()=>[{
                scope_id:model.scopeId,
                sort:"updated_at",
                order:"desc",
                closed: queryParams().closed === "true" ? true : queryParams().closed === "false" ? false : undefined,
                is_public: queryParams().is_public === "true" ? true : queryParams().is_public === "false" ? false : undefined,
                days: parseInt(queryParams().days),
                ...optionsFromQueryParams(),
            }, {scope_id:model.scopeId}],
            null, true
        )

        LiveQuery("recommendations_by_status", "", a=>data.recommendations_by_status=a,
            ()=>[{
                scope_id: model.scopeId,
                is_public: queryParams().is_public === "true" ? true : queryParams().is_public === "false" ? false : undefined,
                days: parseInt(queryParams().days),
            }],
            null, true
        )

        function addTag(r, x, color) { API.addTag("recommendation", r.id, x, color) }
        function deleteTag(r, x) { API.deleteTag("recommendation", r.id, x) }
        function createTag(a, tag, color) { createTagDialog(tag, color).then(({tag, color})=>addTag(a, tag, color)) }

        function togglePublic(c) { API.setRecommendation(c.id, {is_public:!c.is_public})}

        function newRecommendation() { gotoScope("newrecommendation") }

        const selectableDays = {
            100: `${t("In")} 100 ${t("last days")}`,
            30: `${t("In")} 30 ${t("last days")}`,
            15: `${t("In")} 15 ${t("last days")}`,
            7: `${t("In")} 7 ${t("last days")}`,
            2: `${t("In")} 2 ${t("last days")}`,
            1: t("Today")
        }

        return ()=>{
            return <div id="recommendations">
            <DataTable
                headerLeft={<div>
                    <Tabs>
                        <Tab active={queryParams().closed === undefined}
                             label={<span>{t("reco:All")}<Badge>{data.recommendations_by_status?.total}</Badge></span>}
                             onClick={()=>setQueryParams({closed: null, page: 1})}
                        />
                        <Tab active={queryParams().closed === 'false'}
                             label={<span>{t("reco:Open", {nb: data.recommendations_by_status?.open})}<Badge>{data.recommendations_by_status?.open}</Badge></span>}
                             onClick={()=>setQueryParams({closed: 'false', page: 1})}
                        />
                        <Tab active={queryParams().closed === 'true'}
                             label={<span>{t("reco:Closed", {nb: data.recommendations_by_status?.closed})}<Badge>{data.recommendations_by_status?.closed}</Badge></span>}
                             onClick={()=>setQueryParams({closed: 'true', page: 1})}
                        />
                    </Tabs>
                    <Dropdown button={data.days ?
                        <span>{t("Active")} {selectableDays[data.days].toLowerCase()}</span> :
                        <span>{t("reco:All")}</span>
                    }>
                        <DropdownItem onClick={() => {
                            data.days = null
                            setQueryParams({days: null, page: 1})
                        }}>
                            {t("reco:All")}
                        </DropdownItem>
                        {Object.keys(selectableDays).map((k) => {
                            return <DropdownItem
                                onClick={()=>{
                                    data.days = k
                                    setQueryParams({days: k, page: 1})
                                }
                                }>
                                {selectableDays[k]}
                            </DropdownItem>
                        })}
                    </Dropdown>
                    {hasRole("analyst") && <Switch value={data.show_privates} onClick={() => {
                        data.show_privates = !data.show_privates
                        if (data.show_privates) setQueryParams({is_public: null, page: 1})
                        else setQueryParams({is_public: true, page: 1})
                    }}>{t("Show privates")}</Switch>}
                </div>}
                header={<>
                    {hasRole("analyst") && model.scopeId && <AddButton secondary onClick={newRecommendation}>{t("New Recommendation")}</AddButton>}
                </>}
                loading={lq.loading}
                rowClass={x=>!x.is_public && "private"}
                data={model.recommendations}
                columns={[
                    ...(model.scope ? [] : [{title:t("Scope"), type:'scope', render:ScopeItem}]),
                    {title:t('First activity'), type:'date', render:x=>x.created_at},
                    {title:t('Last activity'), type:'date', render:x=>x.updated_at},
                    {title:t("Status"), type:'status', render:x=><RecommendationStatus status={x.status}/>},
                    hasRole("analyst") && {title:"Public", render:x=><Checkbox value={x.is_public} onClick={()=>togglePublic(x)}/>},
                    {title:"Description", type:"description", render:x=><div class='description'>
                        <div><Link href={link(`scope/${x.scope_id}/recommendation/${x.id}`)}>{ICON("recommendation")} {x.title}</Link></div>
                        <div class="body" v-html={md.render(x.body)}/>
                    </div>},
                    {title:t('Priority'), render:x=><Severity val={x.priority}/>},
                    {title:t('Tags'), type:"tags", noLink:true, render:a=>
                        <Tags tags={a.tags} allowed_tags={model.scope?.tags}
                            editable={hasRole("analyst")}
                            onAdd={(x,color)=>addTag(a, x, color)}
                            onDelete={x=>deleteTag(a, x)}
                            onCreate={(x,color)=>createTag(a, x,color)}
                        />
                    },
                ]}

                sortFields={{
                    updated_at:t("last activity"),
                    created_at:t("first activity"),
                    body:t("description"),
                    status:t("status"),
                    priority:t("priority"),
                    ...(model.scope ? {} : {scope_id:t("scope")})
                }}
                defaultSort={{sort:"updated_at", order:"desc"}}
            />
        </div>
        }
    }
}