import { API, hasRole, model } from "../model"
import { Badge } from "../ui/Badge"
import { DataTable } from "../ui/DataTable"
import { ICON } from "../ui/icons"
import { optionsFromQueryParams, queryParams, setQueryParams } from "../utils/queryParams"
import { t } from "../i18n"
import { Chain } from "../ui/Chain"
import { ScopeItem } from "./Alerts"
import './Cases.scss'
import { LiveQuery } from "../api/livequery"
import { Recos } from "../ui/Recos"
import { Link } from "../utils/router"
import { link, gotoScope } from "../utils/routing"
import { Button } from "../ui/Button"
import { reactive } from "@vue/reactivity"
import { createTagDialog, Tags } from "../ui/Tags"
import { first_paragraph } from "../util"
import { Checkbox } from "../ui/Checkbox"
import {Tab, Tabs} from "../ui/Tabs"
import {Switch} from "../ui/Switch";
import { Dropdown, DropdownItem } from '../ui/Dropdown'
import {Filter} from "../ui/Filter";
import {md} from "../ui/Markdown";

export const Cases = {
    setup() {
        const data = reactive({
            show_privates: !queryParams().is_public,
            days: parseInt(queryParams().days),
        })

        const lq = LiveQuery("cases", `{
            count, pages,
            items {
                id, scope_id, title, body, conclusion, type, created_at, updated_at, scope_id, tags, status, steps, recos_by_status, is_public,
                related_cases{count, items{title}} 
            }
        }`, c=>model.cases=c,
            ()=>[{
                scope_id:model.scopeId,
                sort:"updated_at",
                order:"desc",
                closed: queryParams().closed === "true" ? true : queryParams().closed === "false" ? false : undefined,
                is_public: queryParams().is_public === "true" ? true : queryParams().is_public === "false" ? false : undefined,
                days: parseInt(queryParams().days),
                case_type: queryParams().case_type?.split(","),
                ...optionsFromQueryParams(),
            }, {scope_id:model.scopeId}],
            null, true
        )

        LiveQuery("cases_by_status", "", a=>data.cases_by_status=a,
            ()=>[{
                scope_id: model.scopeId,
                is_public: queryParams().is_public === "true" ? true : queryParams().is_public === "false" ? false : undefined,
                days: parseInt(queryParams().days),
                case_type: queryParams().case_type?.split(","),
            }],
            null, true
        )

        LiveQuery("cases_by_type", "", a=>data.cases_by_type=a,
            ()=>[{
                scope_id: model.scopeId,
                is_public: queryParams().is_public === "true" ? true : queryParams().is_public === "false" ? false : undefined,
                closed: queryParams().closed === "true" ? true : queryParams().closed === "false" ? false : undefined,
                days: parseInt(queryParams().days),
            }],
            null, true
        )

        function newCase() { gotoScope("newcase") }

        function addTag(c, t, color) { API.addTag("case", c.id, t, color) }
        function deleteTag(c, t) { API.deleteTag("case", c.id, t) }
        function createTag(a, tag, color) { createTagDialog(tag, color).then(({tag, color})=>addTag(a, tag, color)) }

        function togglePublic(c) { API.setCase(c.id, {is_public:!c.is_public})}

        const selectableDays = {
            100: `${t("In")} 100 ${t("last days")}`,
            30: `${t("In")} 30 ${t("last days")}`,
            15: `${t("In")} 15 ${t("last days")}`,
            7: `${t("In")} 7 ${t("last days")}`,
            2: `${t("In")} 2 ${t("last days")}`,
            1: t("Today")
        }

        return ()=>{
            return <div id="cases">
                <Filter type='case_type' data={data.cases_by_type}
                        render={x=>({
                            "detection":[ICON('alert'), "Detection"],
                            "patrol":[ICON('search'), "Patrol"],
                            "audit":[ICON('coordination'), "Audit"],
                            "incident":[ICON('incident'), "Incident"],
                        }[x])}
                        defaultFilter={["detection", "patrol", "audit", "incident"]}
                />
                <DataTable
                    headerLeft={<div>
                        <Tabs>
                            <Tab active={queryParams().closed === undefined}
                                 label={<span>{t("case:All")}<Badge>{data.cases_by_status?.total}</Badge></span>}
                                 onClick={()=>setQueryParams({closed: null, page: 1})}
                            />
                            <Tab active={queryParams().closed === 'false'}
                                 label={<span>{t("case:Open", {nb: data.cases_by_status?.open})}<Badge>{data.cases_by_status?.open}</Badge></span>}
                                 onClick={()=>setQueryParams({closed: 'false', page: 1})}
                            />
                            <Tab active={queryParams().closed === 'true'}
                                 label={<span>{t("case:Closed", {nb: data.cases_by_status?.closed})}<Badge>{data.cases_by_status?.closed}</Badge></span>}
                                 onClick={()=>setQueryParams({closed: 'true', page: 1})}
                            />
                        </Tabs>
                        <Dropdown button={data.days ?
                            <span>{t("Active")} {selectableDays[data.days].toLowerCase()}</span> :
                            <span>{t("case:All")}</span>
                        }>
                            <DropdownItem onClick={() => {
                                data.days = null
                                setQueryParams({days: null, page: 1})
                            }}>
                                {t("case:All")}
                            </DropdownItem>
                                {Object.keys(selectableDays).map((k) => {
                                return <DropdownItem
                                    onClick={()=>{
                                        data.days = k
                                        setQueryParams({days: k, page: 1})
                                    }
                                }>
                                    {selectableDays[k]}
                                </DropdownItem>
                            })}
                        </Dropdown>
                        {hasRole("analyst") && <Switch value={data.show_privates} onClick={() => {
                            data.show_privates = !data.show_privates
                            if (data.show_privates) setQueryParams({is_public: null, page: 1})
                            else setQueryParams({is_public: true, page: 1})
                        }}>{t("Show privates")}</Switch>}
                    </div>}
                    header={<>
                        {hasRole("analyst") && model.scopeId && <Button secondary onClick={newCase}>{ICON("add")} {t("New case")}</Button>}
                    </>}
                    loading={lq.loading}
                    data={model.cases}
                    rowClass={x=>!x.is_public && "private"}
                    columns={[
                        ...(model.scope ? [] : [{title:t("Scope"), render:ScopeItem}]),
                        {title:t('First activity'), type:'date', render:x=>x.created_at},
                        {title:t('Last activity'), type:'date', render:x=>x.updated_at},
                        {title:"Type", type:'type', render:({type="detection", nb_alerts})=><div class={{type:true, [type]:true}}>
                            <span class={type}/>
                            {t(type)}
                            {!!nb_alerts && <Badge icon={ICON('alert')}>{nb_alerts}</Badge>}
                        </div>},
                        hasRole("analyst") && {title:"Public", render:x=><Checkbox value={x.is_public} onClick={()=>togglePublic(x)}/>},
                        {title:"Description", type:'description', render:(x)=><div class='description'>
                            <div><Link href={link(`scope/${x.scope_id}/case/${x.id}`)}>{x.title}</Link></div>
                            <div v-html={md.render(first_paragraph(x.body))}/>
                            {!!x.conclusion && <div class='conclusion'>{ICON("case")} <span v-html={md.render(first_paragraph(x.conclusion))}/></div>}
                        </div>},
                        {title:t("Status"), type:'status-chain', render:Chain},
                        {title:t('Incidents'), render:x=>x.type!=='incident' && x.related_cases?.count > 0 && <span title={x.related_cases.items[0].title}>{ICON("incident")} {x.related_cases?.count}</span>},
                        {title:t("Recommendations"), render:Recos},
                        {title:t('Tags'), type:"tags", noLink:true, render:a=>
                        <Tags tags={a.tags} allowed_tags={model.scope?.tags}
                            editable={hasRole("analyst")}
                            onAdd={(x, color)=>addTag(a, x, color)}
                            onDelete={x=>deleteTag(a, x)}
                            onCreate={(x,color)=>createTag(a, x, color)} 
                        />
                    },
                    ]}
                    
                    
                    sortFields={{
                        created_at:t("first activity"),
                        updated_at:t("last activity"),
                        case_type:t("type"),
                        body:t("description"),
                        status:t("status"),

                        recommendations:t("recommendations"),
                        recommendations_pending:t("recommendations pending"),
                        ...(model.scope ? {} : {scope_id:t("scope")})
                    }}
                    defaultSort={{sort:"updated_at", order:"desc"}}
                />
            </div>
        }
    }
}