import { queryParams, setQueryParams } from "../utils/queryParams"
import { debounce, max, min, range } from "../util"
import { D } from "./dates"
import './DataTable.scss'
import { ICON } from "./icons"
import { Input} from './Input'
import { t } from "../i18n"
import { Select, SelectItem } from "./Select"
import { DeleteButton } from "./Button"
import { reactive } from "@vue/reactivity"
import { watch } from "@vue/runtime-core"
import { Checkbox } from "./Checkbox"

export const DataTable = {
    props: ['selection', 'buttons', 'noHeader', 'defaultSort', 'defaultFilter', 'loading', 'limit', 'columns', 'emptyText', 'sortFields', 'rowClass', 'data', 'page', 'setPage', 'onRowClick', 'onDelete', 'update', 'header', 'noToolbar', 'footer', 'headerLeft', 'collapse', 'noSearch', 'subrow'],
    setup(props) {
        const ui = reactive({search:"", sort:null, order:null, page:1, collapsed:null, hover:null})
        
        watch(ui,()=>props.update?.(ui))

        return ()=>{
            let {
                columns,
                sortFields,
                data,
                noHeader, noToolbar, noSearch
            } = props

            const noQueryParams = !!props.update

            if(!columns) { columns=[{render:x=>x}]; noHeader = true }
            columns = columns.filter(c=>!!c)

            columns = columns.filter(c=>!!c)

            let {page, search, order, sort:sortField} = ui

            if(props.setPage) page = props.page

            if(!noQueryParams) {
                page = props.page || parseInt(queryParams().page) || 1
                search = queryParams().search || ""
                sortField = queryParams().sort
                order = queryParams().order==="desc" ? "desc" : "asc"
            }

            if(!sortField) {
                sortField = props.defaultSort?.sort
                order = queryParams().order==="desc" ? "desc" : queryParams().order==="asc" ? "asc" : props.defaultSort?.order
            } 

            sortField = sortFields && Object.keys(sortFields).find(x=>x===(sortField))

            const [items, pages] = Array.isArray(data) ? [data, 1] : data ? [data.items, data.pages] : [[],1]

            if(pages>1) {
                if(page>pages) setPage(pages)
                else if(page<1) setPage(1)
            }

            const setSearch = debounce((search) => {
                ui.collapsed = null
                if(noQueryParams) {
                    ui.search = search
                    ui.page = 1
                }
                else setQueryParams({search, page: 1})
            },20)

            function toggleOrder() {
                if(noQueryParams) ui.order = order==="asc"?"desc":null
                else setQueryParams({order:order==="asc"?"desc":"asc"})
            }

            function setPage(page) {
                ui.collapsed = null
                if(props.setPage) props.setPage(page)
                else if(noQueryParams) ui.page = page
                else setQueryParams({page})
            }
            
            function setSort(sort) {
                if(noQueryParams) ui.sort = sort
                else setQueryParams({sort})
            }

            function toggleSelectItem({id}) {
                if(props.selection.includes(id)) props.selection.splice(props.selection.indexOf(id),1)
                else props.selection.push(id)
            }

            function toggleSelectAll() {
                if(props.selection?.length === items?.length) props.selection.splice(0, props.selection.length)
                else {
                    props.selection.splice(0, props.selection.length, ...items?.map(({id})=>id)||[])
                }
            }

            return <div class="datatable" class={{multipage:pages>1, loading:props.loading}}>
            {!noHeader && !noToolbar && <div class="header">
                {props.headerLeft && <span class='left'>{props.headerLeft}</span>}
                {props.buttons}
                {!noSearch && <Input class="search" placeholder={t("Search...")} value={search} onInput={e => setSearch(e.target.value)} pre={ICON("search")}/>}
                {sortFields && <Select class={{order:true, desc:order==="desc"}}
                    value={sortField && (t("Sort by") + " "+sortFields[sortField])} placeholder={t("Sort by")}
                    post={<button class="sort-by" onClick={toggleOrder}>{ICON("sort")}</button>}>
                        {Object.entries(sortFields).map(([k,v])=> 
                         <SelectItem onClick={()=>setSort(k)}>{t('Sort by')} {v}</SelectItem>
                        )}
                </Select>}
                {props.header}
            </div>}
            <div><table class={{empty:!items?.length}}>
                {!noHeader && <thead>
                    <tr>
                        {props?.collapse && <th class='collapse-head'/>}
                        {props?.selection && <th title={t("Select all")}><Checkbox value={props.selection.length && props.selection.length===items?.length} onClick={toggleSelectAll} /></th>}
                        {columns.map(c=><th class={[c.type]}>{c.title||""}</th>)}
                        {props.onDelete && <th/>}
                    </tr>
                </thead>}
                <tbody>
                    {items?.length ? items.map((x,i)=>
                        <><tr key={x.id||i} class={props?.rowClass?.(x)} class={{hover: ui.hover === i}} onmouseover={()=>ui.hover = i} onmouseout={()=>ui.hover = null}  onClick={()=> {
                            if (props?.collapse?.(x)) {
                                if (ui.collapsed === i) ui.collapsed = null
                                else ui.collapsed = i
                            }
                        }}>

                            {props?.collapse && <td>
                                <div>
                                    {props?.collapse?.(x) && (ui.collapsed === i ? ICON("chevron-down") : ICON("chevron-right"))}
                                </div>
                            </td>
                            }

                            {props?.selection && 
                                <td class="noLink checkbox" onClick={()=>toggleSelectItem(x)}>
                                <div>
                                    <Checkbox value={props.selection.includes(x.id)}/>
                                </div>
                                </td>
                            }

                            {columns.map(({type,render=x=>x}, j)=><td class={[type]}>
                                <div>{renderField(type, render(x))}</div>
                            </td>)}
                            {props.onDelete && <td class="noLink delete"><DeleteButton onClick={()=>props.onDelete(x)}/></td>}
                        </tr>

                            {props.subrow && <tr class='subrow' class={{hover: ui.hover === i}} onmouseover={()=>ui.hover = i} onmouseout={()=>ui.hover = null}>
                                <td colSpan={props.columns.length}><div>{props.subrow(x)}</div></td>
                            </tr>}

                            {ui.collapsed === i && <tr class='collapsed-row'>
                                <td colSpan={props.columns.length + 1}>
                                    {props.collapse(x)}
                                </td>
                            </tr>
                            }
                        </>
                    ) 
                    : <tr><td colSpan={columns.length}>
                        {props.loading ? false : (props.emptyText || t("No result"))}
                      </td></tr>
                    }
                </tbody>
            </table></div>
            {pages>1 && <div class="footer">
                <div class="pages">
                    {<button disabled={page<=1} class="nav" onClick={()=>setPage(page-1)}>{ICON("left")}</button>}
                    {pages <= 10 ? range(pages).map(i=><button class={{active:i+1===page}} onClick={()=>setPage(i+1)}>{i+1}</button>)
                    : <>
                    {page > 5 && <button class={{active:1===page}} onClick={()=>setPage(1)}>{1}</button>}
                    {page > 6 && <span>...</span>}
                    {range(9).map(i=>i+min(max(page-4,1),pages-8)).map(i=><button class={{active:i===page}} onClick={()=>setPage(i)}>{i}</button>)}
                    {page < pages - 5  && <span>...</span>}
                    {page < pages - 4 && <button class={{active:pages===page}} onClick={()=>setPage(pages)}>{pages}</button>}
                    </>   
                    }
                    {<button disabled={page>=pages} class="nav" onClick={()=>setPage(page+1)}>{ICON("right")}</button>}
                </div>
            </div>}
            {props.footer && <div>{props.footer}</div>}
        </div>
        }
    }
}


export const renderField = (type, x) => {
    const render = {
        "date": x=>D(x, 'short'),
        "date-abs": x=>D(x, 'short-abs')
    }[type]
    if(render) return render(x)
    return x
}
