import "./Config.scss"
import {getRouter} from "../utils/router";
import {reactive} from "@vue/reactivity";
import {LiveQuery} from "../api/livequery";
import {API, model} from "../model";
import {CodeEditor} from "../ui/CodeEditor";
import {Button} from "../ui/Button";
import {t} from "../i18n";

export const Config = {
    setup() {
        const data = reactive({
            drilldown: undefined,
            mattermost_webhook: ''
        })
        LiveQuery("config_drilldown", "", x => data.drilldown = x, () => model.user && [], null, true)
        LiveQuery("config_matttermost_webhook", "", x => data.mattermost_webhook = x, () => model.user && [], null, true)

        return () => {
            return <div id="config">
                <div id='edit-mattermost-webhook'>
                    <label>Mattermost Webhook</label>
                    <input value={data.mattermost_webhook} onInput={x => data.mattermost_webhook = x.target.value}/>
                    <Button onClick={()=>API.setMattermostWebhook(data.mattermost_webhook)}>{t("Save changes")}</Button>
                </div>
                {<div id="edit-drilldown">
                    <h1>Edit drilldown</h1>
                    {<CodeEditor source={data.drilldown}
                                                  onSave={API.setDrilldownConfig}
                                                  onCancel={() => getRouter().go(-1)}
                    />}
                </div>}
            </div>
        }
    }
}