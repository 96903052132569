import { model, API } from '../model'
import { Avatar } from '../ui/Avatar'
import { Form } from '../ui/Form'
import { validateEmail } from '../util'
import { t } from '../i18n'
import './Profile.scss'
import {Link} from "../utils/router";
import {link} from "../utils/routing";
import {DataTable} from "../ui/DataTable";


export const Profile = {
    setup() {
        
        function save(x) { API.setUser(model.user.id, x) }

        return ()=><div id="profile">
            <h1><Avatar user={model.user}/> {model.user.display_name}</h1>
            <Form object={model.user} fields={{
                display_name:{name:t("Name")},
                email:{validate:validateEmail},
                avatar:{},
            }} onSave={save}>
            </Form>
            <DataTable
                noSearch
                data={model.scopes}
                columns={[
                    {title:t('Logo'), type:'logo', render:x=><img src={x.logo} alt=''/>},
                    {title:t('Name'), type:'name', render:x=><Link href={link(`/scope/${x.id}`)}>{x.display_name}</Link>},
                    {title:t('Role'), type:"role", render:x=>t(x.role)},
                ]}
            />
        </div>
    }
}