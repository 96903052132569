import { reactive } from '@vue/reactivity';
import { watch } from '@vue/runtime-core';
import { t } from '../i18n';
import { Badge } from './Badge';
import { Button, DeleteButton, IconButton } from './Button';
import './Form.scss'
import { ICON } from './icons';
import { Switch } from './Switch';
import {debounce} from "../util";

let id=0
export const Form = {
    props:['fields', 'object', 'onSave', 'onChange', 'onCancel'],
    setup(props, {slots}) {
        const data = reactive({})
        const errors = reactive({})
        const tests = reactive({})
        const ui = reactive({dirty:false})
        const setValue = (k,v)=>{
            data[k] = v;
            const err = validate(k,v)
            errors[k] = err
            props?.onChange?.(data)
            ui.dirty = true
        }

        const cancel = ()=>{
            reset()
            props.onCancel?.()
        }

        const hasErrors = ()=>{
            return Object.keys(errors).some(k=>errors[k])
        }

        const validate = (k,v)=>{
            if(!props.fields[k]?.validate) return;
            const err = props.fields[k].validate(v)
            if(err===true) return;
            else if(err===false) return t("Invalid "+k)
            return err
        }

        function reset() {
            ui.dirty = false
            for(var k in data) delete data[k]
            for(var k in errors) delete errors[k]
            for(var k in tests) delete tests[k]
            Object.assign(data, props.object||{})
            for(var k in props.object||{}) {
                errors[k] = validate(k,props.object[k])
            }
        }
        watch(()=>props.object, reset)
        reset()

        return ()=><div class="form">
            {Object.entries(props.fields).map(([k,{
                name,type,description,render,disabled,test,noLabel
            }])=>{
                if(!name) name = t(k)
                let value = data[k]
                if(value===undefined) value = props.object?.[k]
                const i="form-input-"+(id++);

                const renderField = () => (
                    type==="switch" ? <Switch name={k} disabled={!!disabled} value={value} onClick={()=>setValue(k,!value)}/>
                    : type==="array" ? <input name={k} value={value.join(", ")} id={i} class="form-control" aria-describedby={i+"-desc"} onInput={x=>setValue(k,x.target.value.split(",").map(x=>x.trim()))}/>
                    : render ? render({k,name,type,description})
                    : <input name={k} value={value} id={i} class="form-control" aria-describedby={i+"-desc"} onInput={x=> {
                                    setValue(k, x.target.value)
                                    if (test) {
                                        tests[k] = 'pending'
                                        test(x.target.value, (xx) => tests[k] = xx)
                                    }
                                }}/>
                )

                if (test && tests[k] === undefined) {
                    tests[k] = 'pending'
                    test(value, (xx) => tests[k] = xx)
                }

                return <div class="row g-3 align-items-center">
                {!noLabel && <div class="col-auto">
                    <label for={i} class="col-form-label">{name}</label>
                </div>}
                <div class="col-auto">{renderField()}</div>
                {test && <div class="col-auto test">{tests[k] ? tests[k] === 'pending' ? ICON('refresh') : ICON('ok') : ICON('ko')}</div>}
                <div class="col-auto">
                    {(description||errors[k]) && <span id={i+"-desc"} class="form-text" class={{error:!!errors[k]}}>
                    {errors[k] || description}
                    </span>}
                </div>
                </div> 
            })}
            <div class="row g-3 align-items-center">
                <div class="col-auto">
                    {slots?.default?.()}
                </div>
            </div>
            
            {props?.onSave &&
            <div class="footer">
                <Button disabled={!ui.dirty || hasErrors()} onClick={()=>props.onSave(data)}>{t("Save changes")}</Button>
                <Button secondary onClick={cancel}>{t("Cancel")}</Button>
            </div>}
        </div>
    }
}



