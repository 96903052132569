import { model, API } from "../model"
import { Badge } from "../ui/Badge"
import { IconButton } from "../ui/Button"
import { Form } from "../ui/Form"
import { ICON } from "../ui/icons"
import { Spinner } from "../ui/Spinner"
import { t } from "../i18n"
import "./ScopeSettings.scss"
import {createTagDialog} from "../ui/Tags"
import {AddButton} from "../ui/AddButton";
import {debounce} from "../util";

export const ScopeSettings = {
    setup() {
        function save(data) {
            API.setScope(model.scopeId, data)
        }
        function createTag() { createTagDialog().then(({tag, color})=>API.addTag("scope", model.scopeId, tag, color)) }
        return ()=>{
            const {scope} = model
            if(!scope) return <Spinner/>
            return <div id="scope-settings">
                <h1><img src={scope.logo}/> {scope.display_name} configuration</h1>
                <Form object={scope} 
                    onSave={save} 
                    fields={{
                        display_name:{name:t("Name")},
                        logo:{},
                        kibana_space:{name:'Kibana space'},
                        magellan_url:{name:'Magellan URL', test:debounce(async (x,set) => {
                                const t = await API.assetsTest(x)
                                set(t)
                            }, 1000)},
                        locked:{name:t("Locked"), type:'switch'},
                        api_token:{name:t("API Token"), render:()=><input id='api-token' value={scope.api_token} disabled size={scope.api_token.length}/>},
                    }}
                />

                <h4>Tags <AddButton secondary onClick={createTag}>{t("New Tag")}</AddButton></h4>
                <EditTags/>

            </div>
        }
    }
}

export const EditTags = () => {

    function removeTag(id, t) { API.deleteTag(undefined, id, t) }

    function edit(id, tag, color) {
        createTagDialog(tag, color).then(({tag, color})=>{
            API.updateTag({id, tag, color})
        })
    }

    return <div class="form-tags">
    {model.scope?.tags?.map(({tag,color,id})=><><Badge class="tag" style={{background:color}}>
        {tag}
        <IconButton onClick={()=>edit(id,tag,color)}>{ICON("edit")}</IconButton>

    </Badge>
        <IconButton onClick={()=>removeTag(id, tag)}>{ICON("delete")}</IconButton>
    </>)}
    </div>
}