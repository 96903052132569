import { model } from "../model"
import { DataTable } from "../ui/DataTable"
import { optionsFromQueryParams, queryParams, setQueryParams, watchQueryParams } from "../utils/queryParams"
import { t } from "../i18n"
import { Chain } from "../ui/Chain"
import './Incidents.scss'
import { ScopeItem } from "./Alerts"
import { LiveQuery } from "../api/livequery"
import { Spinner } from "../ui/Spinner"
import { Recos } from "../ui/Recos"
import { Link } from "../utils/router"
import {link} from "../utils/routing"

export const Incidents = {
    setup() {
        LiveQuery("incidents", "{}", i=>model.incidents=i, 
            ()=>queryParams().status && (queryParams().sort || queryParams().order) && [{
                scope_id:model.scopeId,
                ...optionsFromQueryParams(),
            }], null, true
        )

        // Enforce default sort/order
        watchQueryParams(({sort,order})=>{
            if(!sort && !order) setQueryParams({sort:"updated_at", order:"desc"})
        })

        return ()=>{
            if(!model.incidents) return <Spinner/>
            return <div id="incidents">
            <DataTable
                data={model.incidents}
                columns={[
                    ...(model.scope ? [] : [{title:t("Scope"), render:ScopeItem}]),
                    {title:t('First activity'), type:'date', render:x=>x.created_at},
                    {title:t('Last activity'), type:'date', render:x=>x.updated_at},
                    {title:"Description", render:x=><div class='description'>
                        <div><Link href={link(`/scope/${x.scopeId}/incident/${x.id}`)}>{x.title}</Link></div>
                        <div>{x.description}</div>
                    </div>},
                    {title:t("Status"), render:Chain},
                    {title:t("Recommendations"), render:Recos},
                ]}

                
                sortFields={{
                    created_at:t("first activity"),
                    updated_at:t("last activity"),
                    description:t("description"),
                    status:t("status"),
                    recommendations:t("recommendations"),
                    recommendations_pending:t("recommendations pending"),
                    ...(model.scope ? {} : {scope:t("scope")})
                }}
            />
        </div>
        }
    }
}