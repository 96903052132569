import { reactive } from "@vue/reactivity"
import { t } from "../../i18n"
import { API, model } from "../../model"
import { Button } from "../../ui/Button"
import { Description } from "../../ui/Description"
import { ICON } from "../../ui/icons"
import { Input } from "../../ui/Input"
import { queryParams } from "../../utils/queryParams"
import { gotoScope } from "../../utils/routing"
import './New.scss'
import {Dropdown, DropdownItem} from "../../ui/Dropdown";
import {capitalize} from "../../util";
import {CASES_TYPES, CaseType} from "../Case";
    
export const NewCase = {
    setup() {
        const data = reactive({ 
            case:{
                scope_id:model.scopeId,
                type: 'patrol'
            },
            alerts: queryParams().attach_alerts?.split(","),
            related_recommendations: queryParams().related_recommendations?.split(","),
            related_scenarios: queryParams().related_scenarios?.split(","),
        })

        async function save() {
            const {case:c,alerts,related_recommendations,related_scenarios} = data
            const {id} = await API.createCase(c)
            for(var alertId of alerts||[]) API.investigateAlert(alertId, id)
            for(var recoId of related_recommendations||[]) API.addRelatedRecommendation("case", id, recoId)
            for(var scenarioId of related_scenarios||[]) API.addRelatedScenario("case", id, scenarioId)
            gotoScope(`case/${id}`)
        }

        function cancel() {
            gotoScope("cases")
        }

        return ()=><div id="new-case" class="new">
            <div>
                <h1>
                    <Dropdown slim button={<CaseType type={data.case.type}/>}>
                        {CASES_TYPES.map(type=><DropdownItem onClick={()=>data.case.type = type}><span class="type"><span class={[type]}/>{capitalize(t(type))}</span></DropdownItem>)}
                    </Dropdown>
                    <Input placeholder={t("New case...")} 
                        value={data.case.title} 
                        onInput={e=>data.case.title = e.target.value}
                    />
                </h1>

                <Description
                    editing
                    title="Description"
                    source={data.case.body}
                    onInput={x=>data.case.body = x}
                />

                <br/>

                <div class="create-controls">
                    <Button disabled={!data.case.title || !data.case.body} onClick={save}>{t("Create")}</Button>
                    <Button secondary onClick={cancel}>{t("Cancel")}</Button>
                </div>
            </div>
        </div>
    }
}
    