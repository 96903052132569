import {model} from "../model"
import {DataTable} from "../ui/DataTable"
import {t} from "../i18n"
import {LiveQuery} from "../api/livequery"
import "./Assets.scss"
import {optionsFromQueryParams} from "../utils/queryParams";
import {ICON} from "../ui/icons";
import {capitalize} from "../util";
import {Box} from "../ui/Grid";
import {Tags} from "../ui/Tags";

export const Assets = {
    setup() {
        const lq = LiveQuery("assets", `{
            count, pages, items {
                hostname, domain, ips, network, kind, nb_ifaces, nb_services, nb_datasets, nb_accounts, ifaces, services, datasets, accounts
            }
        }`, s => model.assets = s,
            () => [{
                scope_id: model.scopeId,
                ...optionsFromQueryParams(),
            }, {scope_id: model.scopeId}], null, true
        )

        const kind_icon = (kind) => {
            if (!kind) return 'fas fa-desktop'
            if (kind.includes('laptop')) return 'fas fa-laptop'
            if (kind.includes('router')) return 'fas fa-hdd'
            if (kind.includes('firewall')) return 'fas fa-shield-alt'
            if (kind.includes('voip-terminal')) return 'fas fa-phone'
            if (kind.includes('mobile_phone')) return 'fas fa-mobile-alt'
            if (kind.includes('printer')) return 'fas fa-print'
            if (kind.includes('server')) return 'fas fa-server'
            return 'fas fa-desktop'
        }

        const kind_color = (kind) => {
            if (!kind) return ''
            if (kind === 'workstation') return 'blue'
            if (kind === 'laptop') return 'cyan'
            if (kind === 'server') return 'orange'
            if (kind === 'domain-controller') return 'gold'
            if (kind === 'hypervisor') return 'goldenrod'
            if (kind === 'router') return 'violet'
            if (kind === 'switch') return 'purple'
            if (kind === 'firewall') return 'peru'
            if (kind === 'printer') return 'lightgrey'
            if (kind === 'voip-terminal') return 'green'
            if (kind === 'mobile_phone') return 'lime'
            if (kind === 'nas') return 'grey'
            if (kind === 'raspberry-pi') return 'pink'
            if (kind === 'laps') return 'red'

            if (kind === 'tcp') return 'blue'
            if (kind === 'udp') return 'orange'
            return ''
        }

        return () => {

            return <div id="assets">
                <h1>{ICON('asset')} {t("Assets")}</h1>
                <DataTable
                    loading={lq.loading}
                    data={model.assets}
                    columns={[
                        {render: x =>ICON(kind_icon(x.kind))},
                        {
                            title: t('Hostname'), render: x => {
                                if (x.hostname && x.domain) {
                                    return <><span class='hostname'>{x.hostname}</span><span>.{x.domain}</span></>
                                } else if (x.hostname) {
                                    return <><span class='hostname'>{x.hostname}</span></>
                                } else if (x.domain) {
                                    return <><span>{x.domain}</span></>
                                } else {
                                    return <><span>-</span></>
                                }
                            }
                        },
                        {title: t('IPs'), render: x => x.ips && <ul>{x.ips.map((ip) => <li>{ip}</li>)}</ul>},
                        {title: t('Network'),
                            render: x => x.network && <ul>
                                <li>{x.network.cidr}</li>
                                <li>{x.network.description}</li>
                            </ul>
                        },
                        {
                            title: t('KPIs'), type: 'counts', render: x => <>
                                <div>{x.nb_ifaces > 0 &&
                                <span title={t('Interfaces')}>{ICON("iface")} {x.nb_ifaces}</span>}</div>
                                <div>{x.nb_services > 0 &&
                                <span title={t('Services')}>{ICON("service")} {x.nb_services}</span>}</div>
                                <div>{x.nb_datasets > 0 &&
                                <span title={t('Datasets')}>{ICON("dataset")} {x.nb_datasets}</span>}</div>
                                <div>{x.nb_accounts > 0 &&
                                <span title={t('Accounts')}>{ICON("account")} {x.nb_accounts}</span>}</div>
                            </>
                        },
                        {title: t('Kind'), render: x => <Tags tags={x.kind && x.kind.map((k) => ({tag: k, color: kind_color(k)}))}/>},
                    ]}
                    collapse={(x) => {
                        return x.nb_ifaces + x.nb_services + x.nb_datasets + x.nb_accounts > 0 ? <div class='fields'>
                            {x.nb_ifaces > 0 && <Box>
                                <h2>{ICON("iface")} {capitalize(t(`Interfaces`))}</h2>
                                <DataTable noSearch
                                           data={x.ifaces}
                                           columns={[
                                               {title: t('MAC'), render: i => i.mac},
                                               {title: t('Name'), render: i => i.name},
                                               {title: t('Vendor'), render: i => i.vendor},
                                           ]}
                                />
                            </Box>}
                            {x.nb_services > 0 && <Box>
                                <h2>{ICON("service")} {capitalize(t(`Services`))}</h2>
                                <DataTable noSearch
                                           data={x.services}
                                           columns={[
                                               {title: t('Port'), render: i => i.port},
                                               {title: t('Kind'), render: x => <Tags tags={x.kind && [...x.kind].sort((a,b) =>
                                                       ["tcp", "udp"].indexOf(b)+1
                                                   ).map((k) => ({tag: k, color: kind_color(k)}))}/>},
                                           ]}
                                />
                            </Box>}
                            {x.nb_datasets > 0 && <Box>
                                <h2>{ICON("dataset")} {capitalize(t(`Datasets`))}</h2>
                                <DataTable noSearch
                                           data={x.datasets}
                                           columns={[
                                               {title: t('Name'), render: i => i.name},
                                               {title: t('Kind'), render: x => <Tags tags={x.kind && x.kind.map((k) => ({tag: k, color: kind_color(k)}))}/>},
                                           ]}
                                />
                            </Box>}
                            {x.nb_accounts > 0 && <Box>
                                <h2>{ICON("account")} {capitalize(t(`Accounts`))}</h2>
                                <ul>
                                    {x.accounts.map(a => <li>{a.login}</li>)}
                                </ul>
                            </Box>}
                        </div> : false
                    }}
                />
            </div>
        }
    }
}