import './JSONView.scss'

export const JSONView = {
    props:["source"],
    setup(props) {

        return ()=>{
            const {source} = props
            return <div class='json'>
                <pre>
               <code>
                    {JSON.stringify(typeof(source)==="string" ? JSON.parse(source) : source, null, 4)}
                </code>
            </pre>
            </div>
        }
    }
}