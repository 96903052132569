import { reactive } from "@vue/reactivity"
import { API } from "../../model"
import { Form } from "../../ui/Form"
import { Spinner } from "../../ui/Spinner"
import { goTo } from "../../utils/routing"
import { t } from "../../i18n"
import "./NewScope.scss"

export const NewScope = {
    setup() {
        const data = reactive({scope:{ }})

        function save() {
            API.createScope(data.scope)
            goTo("/scopes")
        }

        function cancel() {
            goTo("/scopes")
        }

        return ()=>{
            const {scope} = data
            if(!scope) return <Spinner/>
            return <div id="new-scope">
                <h1><img src={scope.logo}/> {scope.display_name}</h1>
                <Form object={scope} 
                    onChange={x=>Object.assign(data.scope, x)}
                    onSave={save} 
                    onCancel={cancel}
                    fields={{
                        display_name:{name:t("Name"), validate:x=>!!x || "Entrez un nom"},
                        logo:{},
                        kibana_space:{name:'Kibana space'},
                        locked:{name:t("Locked"), type:'switch'},
                    }}
                />
            </div>
        }
    }
}