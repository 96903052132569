import { reactive } from "@vue/reactivity";
import { IconButton } from "./Button";
import { Editor } from "./Editor";
import { ICON } from "./icons";
import { Dropdown, DropdownItem } from './Dropdown'
import './Markdown.scss'
import { t } from "../i18n";
import { scopeLink } from "../utils/routing";
import { getRouter } from "../utils/router";

export const md = markdownit({
    html:true,
    linkify:true,
})

md.linkify.add('@', {
    validate: function (text, pos, self) {
      var tail = text.slice(pos);
      if(/^(\S+)/.test(tail)) {
          return tail.match(/^(\S+)/)[0].length
      }
    },
    normalize: function (match) {
      match.text = '';
    }
});

md.linkify.add('#', {
    validate: function (text, pos, self) {
      var tail = text.slice(pos);
      if(/^\[.+?:.+?\]\(.+?\)/.test(tail)) {
          return tail.match(/^\[.+?:.+?\]\(.+?\)/)[0].length
      }
    },
    normalize: function (match) {
        match.text = '';
    }
});

var defaultRender = md.renderer.rules.link_open || ((tokens, idx, options, env, self) => self.renderToken(tokens, idx, options))
md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
    let href = tokens[idx]?.attrs?.[0]?.[1] || ""
    href = decodeURIComponent(href)
    try {
        const [_,type,id,title] = href.match(/^#\[([^:]+):([^\]]+)\]\(([^\)]+)\)/)
        href = (getRouter()?.options?.base || "/") + scopeLink(`${type}/${id}`)
        href = href.replace("//", "/")
        return `<a href='${href}'>${ICON(type)?.el?.outerHTML} ${title}</a>`
    } catch(e) {
        try {
            const [,,mention] = href.match(/(^|\s)(@\S+)/)
            return `<span class="mention">${mention}</span>`
        } catch(e) {
            return defaultRender(tokens, idx, options, env, self);
        }
    }
}



export const Markdown = {
    props:['editable', 'source', 'onSave', 'visibility', 'visibilities', 'setVisibility', 'editing', 'onCancel', 'placeholder', 'transform', 'render'],
    setup(props) {
        const data = reactive({
            editing:false,
        })
        return ()=>{
            const {onSave, source="", editable} = props
            return data.editing || props.editing ?
                <Editor 
                    source={source} 
                    onSave={onSave && (x=>{onSave(x);data.editing=false;})}
                    onCancel={onSave && (()=>{data.editing=false; props.onCancel?.()})}
                    placeholder={props.placeholder}
                />
                :
                <div class="markdown">
                    {editable && props.editing===undefined && <div class='controls'>
                        {props.visibilities && 
                        <Dropdown button={<>{ICON("eye")} {t(props.visibility)}</>}>
                            {props.visibilities.map(x=><DropdownItem onClick={()=>props.setVisibility?.(x)}>{x===props.visibility && ICON("ok")} {t(x)}</DropdownItem>)}
                        </Dropdown>}
                        {onSave && <IconButton onClick={()=>data.editing=!data.editing}>{ICON('edit')}</IconButton>}
                    </div>}
                    {props?.render ? 
                        <div>{props?.render(source)}</div>
                        : <div v-html={md.render(props?.transform?.(source) || source || (editable && onSave && props.placeholder) || "")}/>
                    }
                </div>
        }
    }
}