import { model, API, hasRole } from "../model"
import { Severity } from "../ui/Severity"
import { t, t_fem, t_n, t_n_fem } from "../i18n"
import { AddTagButton, createTagDialog, Tags } from '../ui/Tags'
import "./Alerts.scss"
import { reactive } from "@vue/reactivity"
import { Button, IconButton } from "../ui/Button"
import { ICON } from "../ui/icons"
import { FilteredDropdown} from '../ui/FilteredDropdown'
import { InProgressIcon } from "../ui/InProgressIcon"
import {optionsFromQueryParams, queryParams, setQueryParams} from "../utils/queryParams"
import { LiveQuery } from "../api/livequery"
import { AddButton } from "../ui/AddButton"
import { Link } from "../utils/router"
import { link, gotoScope } from "../utils/routing"
import { Filter } from '../ui/Filter'
import { Badge } from "../ui/Badge"
import { UI } from "../App"
import { DataTable } from "../ui/DataTable"
import { subscribe } from "../api/graphql"
import {toRaw, watchEffect} from "@vue/runtime-core"
import {debounce } from "../util";
import {Switch} from "../ui/Switch";
import { Dropdown, DropdownItem } from '../ui/Dropdown'
import {Checkbox} from "../ui/Checkbox";
import {download_alerts_as_csv, download_alerts_as_json} from "../utils/download";

export const Alerts = {
    setup() {
        const data = reactive({
            selectedItems:[],
            alerts_by_status:{},
            new_alerts:0,
            new_alerts_message:"",
            filteredScopes: new Set(model.user.settings.filteredScopes || [])
        })

        if(!UI.disable_alerts_by_status) {
            LiveQuery("alerts_by_status", "", a=>data.alerts_by_status=a,
                ()=>[{
                scope_id:model.scopeId,
                not_scope_ids: model.scopeId ? [] : model.user.settings.filteredScopes || []
            }], "alerts", true, {throttle_timeout:5000}
            )
        }

        const lq = LiveQuery("alerts", `{
            count, pages, items {
                id, scope_id, title, scope_id, body, tags, severity, first_detection, last_detection, status, cases{count, items{title}}, siblings_count, source, destination
            }
        }`, c=>{
                model.alerts=c;
                data.new_alerts=0;
            },
            ()=>[{
                scope_id:model.scopeId, limit:20,
                ...optionsFromQueryParams(),
                status: queryParams().status?.split(",") || ["new", "investigating"],
                not_scope_ids: model.scopeId ? [] : model.user.settings.filteredScopes || []
            }, {scope_id:model.scopeId}]
            , null, true, {throttle_timeout:30000}
        )

        let lq_newalerts = null;
        watchEffect(async ()=>{
            lq_newalerts?.unsubscribe()
            lq_newalerts = await subscribe("new_alert", {scope_id:model.scopeId}, ()=>{
                UI.enable_alerts_auto_reload ? refresh() : data.new_alerts++
            })
        })

        async function refresh() { await lq.lq.refresh() }

        function addTag(a, x, color) {
            API.addTag("alert", a.id, x, color)
            refresh();
        }
        function deleteTag(a, x) {
            API.deleteTag("alert", a.id, x)
            refresh();
        }
        function createTag(a, tag, color) {
            createTagDialog(tag, color).then(({tag, color})=>addTag(a, tag, color))
        }

        function addTagToSelection(x) {
            for(var id of data.selectedItems)
                API.addTag("alert", id, x)
            refresh();
        }
        function setSelectionStatus(x) {
            for(var id of data.selectedItems)
                API.setAlertStatus(id, x)
            data.selectedItems = []
            refresh();
        }
        function addSelectionToCase(c) {
            for(var id of data.selectedItems)
                API.investigateAlert(id, c.id)
            data.selectedItems = []
            refresh();
        }
        function addSelectionToNewCase() {
            gotoScope("newcase?attach_alerts="+data.selectedItems.join(","))
            data.selectedItems = []
        }

        function newAlert() { gotoScope("newalert") }

        function getCases(search) { return API.getCases({scope_id:model.scopeId,search,limit:5}) }

        const setFilteredScopes = debounce(() => {
            if (optionsFromQueryParams().page) setQueryParams({page: 1})
            let settings = toRaw(model.user.settings)
            settings.filteredScopes = [...data.filteredScopes]
            API.setUser(model.user.id, {settings: JSON.stringify(settings)})
        })

        return ()=>{
            const allowed_tags = model.scope?.tags

            return <div id="alerts">
            <Filter type='status' title="Alerts"
                data={data.alerts_by_status}
                render={x=>({
                    "new":[ICON("star"), "alert:New"],
                    "investigating":[<InProgressIcon/>, "Investigating"],
                    "resolved":[ICON("done"), "alert:Done"],
                    "false-positive":[ICON("canceled"), "False positive"],
                    "muted":[ICON("mute"), "alert:Ignored"],
                }[x])}
                defaultFilter={["new", "investigating"]}
            />
            <DataTable
                loading={lq.loading}
                data={model.alerts}
                header={<>
                    {hasRole("analyst") && model.scopeId && <AddButton secondary onClick={newAlert}>{t("New Alert")}</AddButton>}
                </>}
                buttons={<>
                    {!model.scopeId && <div class='filter-scopes'>
                        <Dropdown button={t('Filter scopes')}>{model.scopes.map((scope)=>
                            <DropdownItem onClick={(e) => {
                                data.filteredScopes.delete(scope.id) || data.filteredScopes.add(scope.id)
                                setFilteredScopes()
                                e.stopPropagation()
                                e.preventDefault()
                            }}>
                                <Checkbox value={!data.filteredScopes.has(scope.id.toString())}/>
                                <img src={scope.logo} alt=''/>
                                {scope.display_name}
                            </DropdownItem>)}
                        </Dropdown></div>}
                    {model.scopeId && hasRole("analyst") && <>
                        <div class="actions" class={{hide:!data?.selectedItems?.length}}>
                        {data.selectedItems.length>0 && <>
                            <span class="selected-count">{t_n(data.selectedItems.length, "alert selected")}</span>
                            <div>
                            {model.scopeId && <>
                                <Button onClick={addSelectionToNewCase}>{ICON("add")} {t("Open a new case")}</Button>
                                <FilteredDropdown
                                    button={<>{ICON("case")} {t("Add to existing case")}</>}
                                    query={getCases}
                                    render={x=><>{ICON("case")} {x.title}</>}
                                    onSelect={addSelectionToCase}
                                />
                            </>}
                            </div>
                            <div>
                                <Button secondary onClick={()=>setSelectionStatus("false-positive")}>{t("False positive",{nb:data.selectedItems.length})}</Button>
                                <Button secondary onClick={()=>setSelectionStatus("muted")}>{t("Ignore")}</Button>
                            </div>
                            <AddTagButton allowed_tags={allowed_tags} addTag={addTagToSelection}/>
                            <IconButton tooltip={t("Download as JSON")} onClick={async ()=> {
                                    await download_alerts_as_json(data.selectedItems, model.scopeId)
                            }}>{ICON('download')}</IconButton>
                            <IconButton tooltip={t("Download as CSV")} onClick={async ()=> {
                                    await download_alerts_as_csv(data.selectedItems, model.scopeId)
                            }}>{ICON('csv')}</IconButton>
                        </>}
                    </div>
                    <div id="refresh-now">
                        {data.new_alerts>0 && <>
                            <IconButton title={t("Refresh")} onClick={refresh}>{ICON("refresh")}</IconButton>
                            {t_n_fem(data.new_alerts, "alert")} {t("since last refresh")}
                         </>}
                    </div>
                    </>}
                </>}
                columns={[
                    {title:t('First detection'), type:'date', render:x=>x.first_detection},
                    {title:t('Last detection'), type:'date', render:x=>x.last_detection},
                    {title:t('Status'), type:"status", render:x=><div class={[x.status]}>
                        {
                            x.status==="new" ? ICON("star") :
                            x.status==="false-positive" ? ICON("canceled") :
                            x.status==="muted" ? ICON("mute") :
                            x.status==="resolved" ? ICON("done")
                            : <InProgressIcon/>
                        }
                        <span>{t_fem(x.status?.replaceAll("_", " "))}</span>
                    </div>},
                    ...(model.scope ? [] : [{title:t("Scope"), type:"scope", render:ScopeItem}]),
                    {title:t('ID'), type:'id', render:x=>
                        <Link href={link(`/scope/${x.scope_id}/alert/${x.id}`)}>
                            {x.id}
                        </Link>
                    },
                    {title:t('Description'), type:"description", render:x=>
                        <Link href={link(`/scope/${x.scope_id}/alert/${x.id}`)}>
                            {x.title} {x.siblings_count>0 && <Badge>+ {x.siblings_count}{ICON("alert")}</Badge>}
                        </Link>},
                    {title:t('Source'), type:"description", render:x=>x.source},
                    {title:t('Destination'), type:"description", render:x=>x.destination},
                    {title:t('Cases'), render:x=>x.cases?.count > 0 && <span title={x.cases.items[0].title}>{ICON("case")} {x.cases?.count}</span>},
                    {title:t('Severity'), render:x=><Severity val={x.severity}/>},
                    {title:t('Tags'), type:"tags", noLink:true, render:a=>
                        <Tags tags={a.tags}
                            editable={hasRole("analyst")}
                            allowed_tags={allowed_tags}
                            onAdd={(x,color)=>addTag(a,x,color)}
                            onDelete={x=>deleteTag(a,x)}
                            onCreate={(x,color)=>createTag(a,x,color)}
                        />
                    },
                ]}
                sortFields={{
                    status:t("status"),
                    timestamp:t("first detection"),
                    last_detection:t("last detection"),
                    updated_at:t("last edited"),
                    severity:t("severity"),
                    title:t("description"),
                    ...(model.scope ? {} : {scope_id:t("scope")})
                }}
                defaultSort={{sort:"last_detection", order:"desc"}}

                selection={hasRole("analyst") && model.scopeId && data.selectedItems}

                footer={
                    hasRole("analyst") && model.scopeId &&
                    <Switch
                        class='autoreload-switch'
                        value={UI.enable_alerts_auto_reload}
                        onClick={()=>UI.enable_alerts_auto_reload=!UI.enable_alerts_auto_reload}
                    >
                        {"Auto Reload"}
                    </Switch>
                }
            />
        </div>
        }
    }
}

export const ScopeItem = ({scope_id})=><div class="scope-item">
    <img src={model.scopes?.find(s=>s.id===scope_id)?.logo}/>
    {model.scopes?.find(s=>s.id===scope_id)?.display_name}
</div>
