import { reactive } from "@vue/reactivity"
import { t } from "../../i18n"
import { API, model } from "../../model"
import {Button} from "../../ui/Button"
import { Description } from "../../ui/Description"
import { ICON } from "../../ui/icons"
import { Input } from "../../ui/Input"
import { DatePicker } from "../../ui/DatePicker"
import { queryParams } from "../../utils/queryParams"
import { gotoScope } from "../../utils/routing"
import './New.scss'
import {Severity} from "../../ui/Severity";

export const NewAlert = {
    setup() {
        const data = reactive({
            alert:{
                scope_id:model.scopeId,
                date:''
            },
            cases: queryParams().cases?.split(","),
        })

        async function save() {
            const {id} = await API.createAlert(data.alert)
            for(var caseId of data.cases||[]) API.investigateAlert(id, caseId)
            gotoScope(`alert/${id}`)
        }

        function cancel() {
            gotoScope("alerts")
        }

        return ()=><div id="new-alert" class="new">
            <div>
                <div>
                    <div class='text-inputs'>
                        <h1>
                            {ICON("alert")}
                            <Input placeholder={t("New alert...")}
                                   value={data.alert.title}
                                   onInput={e=>data.alert.title = e.target.value}
                            />
                            <h3>{t("Severity")}</h3>
                            <Severity val={data.alert.severity || 0} onChange={x=>data.alert.severity=x}/>
                            <h1>{data.alert.severity || 0}</h1>
                        </h1>

                        <Description
                            editing
                            title="Description"
                            source={data.alert.body}
                            onInput={x=>data.alert.body = x}
                        />

                        <br/>

                        <div className="create-controls">
                            <Button disabled={!data.alert.title || !data.alert.body || !data.alert.severity}
                                    onClick={save}>{t("Create")}</Button>
                            <Button secondary onClick={cancel}>{t("Cancel")}</Button>
                        </div>
                    </div>

                    <div class='date-input'>
                        <b>{t('Detection date')}</b>
                        <DatePicker onChange={(x)=>data.alert.date = x.target.value} />
                    </div>
                </div>
            </div>
        </div>
    }
}
    