import { reactive, watchEffect } from '@vue/runtime-core'
import { Dropdown, DropdownItem } from './Dropdown'
import { Input } from './Input'
import './FilteredDropdown.scss'
import { t } from '../i18n'
import { ICON } from './icons'
import { InProgressIcon } from './InProgressIcon'
import { debounce } from '../util'

export const FilteredDropdown = {
    props:["iconbutton", "menuClass", "onSelect", "query", "render", "tooltip", "footer"],
    setup(props) {
        const data = reactive({open:false, filter:"", items:[], count:0, loading:false})

        const update = debounce(async ()=>{
            const x = await props.query(data.filter)
            if(Array.isArray(x)) {
                data.items = x
                data.count = x.length
            } else {
                data.items = x.items
                data.count = x.count
            }
        }, 100)

        watchEffect(async ()=>{
            if(!data.open) return;
            const {filter} = data
            data.loading = true
            update()
            data.loading = false
        })

        return ()=>{
            const render = props.render || (x=>x)
            return <Dropdown
                    menuClass={props.menuClass + " combo filtered"}
                    tooltip={props.tooltip}
                    iconbutton={props.iconbutton}
                    onOpen={()=>{data.open=true; data.filter=""}}
                    onClose={()=>{data.open=false;}}
                    top={props.query && 
                        <Input 
                            placeholder={t("Search...")} 
                            value={data.filter} 
                            onInput={e=>data.filter = e.target.value} 
                            pre={ICON("search")} 
                            post={data.loading ? <InProgressIcon/> : <small>{data.count} {t('results')}</small>}
                    />}
                >
                {data.items.map(x=>render(x) && <DropdownItem onClick={()=>props.onSelect?.(x)}>{render(x)}</DropdownItem>)}
                {props.footer}
            </Dropdown>
        }
    }
}
