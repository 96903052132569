<template>
    <slot name="trigger"></slot>
    <teleport to="body">
      <slot></slot>
    </teleport>
</template>    
<script>
module.exports = {
  name:"teleport"
}
</script>
