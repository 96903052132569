import { reactive } from "@vue/reactivity"
import { watchEffect } from "@vue/runtime-core"
import { IconButton } from "./Button"
import { D, parseDate } from "./dates"
import { ICON } from "./icons"


export const Date = {
    props:["value", "onSave"],
    setup(props) {
        const data = reactive({
            editing:false,
            value:props.value
        })
        watchEffect(()=>data.value = D(props.value, "text"))
    
        const parse = parseDate

        return ()=>!data.editing ?
            <h6>
                {D(props.value, "long")}
                {props.onSave && <IconButton class="edit" onClick={()=>data.editing=!data.editing}>{ICON("edit")}</IconButton>}
            </h6>
        : 
        <>
            <input value={data.value} onChange={e=>data.value=e.target.value}/>
            {!isNaN(parse(data.value)) && <IconButton class='edit' onClick={()=>{props.onSave?.(parse(data.value)); data.editing=false;}}>{ICON("ok")}</IconButton> }
            <IconButton class="edit" onClick={()=>{data.value = D(props.value, "text"); data.editing = false;}}>{ICON("close")}</IconButton>
        </> 
    }
}