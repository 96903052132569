import {reactive} from "@vue/reactivity"
import {API, model} from "../../model"
import {goTo} from "../../utils/routing"
import {t, t_fem} from "../../i18n";
import {Form} from "../../ui/Form";
import {debounce} from "../../util";
import {Box} from "../../ui/Grid";
import './NewVulnerability.scss'
import {Sidebar} from "../../ui/Sidebar";
import {ICON} from "../../ui/icons";
import {Badge} from "../../ui/Badge";
import {modal} from "../../ui/Modal";
import {Dialog} from "../../ui/Dialog";
import {JSONView} from "../../ui/JSONView";
import {Dropdown, DropdownItem} from "../../ui/Dropdown";
import {Checkbox} from "../../ui/Checkbox";
import {Button, IconButton} from "../../ui/Button";
import {Description} from "../../ui/Description";

export const NewVulnerability = {
    setup() {
        const data = reactive({
            vulnerability: {},
            scopes: new Set([])
        })

        function save() {
            API.createVulnerability({scope_ids: Array.from(data.scopes), cve_id: data.vulnerability.cve_id, body: data.vulnerability.body, original: JSON.stringify(data.vulnerability.original)})
            goTo("/vulnerabilities")
        }

        function cancel() {
            goTo("/vulnerabilities")
        }

        function cvss_score(cvss) {
            if (cvss === 0) {
                return 'None'
            } else if (cvss < 4) {
                return 'Low'
            } else if (cvss < 7) {
                return 'Medium'
            } else if (cvss < 9) {
                return 'High'
            } else {
                return 'Critical'
            }
        }

        function cvss_exploitability(x) {
            let vector = {}
            x.split('/').forEach(y => {
                let [k, v] = y.split(':')
                vector[k] = v
            })

            let [AccessVector, AccessComplexity, Authentication] = [0, 0, 0]

            switch (vector['AV']) {
                case 'L':
                    AccessVector = 0.395;
                    break;
                case 'A':
                    AccessVector = 0.646;
                    break;
                case 'N':
                    AccessVector = 1.0;
                    break;
            }

            switch (vector['AC']) {
                case 'H':
                    AccessComplexity = 0.35;
                    break;
                case 'M':
                    AccessComplexity = 0.61;
                    break;
                case 'L':
                    AccessComplexity = 0.71;
                    break;
            }

            switch (vector['Au']) {
                case 'M':
                    Authentication = 0.45;
                    break;
                case 'S':
                    Authentication = 0.56;
                    break;
                case 'N':
                    Authentication = 0.704;
                    break;
            }

            return 20 * AccessVector * AccessComplexity * Authentication
        }

        function cvss_impact(x) {
            let vector = {}
            x.split('/').forEach(y => {
                let [k, v] = y.split(':')
                vector[k] = v
            })

            let [ConfImpact, IntegImpact, AvailImpact] = [0, 0, 0]

            switch (vector['C']) {
                case 'N':
                    ConfImpact = 0.0;
                    break;
                case 'P':
                    ConfImpact = 0.275;
                    break;
                case 'C':
                    ConfImpact = 0.660;
                    break;
            }

            switch (vector['I']) {
                case 'N':
                    IntegImpact = 0.0;
                    break;
                case 'P':
                    IntegImpact = 0.275;
                    break;
                case 'C':
                    IntegImpact = 0.660;
                    break;
            }

            switch (vector['A']) {
                case 'N':
                    AvailImpact = 0.0;
                    break;
                case 'P':
                    AvailImpact = 0.275;
                    break;
                case 'C':
                    AvailImpact = 0.660;
                    break;
            }

            return 10.41 * (1 - (1 - ConfImpact) * (1 - IntegImpact) * (1 - AvailImpact))
        }

        function products(x) {
            let res = {}
            if (x) {
                x.map(p => {
                    let y = p.split(':')
                    let [vendor, product] = [y[3], y[4]]
                    if (vendor in res) {
                        if (!res[vendor].includes(product)) res[vendor].push(product)
                    } else {
                        res[vendor] = [product]
                    }
                })
            }
            return res
        }

        function openJSON() {
            return modal(({close}) =>
                <Dialog close={close} onSubmit={() => close(data)}
                        header={t("JSON object")}
                        body={<JSONView source={data.vulnerability.original}/>}
                />
            )
        }

        return () => {

            return <div id="new-vulnerability" class="page with-sidebar">
                <div>
                    <Form object={data.vulnerability}
                          onChange={x => Object.assign(data.vulnerability, x)}
                          fields={{
                              cve_id: {
                                  name: t("CVE ID"), test: debounce(async (x, set) => {
                                      for (let k in data.vulnerability) if (k !== 'cve_id') delete data.vulnerability[k]
                                      const t = await API.vulnerabilitySearch(x || "")
                                      data.vulnerability.original = t
                                      if (t) {
                                          delete t['cve_id']
                                          Object.assign(data.vulnerability, t)
                                          data.vulnerability.products = products(data.vulnerability.vulnerable_product)
                                      }
                                      set(!!t)
                                  })
                              },
                              scopes: {
                                  noLabel: true,
                                  render: () => <div className='filter-scopes'>
                                      <Dropdown
                                          button={t('Select Scopes') + ' (' + data.scopes.size + ')'}>{model.scopes.map((scope) =>
                                          <DropdownItem onClick={(e) => {
                                              data.scopes.delete(scope.id) || data.scopes.add(scope.id)
                                              e.stopPropagation()
                                              e.preventDefault()
                                          }}>
                                              <Checkbox value={data.scopes.has(scope.id.toString())}/>
                                              <img src={scope.logo} alt=''/>
                                              {scope.display_name}
                                          </DropdownItem>)}
                                      </Dropdown>
                                  </div>
                              }
                          }}
                    />
                    <Description
                        editing
                        title="Description"
                        source={data.vulnerability.body}
                        onInput={x=>data.vulnerability.body = x}
                    />
                    <div class='vulnerability-fields'>
                        <Box>
                            <h2>{t('Summary')}</h2>
                            <span>{data.vulnerability.summary}</span>
                        </Box>
                        <Box>
                            <h2>{t('CVSS v2.0')}</h2>
                            {data.vulnerability.cvss && <div class='cvss'>
                                <Box class={'score ' + cvss_score(data.vulnerability.cvss)}>
                                    <h3>{data.vulnerability.cvss.toFixed(1)}<sup>/10</sup></h3>
                                    <p>CVSS 2.0 : {t(cvss_score(data.vulnerability.cvss))}</p>
                                    <div>
                                        <a href='https://www.first.org/cvss/v2/guide' target='_blank'>V2
                                            Legend {ICON("go")}</a>
                                    </div>
                                </Box>
                                <div>
                                    <Box><b>{t('Access Vector')}</b><Badge
                                        class={'vector ' + data.vulnerability.access.vector}>{t(data.vulnerability.access.vector)}</Badge></Box>
                                    <Box><b>{t('Access Complexity')}</b><Badge
                                        class={'complexity ' + data.vulnerability.access.complexity}>{t_fem(data.vulnerability.access.complexity)}</Badge></Box>
                                    <Box><b>{t('Authentication')}</b><Badge
                                        class={'authentication ' + data.vulnerability.access.authentication}>{t_fem(data.vulnerability.access.authentication)}</Badge></Box>
                                </div>
                                <div>
                                    <Box><b>{t('Confidentiality Impact')}</b><Badge
                                        class={'confidentiality ' + data.vulnerability.impact.confidentiality}>{t(data.vulnerability.impact.confidentiality)}</Badge></Box>
                                    <Box><b>{t('Integrity Impact')}</b><Badge
                                        class={'integrity ' + data.vulnerability.impact.integrity}>{t(data.vulnerability.impact.integrity)}</Badge></Box>
                                    <Box><b>{t('Availability Impact')}</b><Badge
                                        class={'availability ' + data.vulnerability.impact.availability}>{t(data.vulnerability.impact.availability)}</Badge></Box>
                                </div>
                                <div>
                                    <p><strong>{t('Vector')} : </strong>{data.vulnerability['cvss-vector']}</p>
                                    <p>
                                        <strong>{t('Exploitability')} : </strong>{(Math.round(cvss_exploitability(data.vulnerability['cvss-vector']) * 10) / 10).toFixed(1)} /&nbsp;
                                        <strong>{t('Impact')} : </strong>{(Math.round(cvss_impact(data.vulnerability['cvss-vector']) * 10) / 10).toFixed(1)}
                                    </p>
                                </div>
                            </div>}
                        </Box>
                        <Box class="references">
                            <h2>{t("References")}</h2>
                            {data.vulnerability.references && <ul>
                                {data.vulnerability.references.map(r => <a href={r} target='_blank'>
                                    <li>{r}</li>
                                </a>)}
                            </ul>}
                        </Box>
                        <Box class="configurations">
                            <h2>{t("Configurations")}</h2>
                            {data.vulnerability.vulnerable_configuration && <ul>
                                {data.vulnerability.vulnerable_configuration.map(r => <li>{r.title}</li>)}
                            </ul>}
                        </Box>
                    </div>
                </div>
                <Sidebar>
                    <div class='buttons'>
                        <Button disabled={!data.vulnerability.id} onClick={() => save(data.vulnerability)}>{t("Save changes")}</Button>
                        <Button secondary onClick={cancel}>{t("Cancel")}</Button>
                    </div>
                    <Box class='information'>
                        <h2>{t('Information')}</h2>
                        {data.vulnerability.Published && <p>
                            <strong>{t('Published')} : </strong>{data.vulnerability.Published.split('T')[0]} {data.vulnerability.Published.split('T')[1].slice(0, -3)}
                        </p>}
                        {data.vulnerability.Modified && <p>
                            <strong>{t('Updated')} : </strong>{data.vulnerability.Modified.split('T')[0]} {data.vulnerability.Modified.split('T')[1].slice(0, -3)}
                        </p>}
                        {data.vulnerability.id && <>
                            <hr/>
                            <p>{ICON("go")} <strong>NVD : </strong><a target='_blank'
                                                                           href={`https://nvd.nist.gov/vuln/detail/${data.vulnerability.id}`}>{data.vulnerability.id}</a>
                            </p>
                            <p>{ICON("go")} <strong>Mitre : </strong><a target='_blank'
                                                                             href={`https://cve.mitre.org/cgi-bin/cvename.cgi?name=${data.vulnerability.id}`}>{data.vulnerability.id}</a>
                            </p>
                        </>}
                        {data.vulnerability.original && <>
                            <hr/>
                            <p>{ICON("json")} <strong>JSON : </strong> <IconButton onClick={openJSON}>{ICON("eye")}</IconButton></p>
                        </>}
                    </Box>
                    <Box class='products'>
                        <h2>{t('Products affected')}</h2>
                        {data.vulnerability.products && <ul>
                            {Object.keys(data.vulnerability.products).map(v => {
                                return <li>
                                    <span>{v}</span>
                                    <ul>
                                        {data.vulnerability.products[v].map(pp => <li>{pp}</li>)}
                                    </ul>
                                </li>
                            })}
                        </ul>}
                    </Box>
                </Sidebar>
            </div>
        }
    }
}