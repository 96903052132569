import "./Input.scss"

export const Input = ({label, pre, post, value="", validate, autofocus, ...rest}) => {
  let c = (
    <div class="input-group flex-nowrap">
      {!!pre && <span class="input-group-text">{pre}</span>}
      <input type="text" 
        ref={e=>{if(e) e.value=value}} 
        class={["form-control", value && validate && !validate(value) && "is-invalid"]} 
        autofocus={autofocus}
        {...rest}
      />
      {!!post && <span class="input-group-text">{post}</span>}
    </div>
  )
  if(label) c = <div class="form-group"><label>{label}</label>{c}</div>
  return c
}
