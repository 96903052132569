import { reactive } from "vue"
import { IconButton } from "./Button"
import { ICON } from "./icons"
import './Menu.scss'
import { hasRole, model } from "../model"
import { DropdownItem, Dropdown } from "./Dropdown"
import { useRoute } from "vue-router"
import { UI } from "../App"
import { Switch } from "./Switch"
import { getRouter } from "../utils/router"
import {link} from "../utils/routing"

import { scopeLink } from "../utils/routing"
import { t } from "../i18n"

import SCOPES_LOGO from "../../public/assets/astraia-icon.png"

const MENUS = () =>
    model.scopeId ? [
        [scopeLink("dashboard"), ICON("dashboard"), t("Dashboard")],
        [scopeLink("assets"), ICON("asset"), t("Assets")],
        [scopeLink("documents"), ICON("documents"), t("Tracking")],
        [scopeLink("alerts"), ICON("alert"), t("Alerts"), "analyst"],
        [scopeLink("cases"), ICON("analysis"), t("Cases")],
        [scopeLink("recommendations"), ICON("recommendation"), t("Recommendations")],
        [scopeLink("scenarios"), ICON("scenario"), t("Scenarios")],
        [scopeLink("vulnerabilities"), ICON("vulnerability"), t("Vulnerabilities")],
        [scopeLink("members"), ICON("members"), t("Members")],
        [scopeLink("settings"), ICON("settings"), t("Settings"), "analyst"],
    ]
    : [
        [link("/scopes"), ICON("scopes"), t("Scopes")],
        [link("/alerts"), ICON("alert"), t("Alerts")],
        [link("/cases"), ICON("analysis"), t("Analyses")],
        [link("/recommendations"), ICON("recommendation"), t("Recommendations")],
        [link("/scenarios"), ICON("scenario"), t("Scenarios")],
        [link("/vulnerabilities"), ICON("vulnerability"), t("Vulnerabilities"), "supervisor"],
        [link("/users"), ICON("members"), t("Users"), "admin"],
        [link("/config"), ICON("settings"), t("Configuration"), "supervisor"],
    ]

export const Menu = {
    setup() {
        const ui = reactive({collapse:false})
        function collapse() {
            ui.collapse = !ui.collapse
            document.body.classList.toggle("collapse-menu")
        }

        const is_selected = (to) => {
            const route = useRoute()
            return (to.name ? to.name===route.name : to===route.path) || to.includes(route.name)
        }

        return () => {
            const scope = model.scope
            const scopes = model.scopes || []
            const logo = scope?.logo
            const title = scope?.display_name || "Mes scopes"
            const admin = model.user?.is_admin

            function replaceRouteScope(id) {
                let p = getRouter().currentRoute?.value.fullPath
                // When changing scope, stay on same page for DO lists
                if(["assets","alerts","cases","recommendations","settings","scenarios","report","vulnerabilities"].some(x=>p.includes(x))) {
                    p = "/" + p.split("/").slice(2).join("/")
                    if(id===null) {
                        p = p.replace(/\/scope\/[^/]+\//,"/")
                    } else {
                        if(!p.match(/\/scope\/[^/]+\//)) p = "/scope/"+id+p
                        else p = p.replace(/\/scope\/[^/]+\//,"/scope/"+id+"/")
                        p = p.replace(/page=\d*/, "page=1") // Go back to page 1 if changing scope in table
                    }

                    return link(p)
                } 
                // Otherwise goto default page
                else {
                    if(id===null) return link("/")
                    else return link("/scope/" + id + "/")
                }
            }

            return <div id="menu" >
                    <IconButton id="collapse" onClick={collapse}>{ICON(ui.collapse ? "fas fa-bars" : "fas fa-chevron-left")}</IconButton>

                    <div id="logo">
                        <div><img src={logo} onError={(e)=>e.target.src=SCOPES_LOGO}/></div>
                        <h2>
                            {(admin || scopes.length>1) ?
                            <Dropdown button={title}>
                                <DropdownItem class="scope" to={replaceRouteScope(null)}>{ICON("scopes")}Tous mes scopes</DropdownItem>
                                {scopes.map(({id, display_name,logo})=><DropdownItem to={replaceRouteScope(id)}><img src={logo} alt=''/>{display_name}</DropdownItem>)}
                            </Dropdown>
                            : 
                            title
                            }
                        </h2>
                    </div>

                    <ul>
                        {MENUS().map(([to,icon,text,min_role])=>
                            (hasRole(min_role) || model.user?.is_admin) && <Item selected={is_selected(to)} key={to} to={to} icon={icon}>{text}</Item>
                        )}
                    </ul>

                    {model.scope?.kibana_space && hasRole("analyst") && <a href={`https://astraia.ct-squa.re/kb/s/${model.scope?.kibana_space}/app/dashboards`} target="_blank"><IconButton class="tool" tooltip="kibana">{ICON("kibana")}</IconButton></a>}

                    {CONFIG.DEBUG && <Switch value={UI.dbg} onClick={()=>UI.dbg=!UI.dbg}>Debug mode</Switch>}
                </div>
        }
    }
}   


const Item = ({to, icon, selected=false}, {slots}) => <li>
    <router-link to={to} class={{selected}}>
    {icon} <span>{slots.default()}</span>
    </router-link>
</li>