import { t } from "../i18n"
import { logout, model } from "../model"
import { Button } from "../ui/Button"
import { Fullscreen } from "../ui/Fullscreen"

export const NoScope = {
    setup() {
        return ()=>{
            const u = model.user?.display_name
            return <Fullscreen loading={!u}
            title={`${t("Welcome")} ${u}`}
            subtitle={t(`You don't have any scope yet`)}>
                <Button secondary onClick={logout}>{t("Sign out")}</Button>
        </Fullscreen>
        }
    }
}