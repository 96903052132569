import { range } from '../util'
import './InProgressIcon.scss'

export const InProgressIcon = ({N=12, moving=false})=><div class="ui-in-progress">
    {range(N).map(i=><div key={i} style={{
            animation: moving && 'ui-in-progress-spin 1.7s linear infinite',
            transform: `rotate(${i*360/(N)}deg)`,
            animationDelay: `${i*1.7/N}s`,
            opacity: `${i*1.7/N*100}%`,
    }}/>)}
</div>
