import FR from './fr'
import EN from './en'
import { model } from '../model'
import { getRouter } from '../utils/router'

export const i18n = () => model.lang === "fr" ? FR : EN
export const t = (...x) => model.lang === "fr" ? FR.t(...x): EN.t(...x)
export const t_n = (...x) => model.lang === "fr" ? FR.t_n(...x): EN.t_n(...x)
export const t_fem = (...x) => model.lang === "fr" ? FR.t_fem(...x): EN.t_fem(...x)
export const t_n_fem = (...x) => model.lang === "fr" ? FR.t_n_fem(...x): EN.t_n_fem(...x)


export function setLang(lang) {
    let p = getRouter().currentRoute?.value?.fullPath
    p = `/${lang}/` +  p.split("/").slice(2).join("/")
    getRouter().push(p)
}