import { model, API, hasRole } from "../model"
import { DataTable } from "../ui/DataTable"
import { optionsFromQueryParams, queryParams, setQueryParams, watchQueryParams } from "../utils/queryParams"
import { t } from "../i18n"
import { LiveQuery } from "../api/livequery"
import { Spinner } from "../ui/Spinner"
import { Recos } from "../ui/Recos"
import "./Scenarios.scss"
import { createTagDialog, Tags } from "../ui/Tags"
import {Link} from "../utils/router"
import {link} from "../utils/routing"

import { gotoScope } from "../utils/routing"
import { AddButton } from "../ui/AddButton"
import { ScopeItem } from "./Alerts"
import { Checkbox } from "../ui/Checkbox"
import {md} from "../ui/Markdown";

export const Scenarios = {
    setup() {
        const lq = LiveQuery("scenarios", `{
            count, pages, items {
                id, scope_id, title, body, created_at, updated_at, recos_by_status, tags, is_public
            }
        }`, s=>model.scenarios=s,
            ()=>[{
                scope_id:model.scopeId,
                sort:"updated_at",
                order:"desc",
                ...optionsFromQueryParams(),
            }, {scope_id:model.scopeId}], null, true
        )

        function addTag(s, x, color) { API.addTag("scenario", s.id, x, color) }
        function deleteTag(s, x) { API.deleteTag("scenario", s.id, x) }
        function createTag(a, tag, color) { createTagDialog(tag, color).then(({tag, color})=>addTag(a, tag, color)) }

        function togglePublic(c) { API.setScenario(c.id, {is_public:!c.is_public})}

        function newScenario() { gotoScope("newscenario") }

        return ()=>{
            // if(!model.scenarios) return <Spinner/>

            const allowed_tags = model.scope?.tags

            return <div id="scenarios">
                <DataTable
                    loading={lq.loading}
                    header={<>
                        {hasRole("analyst") && model.scopeId && <AddButton secondary onClick={newScenario}>{t("New Scenario")}</AddButton>}
                    </>}
                    data={model.scenarios}
                    rowClass={x=>!x.is_public && "private"}
                    columns={[
                        ...(model.scope ? [] : [{title:t("Scope"), type:'scope', render:ScopeItem}]),
                        {title:t('First activity'), type:'date', render:x=>x.created_at},
                        {title:t('Last activity'), type:'date', render:x=>x.updated_at},
                        {title:"Description", type:'description', render:x=><div class='description'>
                            <div><Link href={link(`scope/${x.scope_id}/scenario/${x.id}`)}>{x.title}</Link></div>
                            <div class="body" v-html={md.render(x.body)}/>
                        </div>},
                        {title:t("Recommendations"), render:Recos},
                        hasRole("analyst") && {title:"Public", render:x=><Checkbox value={x.is_public} onClick={()=>togglePublic(x)}/>},
                        {title:t('Tags'), type:"tags", noLink:true, render:a=>
                        <Tags tags={a.tags} allowed_tags={allowed_tags}
                            editable={hasRole("analyst")}
                            onAdd={(x, color)=>addTag(a, x, color)}
                            onDelete={x=>deleteTag(a, x)}
                            onCreate={(x, color)=>createTag(a, x, color)}
                        />
                        },
                    ]}

                    sortFields={{
                        updated_at:t("last activity"),
                        created_at:t("first activity"),
                        body:t("description"),
                        status:t("status"),
                        ...(model.scope ? {} : {scope_id:t("scope")})
                    }}
                    defaultSort={{sort:"updated_at", order:"desc"}}
                />
            </div>
            }
    }
}