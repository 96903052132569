import "./LosangeBackground.scss"

export const LosangeBackground = () => (
    <svg class="losange-background" viewBox="0 0 765 864" shadow="true">
        <g transform="translate(-972.000000, -118.000000)">
            <rect transform="translate(1350.500000, 603.500000) rotate(44.000000) translate(-1350.500000, -603.500000) " 
            x="1000" y="285" width="637" height="637" rx="175"/>
            <rect transform="translate(1358.500000, 496.500000) rotate(44.000000) translate(-1358.500000, -496.500000) " 
            x="1040.5" y="178.5" width="636" height="636" rx="175"/>
        </g>
    </svg>
)