import { reactive, watchEffect } from "vue";
import { useRoute } from "vue-router";
import {LiveQuery} from './api/livequery'
import { t } from "./i18n";
import { getMonth } from "./util";
import YAML from 'yaml';
import { goTo } from "./utils/routing";
import { getRouter } from "./utils/router";

export const API = require(CONFIG.MOCK ? "./api/mock" : "./api/api")

export const model = reactive({
    lang:'fr',
    user:false,
    token:null,
    config:{},
})

export const resolveObjectName = async ({type,id}) => ({
    alert:async ()=>model.scopeId && model.alertId && (await API.getAlert({id,scope_id:model.scopeId}))?.title,
    case:async ()=>model.scopeId && model.caseId && (await  API.getCase({id,scope_id:model.scopeId}))?.title,
    incident:async ()=>model.scopeId && model.incidentId && (await  API.getIncident({id,scope_id:model.scopeId}))?.title,
    scenario:async ()=>model.scopeId && model.scenarioId && (await  API.getScenario({id,scope_id:model.scopeId}))?.title,
    recommendation:async ()=>model.scopeId && model.recommendationId && (await  API.getRecommendation({id,scope_id:model.scopeId}))?.title,
    reports:async ()=>t(getMonth(model.month)) + " " + model.year
}[type] || (()=>""))()
        

// Login

export async function logout() {
    if(!localStorage.getItem("token")) return;
    localStorage.removeItem("token")
    await API.logout()
    document.location = getRouter().options.history.base
}

export async function login(email, password) {
    const {token} = await API.login(email, password) || {}
    if(token) connect(token)
    else {
        model.token = localStorage.removeItem("token")
        throw new Error()    
    }
}


export async function connect(token) {
    try {
        const u = await API.getCurrentUser()
        if(!u) throw new Error()
        localStorage.setItem("token", true)
        model.token = true
    }
    catch(e) {
        model.token = localStorage.removeItem("token")
        model.user = null
        goTo("/login")
    }
}

// Rights

const ROLES_HIERARCHY = ["manager", "operator", "analyst", "project_manager", "supervisor", "admin"]
export const hasRole = (r) => ROLES_HIERARCHY.indexOf(r) <= ROLES_HIERARCHY.indexOf(model.scope?.role) || (r==='supervisor' && model.user?.is_supervisor) || model.user?.is_admin

export const getMembers = ({search}={}) => model.members?.filter(m=>m.user?.display_name?.toLowerCase().includes(search.toLowerCase()))

// Live queries

export async function init_livequeries() {
    const route = useRoute()
    watchEffect(()=>{
        model.lang = route?.params.lang || 'fr'
        if(!model.user) return;
        if(model.scopes?.length<2 && !model.user.is_admin && !model.user.is_supervisor) model.scopeId = model.scopes?.[0]?.id
        else model.scopeId = route?.params.scopeId
        model.caseId = route?.params.caseId
        model.recommendationId = route?.params.recommendationId
        model.scenarioId = route?.params.scenarioId
        model.alertId = route?.params.alertId
        model.incidentId = route?.params.incidentId
        model.vulnerabilityId = route?.params.vulnerabilityId
        model.year = route?.params.year
        model.month = route?.params.month
    })

    LiveQuery("current_user", `{ 
        id, display_name, avatar, email, is_admin, is_supervisor, settings
    }`, 
        u=>model.user=u, 
        ()=>!!model.token && 
            []
        , null, false, {fetchInterval:false}
    )

    LiveQuery("scopes", `{
        items { id, display_name, logo, locked, kibana_space, role }
    }`, 
        s=>model.scopes=s?.items || s, 
        ()=>!!model.user && 
            [{limit:100}, null]
        , null, false, {fetchInterval:false}
    )    

    LiveQuery("scope", `{
        id, display_name, logo, role, kibana_space, magellan_url, locked, tags, api_token
    }`, 
        s=>model.scope=s, 
        ()=>!!model.user && !!model.scopeId && 
            [{id:model.scopeId}]
    )

    LiveQuery("case", `{
        id, title, body, conclusion, participants { id, user { display_name, avatar }, role }, 
        type, tags, steps, status, closed, created_at, updated_at, is_public,
        comments { id, body, visibility, created_at, updated_at, tags, seen,
            author { id, display_name, avatar },
            files { filename, url }
        }
    }`, 
        c=>model.case=c, 
        ()=>!!model.user && !!model.caseId && !!model.scopeId && 
            [{id:model.caseId, scope_id:model.scopeId}, {id:model.caseId}]
    )

    LiveQuery("alert", `{
        id, scope_id, title, body, tags, status, severity, original, created_at, updated_at, first_detection, last_detection
    }`, 
        c=>model.alert=c, 
        ()=>!!model.user && !!model.alertId && !!model.scopeId && 
            [{id:model.alertId, scope_id:model.scopeId}, {id:model.alertId}]
    )

    LiveQuery("scenario", `{
        id, scope_id, title, body, status,  tags, created_at, updated_at, is_public,
        comments { id, body, visibility, created_at, updated_at, seen,
            author { id, display_name, avatar },
            files { filename, url }
        }
    }`, 
        c=>model.scenario=c, 
        ()=>!!model.user && !!model.scenarioId && !!model.scopeId && 
            [{id:model.scenarioId, scope_id:model.scopeId}, {id:model.scenarioId}]
    )

    LiveQuery("recommendation", `{
        id, title, body, priority, tags, status, created_at, updated_at, is_public,
        comments { id, body, visibility, created_at, updated_at, seen
            author { id, display_name, avatar },
            files { filename, url }
        }
    }`, 
        c=>model.recommendation=c, 
        ()=>!!model.user && !!model.recommendationId && !!model.scopeId && 
            [{id:model.recommendationId, scope_id:model.scopeId}, {id:model.recommendationId}]
    )

    LiveQuery("report", `{
        id, cases, kpis, cases_page_break, is_public,
        cases_by_status, cases_by_type, recos_by_status,
        coordination, control_points, vulnerabilities, summary
    }`, 
        x=>model.report=x, 
        ()=>!!model.user && !!model.scopeId && !!model.year && !!model.month && 
            [{month:model.month, scope_id:model.scopeId, year:model.year}]
        , null, false, {fetchInterval:false}
    )

    LiveQuery("scope", `{
        members{id, user{id, display_name, avatar, activated}, role, created_at, last_connection}
    }`, 
        c=>model.members=c?.members, 
        ()=>!!model.user && !!model.scopeId && 
            [{id:model.scopeId}]
    )

    LiveQuery("config_drilldown", ``, 
        c=>{c && (model.config.drilldown=YAML.parse(c))}, 
        ()=>!!model.user && !!model.alert && []
    )

    LiveQuery("vulnerability", `{
        id, cve_id, body, original,
        scopes { id, display_name, logo }
    }`,
        c=>model.vulnerability=c,
        ()=>!!model.user && !!model.vulnerabilityId &&
            [{id:model.vulnerabilityId}, {id:model.vulnerabilityId}]
    )
}
