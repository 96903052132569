import { model } from "../model";
import { getRouter } from "./router";


export function goTo(path) {
    if(path[0]==="/") path=path.slice(1)
    getRouter().push(`/${model.lang}/${path}`)
}

export function gotoScope(path) {
    if(path[0]==="/") path=path.slice(1)
    goTo(`scope/${model.scopeId}/${path}`)
}

export function link(path) {
    if(path[0]==="/") path=path.slice(1)
    return `/${model.lang || "fr"}/${path}`
}

export function scopeLink(path) {
    if(path[0]==="/") path=path.slice(1)
    return link(`scope/${model.scopeId}/${path}`)
}