import { reactive } from "vue"
import { useRoute } from "vue-router"
import { router } from "../../main"
import { API, model } from "../../model"
import { Button } from "../../ui/Button"
import { FullError } from "../../ui/FullError"
import { Fullscreen } from "../../ui/Fullscreen"
import { Input } from "../../ui/Input"
import { ERR, INFO } from "../../ui/Toasts"
import { t } from "../../i18n"

export const Signup = {
    setup() {
        API.logout()
        const {token} = useRoute().params
        
        const ui = reactive({
            password:"",
            password2:"",
            user:null,
            invalid:false
        })

        const doSetPassword = () => {
            if(!ui.user) return;
            if(ui.password !== ui.password2) return ERR(t("Passwords do not match !"))
            API.setPassword({token, user_id:ui.user.id, password:ui.password})
            .then(()=>{
                INFO(t("Password successfully set !"))
                setTimeout(()=>router.push('/'), 1000)
            })
            .catch(()=>{
                ERR(t("Unexpected error"))
                setTimeout(()=>router.push('/'), 1000)
            })
        }

        API.activate({token}).then(u=>ui.user=u).catch(()=>ui.invalid = true)
  
        return ()=>{
            const u = ui.user?.display_name
            if(ui.invalid) {
                setTimeout(()=>router.push('/'), 5000)
                return <FullError>
                    <p>{t("Invalid token !")}</p>
                    <p><router-link to='/' onClick={API.logout}>{t("Back to login page")}</router-link></p>
                </FullError>
            }
        
            return <Fullscreen loading={!u}
                title={`${t("Welcome")} ${u}`}
                subtitle={<>
                    {t(`This is your first time in CT-Square Astraia`)}<br/>
                    {t(`Please set your password before connecting`)}</>
                }>
                <form onSubmit={e=>{e.preventDefault();doSetPassword()}}>
                    <Input autofocus label={t("Password")} value={ui.password} type="password" onInput={e=>ui.password = e.target.value}/>
                    <Input label={t("Confirm password")} value={ui.password2} type="password" onInput={e=>ui.password2 = e.target.value} validate={x=>x===ui.password}/>
                    <Button type="submit" disabled={!ui.password || !ui.password2}>{t("Set my password")}</Button>
                </form>
            </Fullscreen>
        }
    }
}