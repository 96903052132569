import { reactive, watch, watchEffect } from '@vue/runtime-core'
import { Dropdown, DropdownItem } from './Dropdown'
import { Input } from './Input'
import './DropdownCombo.scss'
import { t } from '../i18n'
import { ICON } from './icons'
import { InProgressIcon } from './InProgressIcon'
import { Button } from './Button'

export const DropdownCombo = {
    props:["iconbutton", "menuClass", "onSelect", "items", "render", "tooltip", "footer", "onCreate", "createLabel", "filter"],
    setup(props) {
        const data = reactive({open:false, filter:""})
        return ()=>{
            const render = props.render || (x=>x)
            const filter = props.filter || (filter=>props.items?.filter(x=>x?.toLowerCase?.().includes?.(filter.toLowerCase())) || [])
            const items = filter(data.filter)
            return <Dropdown
                    menuClass={props.menuClass + " combo"}
                    tooltip={props.tooltip}
                    iconbutton={props.iconbutton}
                    onOpen={()=>{data.open=true; data.filter=""}}
                    onClose={()=>{data.open=false;}}
                    top={<Input 
                            autofocus
                            placeholder={t("Search...")} 
                            value={data.filter} 
                            onInput={e=>data.filter = e.target.value} 
                            pre={ICON("search")} 
                            post={data.loading ? <InProgressIcon/> : <small>{items?.length||0} {t('results')}</small>}
                    />}
                >
                {items?.map(x=><DropdownItem onClick={()=>props.onSelect?.(x)}>{render(x)}</DropdownItem>)}
                {props.onCreate && data.filter && items?.length===0 && <Button secondary onClick={()=>props.onCreate(data.filter)}>{props.createLabel || t("Create new")}</Button> }
                {props.footer}
            </Dropdown>
        }
    }
}
