import { ICON } from "./icons"
import {IconButton} from "./Button";

export const Upload = ({afterUpload, files, params}) =>
    files.length > 0 &&
    <IconButton class="upload" onClick={()=> {
      files.forEach(async (f) => {
        const formData = new FormData();
        formData.append('file', f);
        params.map((p)=> {
          const [key] = Object.keys(p)
          formData.append(key, p[key])
        })
        await fetch("/files", {
          method: 'POST',
          body: formData
        })
      })
      afterUpload && afterUpload()
    }}>
      {ICON("upload")}
    </IconButton>
