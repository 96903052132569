import {reactive} from "@vue/reactivity"
import { watchEffect } from "@vue/runtime-core"
import {API, model} from "../model"
import {t, t_fem} from "../i18n";
import {arrayUnique} from "../util";
import {Box} from "../ui/Grid";
import './Vulnerability.scss'
import {Sidebar} from "../ui/Sidebar";
import {ICON} from "../ui/icons";
import {Badge} from "../ui/Badge";
import {modal} from "../ui/Modal";
import {Dialog} from "../ui/Dialog";
import {JSONView} from "../ui/JSONView";
import {Dropdown, DropdownItem} from "../ui/Dropdown";
import {Spinner} from "../ui/Spinner";
import {H1} from "../ui/H";
import {Button, IconButton} from "../ui/Button";
import {Checkbox} from "../ui/Checkbox";
import {Description} from "../ui/Description";

export const Vulnerability = {
    setup() {
        const data = reactive({
            vulnerability: {
                products: []
            },
            scopes: new Set([]),
            updated: false
        })

        function cvss_score(cvss) {
            if (cvss === 0) {
                return 'None'
            } else if (cvss < 4) {
                return 'Low'
            } else if (cvss < 7) {
                return 'Medium'
            } else if (cvss < 9) {
                return 'High'
            } else {
                return 'Critical'
            }
        }

        function cvss_exploitability(x) {
            let vector = {}
            x.split('/').forEach(y => {
                let [k, v] = y.split(':')
                vector[k] = v
            })

            let [AccessVector, AccessComplexity, Authentication] = [0, 0, 0]

            switch (vector['AV']) {
                case 'L':
                    AccessVector = 0.395;
                    break;
                case 'A':
                    AccessVector = 0.646;
                    break;
                case 'N':
                    AccessVector = 1.0;
                    break;
            }

            switch (vector['AC']) {
                case 'H':
                    AccessComplexity = 0.35;
                    break;
                case 'M':
                    AccessComplexity = 0.61;
                    break;
                case 'L':
                    AccessComplexity = 0.71;
                    break;
            }

            switch (vector['Au']) {
                case 'M':
                    Authentication = 0.45;
                    break;
                case 'S':
                    Authentication = 0.56;
                    break;
                case 'N':
                    Authentication = 0.704;
                    break;
            }

            return 20 * AccessVector * AccessComplexity * Authentication
        }

        function cvss_impact(x) {
            let vector = {}
            x.split('/').forEach(y => {
                let [k, v] = y.split(':')
                vector[k] = v
            })

            let [ConfImpact, IntegImpact, AvailImpact] = [0, 0, 0]

            switch (vector['C']) {
                case 'N':
                    ConfImpact = 0.0;
                    break;
                case 'P':
                    ConfImpact = 0.275;
                    break;
                case 'C':
                    ConfImpact = 0.660;
                    break;
            }

            switch (vector['I']) {
                case 'N':
                    IntegImpact = 0.0;
                    break;
                case 'P':
                    IntegImpact = 0.275;
                    break;
                case 'C':
                    IntegImpact = 0.660;
                    break;
            }

            switch (vector['A']) {
                case 'N':
                    AvailImpact = 0.0;
                    break;
                case 'P':
                    AvailImpact = 0.275;
                    break;
                case 'C':
                    AvailImpact = 0.660;
                    break;
            }

            return 10.41 * (1 - (1 - ConfImpact) * (1 - IntegImpact) * (1 - AvailImpact))
        }

        function products(x) {
            let res = {}
            if (x) {
                x.map(p => {
                    let y = p.split(':')
                    let [vendor, product] = [y[3], y[4]]
                    if (vendor in res) {
                        if (!res[vendor].includes(product)) res[vendor].push(product)
                    } else {
                        res[vendor] = [product]
                    }
                })
            }
            return res
        }

        function openJSON() {
            return modal(({close}) =>
                <Dialog close={close} onSubmit={() => close(data)}
                        header={t("JSON object")}
                        body={<JSONView source={data.vulnerability?.original}/>}
                />
            )
        }

        function save() {
            API.updateVulnerability({scope_ids: Array.from(data.scopes), original: JSON.stringify(data.vulnerability?.original), id: model.vulnerabilityId})
            data.updated = false
        }

        function cancel() {
            data.scopes = new Set(model.vulnerability?.scopes.map(x => x.id))
            data.vulnerability.original = model.vulnerability.original
            data.updated = false
        }

        async function refresh() {
            for (let k in data.vulnerability) if (k !== 'cve_id') delete data.vulnerability[k]
            const t = await API.vulnerabilitySearch(model.vulnerability.cve_id)
            data.vulnerability.original = t
            data.updated = true
        }

        function setDescription(d) { API.setVulnerabilityDescription(model.vulnerabilityId, d) }

        watchEffect(()=> {
            data.scopes = new Set(model.vulnerability?.scopes.map(x => x.id))
        })

        return () => {
            if(!model.vulnerability) return <Spinner/>

            if (!data.vulnerability.original) data.vulnerability.original = model.vulnerability.original
            data.vulnerability.products = products(data.vulnerability?.original?.vulnerable_product)

            return <div id="vulnerability" class="page with-sidebar">
                <div>
                    <H1 class="title" value={model.vulnerability.cve_id}/>
                    {!model.scopeId && <div className='filter-scopes'>
                        <Dropdown
                            button={t('Scopes') + ' (' + data.scopes.size + ')'}>{arrayUnique(model.vulnerability.scopes.concat(model.scopes), 'id').map((scope) =>
                            <DropdownItem onClick={(e) => {
                                data.scopes.delete(scope.id) || data.scopes.add(scope.id)
                                e.stopPropagation()
                                e.preventDefault()
                            }}>
                                <Checkbox value={data.scopes.has(scope.id.toString())}/>
                                <img src={scope.logo} alt=''/>
                                {scope.display_name}
                            </DropdownItem>)}
                        </Dropdown>
                    </div>}
                    <Description
                        editable={!model.scopeId}
                        title="Description"
                        source={model.vulnerability.body}
                        onSave={setDescription}
                    />
                    <div class='vulnerability-fields'>
                        <Box>
                            <h2>{t('Summary')}</h2>
                            <span>{data.vulnerability?.original?.summary}</span>
                        </Box>
                        <Box>
                            <h2>{t('CVSS v2.0')}</h2>
                            {data.vulnerability?.original?.cvss && <div class='cvss'>
                                <Box class={'score ' + cvss_score(data.vulnerability?.original?.cvss)}>
                                    <h3>{data.vulnerability?.original?.cvss.toFixed(1)}<sup>/10</sup></h3>
                                    <p>CVSS 2.0 : {t(cvss_score(data.vulnerability?.original?.cvss))}</p>
                                    <div>
                                        <a href='https://www.first.org/cvss/v2/guide' target='_blank'>V2
                                            Legend {ICON("go")}</a>
                                    </div>
                                </Box>
                                <div>
                                    <Box><b>{t('Access Vector')}</b><Badge
                                        class={'vector ' + data.vulnerability?.original?.access.vector}>{t(data.vulnerability?.original?.access.vector)}</Badge></Box>
                                    <Box><b>{t('Access Complexity')}</b><Badge
                                        class={'complexity ' + data.vulnerability?.original?.access.complexity}>{t_fem(data.vulnerability?.original?.access.complexity)}</Badge></Box>
                                    <Box><b>{t('Authentication')}</b><Badge
                                        class={'authentication ' + data.vulnerability?.original?.access.authentication}>{t_fem(data.vulnerability?.original?.access.authentication)}</Badge></Box>
                                </div>
                                <div>
                                    <Box><b>{t('Confidentiality Impact')}</b><Badge
                                        class={'confidentiality ' + data.vulnerability?.original?.impact.confidentiality}>{t(data.vulnerability?.original?.impact.confidentiality)}</Badge></Box>
                                    <Box><b>{t('Integrity Impact')}</b><Badge
                                        class={'integrity ' + data.vulnerability?.original?.impact.integrity}>{t(data.vulnerability?.original?.impact.integrity)}</Badge></Box>
                                    <Box><b>{t('Availability Impact')}</b><Badge
                                        class={'availability ' + data.vulnerability?.original?.impact.availability}>{t(data.vulnerability?.original?.impact.availability)}</Badge></Box>
                                </div>
                                <div>
                                    <p><strong>{t('Vector')} : </strong>{data.vulnerability?.original?.['cvss-vector']}</p>
                                    <p>
                                        <strong>{t('Exploitability')} : </strong>{(Math.round(cvss_exploitability(data.vulnerability?.original?.['cvss-vector']) * 10) / 10).toFixed(1)} /&nbsp;
                                        <strong>{t('Impact')} : </strong>{(Math.round(cvss_impact(data.vulnerability?.original?.['cvss-vector']) * 10) / 10).toFixed(1)}
                                    </p>
                                </div>
                            </div>}
                        </Box>
                        <Box class="references">
                            <h2>{t("References")}</h2>
                            {data.vulnerability?.original?.references && <ul>
                                {data.vulnerability?.original?.references.map(r => <a href={r} target='_blank'>
                                    <li>{r}</li>
                                </a>)}
                            </ul>}
                        </Box>
                        <Box class="configurations">
                            <h2>{t("Configurations")}</h2>
                            {data.vulnerability?.original?.vulnerable_configuration && <ul>
                                {data.vulnerability?.original?.vulnerable_configuration.map(r => <li>{r.title}</li>)}
                            </ul>}
                        </Box>
                    </div>
                </div>
                <Sidebar>
                    {!model.scopeId && <div className='buttons'>
                        <Button
                            disabled={JSON.stringify(Array.from(data.scopes).sort()) === JSON.stringify(model.vulnerability.scopes.map(x => x.id).sort()) && !data.updated}
                            onClick={() => save(data.vulnerability)}>{t("Save changes")}</Button>
                        <Button
                            disabled={JSON.stringify(Array.from(data.scopes).sort()) === JSON.stringify(model.vulnerability.scopes.map(x => x.id).sort()) && !data.updated}
                            secondary onClick={cancel}>{t("Cancel")}</Button>
                        <IconButton onClick={refresh} tooltip={t("refresh")}>{ICON("refresh")}</IconButton>
                    </div>}
                    <Box class={'information' + (model.scopeId ? ' noButtons' : '')}>
                        <h2>{t('Information')}</h2>
                        {data.vulnerability?.original?.Published && <p>
                            <strong>{t('Published')} : </strong>{data.vulnerability?.original?.Published.split('T')[0]} {data.vulnerability?.original?.Published.split('T')[1].slice(0, -3)}
                        </p>}
                        {data.vulnerability?.original?.Modified && <p>
                            <strong>{t('Updated')} : </strong>{data.vulnerability?.original?.Modified.split('T')[0]} {data.vulnerability?.original?.Modified.split('T')[1].slice(0, -3)}
                        </p>}
                        {data.vulnerability?.original?.id && <>
                            <hr/>
                            <p>{ICON("go")} <strong>NVD : </strong><a target='_blank'
                                                                           href={`https://nvd.nist.gov/vuln/detail/${data.vulnerability?.original?.id}`}>{data.vulnerability?.original?.id}</a>
                            </p>
                            <p>{ICON("go")} <strong>Mitre : </strong><a target='_blank'
                                                                             href={`https://cve.mitre.org/cgi-bin/cvename.cgi?name=${data.vulnerability?.original?.id}`}>{data.vulnerability?.original?.id}</a>
                            </p>
                        </>}
                        {data.vulnerability?.original && <>
                            <hr/>
                            <p>{ICON("json")} <strong>JSON : </strong> <IconButton onClick={openJSON}>{ICON("eye")}</IconButton></p>
                        </>}
                    </Box>
                    <Box class='products'>
                        <h2>{t('Products affected')}</h2>
                        {data.vulnerability.products && <ul>
                            {Object.keys(data.vulnerability.products).map(v => {
                                return <li>
                                    <span>{v}</span>
                                    <ul>
                                        {data.vulnerability.products[v].map(pp => <li>{pp}</li>)}
                                    </ul>
                                </li>
                            })}
                        </ul>}
                    </Box>
                </Sidebar>
            </div>
        }
    }
}