import './Fullscreen.scss'
import { Screen } from './Screen'
import { Spinner } from './Spinner'

export const Fullscreen = ({title,subtitle,loading},{slots}) =>
<Screen class='fullscreen'>
    {loading ? <Spinner/> : <>
    <div>
      <img src="/assets/astraia-logo.png"/>
      <h1>{title}</h1>
      <h2>{subtitle}</h2>
    </div>
    <div><div>
      {slots?.default?.()}
    </div></div></>}
</Screen>