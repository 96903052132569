import { markRaw, onMounted, onUnmounted, onUpdated, reactive, ref, toRaw, watchEffect } from 'vue';
import { Accordion, AccordionItem } from './Accordion';
import { IconButton } from './Button';
import './CustomGrid.scss'
import { ICON } from './icons';

export const CustomGrid = {
    props:['layout', 'onChange', 'library', 'onSave'],
    setup(props) {
        const ui = reactive({
            editing:false,
            layout:props.layout
        })
        let grid = null;
        const el = ref(null)

        watchEffect(()=>{ui.layout = props.layout})

        watchEffect(()=>{
            $("#page").toggleClass("with-sidebar", ui.editing)
            init()
        })
        onMounted(init)
        function init() {
            if(!el.value) return;
            const existed = !!grid
            if(existed) grid.destroy(false)
            grid = GridStack.init({
                float:true,
                cellHeight:"70px",
                minRow:1,
                acceptWidgets: true,
                dragIn: '.grid-toolbar .grid-stack-item',
                dragInOptions:  {revert:'invalid', scroll:false, appendTo:"body", helper:'clone'},
                compact:false,
                staticGrid:!ui.editing,
            }, el.value)
            grid.batchUpdate()
            if(!existed) {
                grid.on("change", () => {
                    var tmp = {}
                    ui.layout.forEach(item=>tmp[item.id] = item)
                    grid.getGridItems().forEach(item=>{
                        const o = tmp[item.getAttribute("gs-i")]
                        o.x = parseInt(item.getAttribute("gs-x"))
                        o.y = parseInt(item.getAttribute("gs-y"))
                        o.w = parseInt(item.getAttribute("gs-w"))
                        o.h = parseInt(item.getAttribute("gs-h"))
                    })
                    onChange()
                });   
                grid.on("added", (e,[item])=>{
                    if(item.el.hasAttribute("gs-i")) return;
                    grid.removeWidget(item.el, true, true)
                    const {x,y,w,h} = item
                    const name = item.el.getAttribute("name")
                    addWidget({x,y,w,h,name})
                })
            }
        }
        onUnmounted(()=>{
            $("#page").removeClass("with-sidebar")
        })

        function onChange() {
            props.onChange?.(toRaw(ui.layout))
        }

        function addWidget({x,y,w,h,name}) {
            const id = ui.layout.reduce((cur,{id})=>id>cur ? id : cur, 0) + 1
            ui.layout.push(markRaw({
                id,x,y,w,h,name
            }))
            onChange()
        }

        function removeWidget(id) {
            ui.layout = ui.layout.filter(item=>item.id!==id)
            onChange()
        }

        function edit() {
            ui.editing = true
            ui.oldLayout = JSON.parse(JSON.stringify(ui.layout))
        }

        function save() {
            props.onSave?.(ui.layout)
            ui.editing=false
        }

        function cancel() {
            ui.layout = ui.oldLayout
            $("#page").removeClass("with-sidebar")
            ui.editing=false
        }


        onUpdated(init)

        return ()=><>
        <div ref={el} class="grid-stack">
            {ui.layout.map(({x,y,w,h,id,name})=>
            <div key={id} class="grid-stack-item" gs-x={x} gs-y={y} gs-w={w} gs-h={h} gs-i={id}>
                <div class="grid-stack-item-content">
                <div class="widget">
                    {name}
                    {ui.editing && <IconButton onClick={()=>removeWidget(id)}>{ICON("fas fa-times")}</IconButton>}
                </div>
                </div>
            </div>)}
        </div>
        {ui.editing ?
        <div class="grid-toolbar">
            <Accordion>
            <AccordionItem title="soih sdiuh">
                soisfoij
            </AccordionItem>
            <AccordionItem title="Widgets">
                <Library onChange={init} items={props.library}/>
            </AccordionItem>
            </Accordion>
            <div class="buttons">
                <IconButton onClick={save}>{ICON("fas fa-save")}</IconButton>
                <IconButton onClick={cancel}>{ICON("fas fa-times")}</IconButton>
            </div>
        </div>
        :
        <IconButton id="edit-dash" onClick={edit}>{ICON("fas fa-edit")}</IconButton>
        }
        </>

    }
}

const Library = {
    props:['items', 'onChange'],
    setup(props) {
        onMounted(()=>props.onChange())
        onUpdated(()=>props.onChange())
        return ()=>props.items?.map(item=> 
            <div class="grid-stack-item" gs-w="2" gs-h="1" name={item}>
                <div class="grid-stack-item-content">{item}</div>
            </div>
        )
    }
}
