import './Select.scss'

import { Dropdown, DropdownItem } from "./Dropdown"

export const Select = ({value="", placeholder="", pre, post}, {slots}) => (
    <div class="input-group select flex-nowrap">
      <Dropdown button={<span>{value || placeholder}</span>}>
        {slots?.default?.()}
      </Dropdown>
        {post}
    </div>
  )
  
  export const SelectItem = DropdownItem
  
  
  