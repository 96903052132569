import './NotFound.scss'
import {Button} from "./Button";
import {Screen} from "./Screen";
import {t} from "../i18n";

export const NotFound = {
    setup() {
        return ()=>{
            document.title = "Astraia | 404"
            return <Screen><div class='not-found'>
                <h1>4<span><img src="/assets/astraia-dead.png"/></span>4</h1>
                <h2>{t("Error : 404 page not found")}</h2>
                <p>{t("Sorry, the page you're looking for cannot be accessed")}</p>
                <Button onClick={()=>window.location.href='/'}>
                    <i className="fas fa-home"/>
                    <span>{t("Go back to main page")}</span>
                </Button>
            </div></Screen>
        }
    }
}