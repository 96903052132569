import { reactive } from "vue"
import { useRoute } from "vue-router"
import { router } from "../../main"
import { API, logout } from "../../model"
import { Button } from "../../ui/Button"
import { Input } from "../../ui/Input"
import { ERR, INFO } from "../../ui/Toasts"
import { t } from "../../i18n"
import { Fullscreen } from '../../ui/Fullscreen'
import { FullError } from '../../ui/FullError'

export const ResetPassword = {
    setup() {
        API.logout()
        const {token} = useRoute().params
        const ui = reactive({
            password:"",
            password2:"",
            user:null,
            invalid:false
        })

        const doSetPassword = () => {
            if(!ui.user) return;
            if(ui.password !== ui.password2) return ERR(t("Passwords do not match !"))
            API.setPassword({token, user_id:ui.user.id, password:ui.password})
            .then(()=>{
                INFO(t("Password successfully updated !"))
                setTimeout(()=>document.location=router.options.history.base, 1000)
            })
            .catch((e)=>{
                console.error(e)
                ERR(t("Unexpected error"))
                setTimeout(()=>document.location=router.options.history.base, 1000)
            })
        }

        API.activate({token}).then(u=>ui.user=u).catch(()=>ui.invalid = true)
  
        return ()=>{
            const u = ui.user?.display_name
            if(ui.invalid) {
                const leave = setTimeout(()=>document.location='/', 5000)
                return <FullError>
                    <p>{t("Invalid token !")}</p>
                    <p><router-link to={'/'} onClick={async () => {
                        clearTimeout(leave)
                        await logout()
                    }}>{t("Back to login page")}</router-link></p>
                </FullError>
            }
        
            return <Fullscreen loading={!u}
            title={t("Welcome ")+u}
            subtitle={<>
                {t("You have requested a password reset")}<br/>
                {t("Please reset your password now")}</>
                }>
                <form onSubmit={e=>{e.preventDefault();doSetPassword()}}>
                    <Input autofocus label={t("New password")} value={ui.password} type="password" onInput={e=>ui.password = e.target.value} />
                    <Input label={t("Confirm password")} value={ui.password2} type="password" onInput={e=>ui.password2 = e.target.value} />
                    <Button type="submit" disabled={!ui.password || !ui.password2}>{t("Reset my password")}</Button>
                </form>
            </Fullscreen>
        }
    }
}