import { reactive } from "@vue/reactivity"
import { t } from "../../i18n"
import { API, model } from "../../model"
import { Button } from "../../ui/Button"
import { Description } from "../../ui/Description"
import { ICON } from "../../ui/icons"
import { Input } from "../../ui/Input"
import { queryParams } from "../../utils/queryParams"
import { gotoScope } from "../../utils/routing"
import './New.scss'

export const NewRecommendation = {
    setup() {
        const data = reactive({ 
            recommendation:{
                scope_id:model.scopeId,
                body:queryParams().body||"",
            },
            related_scenarios: queryParams().related_scenarios?.split(","),
            related_cases: queryParams().related_cases?.split(","),
        })

        async function save() {
            const {id} = await API.createRecommendation(data.recommendation)
            for(var caseId of data.related_cases||[]) API.addRelatedCase("recommendation", id, caseId)
            for(var scenarioId of data.related_scenarios||[]) API.addRelatedScenario("recommendation", id, scenarioId)
            gotoScope(`recommendation/${id}`)
        }

        function cancel() {
            gotoScope("recommendations")
        }

        return ()=><div id="new-recommendation" class="new">
            <div>
                <h1>
                    {ICON("recommendation")} 
                    <Input placeholder={t("New recommendation...")} 
                        value={data.recommendation.title} 
                        onInput={e=>data.recommendation.title = e.target.value}
                    />
                </h1>

                <Description
                    editing
                    title="Description"
                    source={data.recommendation.body}
                    onInput={x=>data.recommendation.body = x}
                />

                <br/>

                <div class="create-controls">
                    <Button disabled={!data.recommendation.title || !data.recommendation.body} onClick={save}>{t("Create")}</Button>
                    <Button secondary onClick={cancel}>{t("Cancel")}</Button>
                </div>
            </div>
        </div>
    }
}
    