import './TableView.scss'

export const TableView = {
    props: ["source"],
    setup(props) {
        function unwrap(obj, prefix) {
            let res = {};
            if (obj) {
                for (let k of Object.keys(obj)) {
                    let val = obj[k], key = prefix ? prefix + '.' + k : k;
                    if (Array.isArray(val)) res[key] = val.join(', ')
                    else if (typeof val === 'object') Object.assign(res, unwrap(val, key)); // <-- recursion
                    else res[key] = val;
                }
            }
            return res;
        }

        const json = unwrap(typeof(props.source)==="string" ? JSON.parse(props.source) : props.source)

        return () => {
            return <div class='table'>
                <pre>
                <code>
                    <table>
                        {Object.keys(json).sort().map(x => <tr>
                            <td>{x}</td>
                            <td>{json[x]}</td>
                        </tr>)}
                    </table>
                </code>
            </pre>
            </div>
        }
    }
}