import { capitalize } from "../util"

const plural = (text)=>({
    "Case open": "Analyses en cours",
    "Case closed": "Analyses clôturées",
    "Recommendation pending": "Recommandations en attente",
    "Recommendation validated": "Recommandations validées",
    "validated out of": "validées sur",
    "alert selected": "alertes selectionnées",
    "False positive": "Faux positifs",
    "Investigating": "En cours",
    "case:All": "Toutes",
    "case:Open": "Ouvertes",
    "case:Closed": "Closes",
    "reco:All": "Toutes",
    "reco:Open": "Ouvertes",
    "reco:Closed": "Closes",
    "case:Done": "Closes",
    "Waiting": "En attente",
    "alert": "alertes",
})[text]||(t(text)+"s")

const t = (text, opts)=> {
    if(opts?.fem) return t_fem(text, opts) 
    if(opts?.nb>1) return plural(text) 
    const x = {
    "Public": "Publique",
    "last edited": "dernière modification",
    "last detection": "dernière détection",
    "first detection": "première détection",
    "Triggering event": "Événement déclencheur",
    "First detection": "Première détection",
    "Last detection": "Dernière détection",
    "Detection date": "Date de détection",
    "Generate": "Générer",
    "No result": "Aucun resultat",
    "No case active in last 30 days": "Aucune analyse active dans les 30 derniers jours",
    "Activated": "Activé",
    "Password reset requested": "Réinitialisation du mot de passe demandée",
    "New password": "Nouveau mot de passe",
    "You have requested a password reset": "Vous avez demandé une réinitialisation de votre mot de passe",
    "Please reset your password now": " ",
    "Reset my password": "Définir mon mot de passe",
    "Passwords do not match !": "Les mots de passe ne correspondent pas !",
    "Password successfully updated !": "Votre mot de passe a été mis à jour",
    "Resend activation email": "Renvoyer l'email d'activation",
    "Invalid token !": "Jeton invalide",
    "Back to login page": "Retour à la page de connexion",
    "Welcome ":"Bienvenue ",
    "Reset password": "Réinitialiser le mot de passe",
    "Activation email sent to ": "Email d'activation envoyé à ",
    "Password reset email sent to ": "Email de réinitialisation de mot de passe envoyé à ",
    "Set my password": "Définir mon mot de passe",
    "Add related case": "Lier à une analyse",
    "Case open": "Analyse en cours",
    "Case closed": "Analyse clôturée",
    "Report already exists. Do you really want to overwrite KPI data ?": "Voulez-vous vraiment écraser les données de KPI ?",
    "Overwrite existing cases too !": "Écraser aussi les analyses existantes !",
    "Overwrite existing report": "Mettre à jour le rapport existant",
    "Report already exists": "Ce rapport existe déjà",
    "Recommendation pending": "Recommandation en attente",
    "Recommendation validated": "Recommandation validée",
    "Detection": "Détection",
    "Patrol": "Patrouille",
    "detection": "détection",
    "patrol": "patrouille",
    "Last activity": "Dernière activité",
    "First activity": "Première activité",
    "Status": "Statut",
    "Recommendation": "Recommandation",
    "Recommendations": "Recommandations",
    "Vulnerabilities": "Vulnérabilités",
    "Cancelled": "Annulé",
    "False positive": "Faux positif",
    "Analysis": "Analyse",
    "Planning": "Planification",
    "Activity report": "Rapport d'activité",
    "Network logs": "Logs réseau", 
    "System logs": "Logs système",
    "Cloud logs": "Logs Cloud",
    "Generate new report": "Générer un rapport",
    "last days": "derniers jours",
    "Recommendations pending": "Recommandations non validées",
    "detection date": "date de détection",
    "validated out of": "validée sur",
    "Search...": "Rechercher...",
    "Sort by": "Trier par",
    "analysts": "analystes",
    "public": "public",
    "private": "privé",
    "edited": "édité",
    "Save": "Sauvegarder",
    "Cancel": "Annuler",
    "second": "seconde",
    "hour": "heure",
    "day": "jour",
    "week": "semaine",
    "month": "mois",
    "year": "an",
    "report": "rapport mensuel",
    "Sent as email": "Envoyé par email",
    "results": "resultats",
    "alerts": "alertes",
    "Alerts": "Alertes",
    "Conclusions": "Conclusions de l'analyse",
    "analyst": "analyste",
    "manager": "manager",
    "Priority": "Priorité",
    "Send as email": "Envoyer par email",
    "Add to playbook": "Ajouter au playbook",
    "Add to monthly report": "Ajouter au rapport mensuel",
    "Convert to recommendation": "Convertir en recommandation",
    "Add related scenario": "Lier un scénario",
    "Add related recommendation": "Lier une recommandation",
    "Add related alert": "Lier une alerte",
    "Add alert": "Ajouter une alerte",
    "Add participant": "Ajouter un participant",
    "Visibility": "Visibilité",
    "Add tags": "Ajouter des tags",
    "Locked": "Verrouillé",
    "Supervisor": "Superviseur",
    "# Alerts": "# Alertes",
    "# Cases": "# Analyses",
    "# Recommendations": "# Recommandations",
    "alert selected": "alerte selectionnée",
    "Ignore": "Ignorer",
    "Add to case": "Ajouter à l'analyse",
    "Severity": "Gravité",
    "Save changes": "Sauvegarder",
    "alert:New": "Nouvelle",
    "Investigating": "En cours", 
    "alert:Done": "Traitée",
    "alert:Ignored": "Ignorée",
    "severity": "gravité",
    "Qualify selected alerts": "Qualifier les alertes selectionnées",
    "open": "En cours",
    "Criticality": "Criticité",
    "Create": "Créer",
    "New Scope": "Nouveau Scope",
    "New Alert": "Nouvelle Alerte",
    "New Recommendation": "Nouvelle Recommandation",
    "New Scenario": "Nouveau Scenario",
    "New Vulnerability": "Nouvelle vulnérabilité",
    "New scope": "Nouveau scope",
    "Profile": "Mon compte",
    "profile": "mon compte",
    "Add member": "Ajouter un membre",
    "Add": "Ajouter",
    "Add scope member": "Ajouter un membre au scope",
    "My scopes": "Mes scopes",
    "User": "Utilisateur",
    "reports": "Rapports",
    "users": "utilisateurs",
    "Users": "Utilisateurs",
    "Attach to case": "Rattacher à une analyse",
    "Rule": "Règle de détection",
    "author": "auteur",
    "Raw alert": "Alerte brute",
    "Members": "Membres",
    "Settings": "Configuration",
    "Cases": "Analyses",
    "Assets": "Actifs",
    "case:All": "Toutes",
    "case:Open": "Ouverte",
    "case:Closed": "Close",
    "reco:All": "Toutes",
    "reco:Open": "Ouverte",
    "reco:Closed": "Close",
    "Waiting": "En attente",
    "case:Done": "Close",
    "case:Canceled": "Annulée",
    "reco:Done": "Appliquée",
    "reco:open": "Ouverte",
    "reco:canceled": "Annulée",
    "reco:done": "Appliquée",
    "reco:draft": "Brouillon",
    "reco:deleted": "Supprimée", 
    "reco:archived": "Archivée",
    "reco:validated": "Validée",
    "reco:rejected": "Rejetée",
    "reco:closed": "Fermée",
    "reco:archived-validated": "Validée et archivée",
    "reco:todo": "Emise", 
    "Last signin": "Dernière connexion",
    "Created": "Date de création",
    "cases":"analyses",
    "vulnerabilities":"vulnérabilités",
    "members":"membres",
    "settings":"configuration",
    "New user": "Nouvel utilisateur",
    "Invalid email": "Email invalide",
    "New scenario...": "Nouveau scenario...",
    "New case...": "Nouvelle analyse...",
    "New incident...": "Nouvel incident...",
    "New recommendation...": "Nouvelle recommandation...",
    "New alert...": "Nouvelle alerte...",
    "New scenario": "Nouveau scenario",
    "New recommendation": "Nouvelle recommandation", 
    "New case": "Nouvelle analyse",
    "New incident": "Nouvel incident",
    "New alert": "Nouvelle alerte",
    'cancel': 'annulé',
    "containment": "confinement",
    "mitigation": "remédiation",
    "recovery": "rétablissement",
    "closed": "clôturé",
    "priority": "priorité",
    "Regenerate report": "Regénérer le rapport",
    "Unread messages": "Messages non lus",
    "Tracking":"Suivi",

    "January": "Janvier",
    "February": "Fevrier",
    "March": "Mars",
    "April": "Avril",
    "May": "Mai",
    "June": "Juin",
    "July": "Juillet",
    "August": "Août",
    "September": "Septembre",
    "October": "Octobre",
    "November": "Novembre",
    "December": "Décembre",
    "just now": "à l'instant",
    "project_manager": "Chef de Projet",
    "Select user...": "Selectionnez un utilisateur...",
    "Name": "Nom",
    "Invalid email or password": "Email ou mot de passe invalide",

    "No recommendation": "Aucune recommandation\n sur cette période",
    "draft": "brouillon",
    "doing": "En cours",
    "done": "Validé",
    "process": "traitement",
    "returned": "restitution",
    "nothing to declare": "rien à signaler",
    "canceled": "annulé",
    "waiting": "en attente",
    "new": "nouveau",
    "Last 30 days": "30 derniers jours",
    "first activity": "première activité",
    "last activity": "dernière activité",
    "status":"statut",
    "recommendations":"recommandations",
    "Sign out": "Deconnexion",
    "Create new": "Créer un nouveau", 
    "Create this tag": "Créer ce tag",
    "Aggregated alerts": "Alertes aggrégées",
    "recommendations pending": "recommandations en attente",
    "Monthly reports": "Rapports mensuels",
    "ignore": "ignoré",
    "You don't have any scope yet": "Vous n'êtes membre d'aucun périmètre",
    "user": "utilisateur",
    "Last login": "Dernière connexion",
    "operator": "opérateur",
    "none": "aucun",
    "Refresh": "Rafraichir",
    "Welcome": "Bienvenue", 
    "This is your first time in CT-Square Astraia": "C'est votre première visite dans CT-Square Astraia",
    "Please set your password before connecting": "Veuillez définir un mot de passe avant de vous connecter",
    "Password": "Mot de passe", 
    "Confirm password": "Confirmez votre mot de passe",
    "Dashboard": "Tableau de bord",
    "audit:done": "clôturé",
    "incident:done":"clôturé",
    "incident-step:done": "résolu",
    "false positive": "faux positif",
    "Investigate via external tools": "Investiguer via des outils externes",
    "No report for": "Aucun rapport mensuel pour",
    "Generate report": "Générer le rapport",
    "You don't have any files":"Aucun fichier",
    "since last refresh": "depuis le dernier rafraichissement",
    "alert":"alerte",
    "Open a new case": "Ouvrir une nouvelle analyse",
    "Add to existing case": "Ajouter à une analyse existante",
    "Download as JSON": "Télécharger en JSON",
    "Download as CSV": "Télécharger en CSV",
    "Edit": "Editer",

    "Error : 404 page not found": "Erreur : 404 page introuvable",
    "Sorry, the page you're looking for cannot be accessed": "Désolé, la page que vous cherchez n'est pas accessible",
    "Go back to main page": "Retour à l'accueil",

    "Filter scopes": "Filtrer les scopes",
    "Select all": "Tout sélectionner",
    "Filename": "Nom de fichier",
    "Cotech reports": "Cotechs",

    "Show privates": "Privées",
    "Active": "Active",
    "Today": "Aujourd'hui",
    "In": "Dans les",

    "Hostname": "Nom de domaine",
    "Network": "Réseau",
    "Kind": "Type",
    "Vendor": "Fournisseur",
    "Datasets": "Données",
    "Accounts": "Comptes",

    "Vendors": "Distributeurs",
    "Products": "Produits",
    "Updated": "Mise à jour",
    "Publised": "Publiée",
    "Summary": "Résumé",
    "References": "Références",
    "Products affected": "Produits concernés",
    "Exploitability": "Exploitabilité",
    "Vector": "Vecteur",
    "Access Vector": "Vecteur d'accès",
    "Access Complexity": "Complexité d'accès",
    "Authentication": "Authentification",
    "Confidentiality Impact": "Impact sur la confidentialité",
    "Integrity Impact": "Impact sur l'intégrité",
    "Availability Impact": "Impact sur la disponibilité",
    "None": "Aucun",
    "Low": "Faible",
    "Medium": "Moyen",
    "High": "Élevé",
    "Critical": "Critique",
    "LOCAL": "LOCAL",
    "ADJACENT NETWORK": "RÉSEAU ADJACENT",
    "NETWORK": "RÉSEAU",
    "NONE": "AUCUN",
    "PARTIAL": "PARTIEL",
    "COMPLETE": "COMPLET",
    "Select Scopes": "Sélectionner les scopes",
    }
    return (opts?.type && x[opts.type+":"+text]) || x[text] || text
}

const t_fem = (text, opts)=> {
    const x = {
        "HIGH": "ÉLEVÉE",
        "MEDIUM": "MOYENNE",
        "LOW": "FAIBLE",
        "NONE": "AUCUNE",
        "SIGLE": "SIMPLE",
        "MULTIPLE": "MULTIPLE",
        "Cancelled": "Annulée",
        "Validated": "Validée",
        "done": "Validée",
        "canceled": "Annulée",
        "new": "nouvelle",
        "private": "privée",
        "public": "publique",
        "ignore": "ignorée",
        "muted": "ignorée",
        "false-positive": "faux positif",
        "false positive": "faux positif",
        "acknowledged": "qualifiée",
        "investigating": "analyse en cours",
        "resolved": "traitée",
        "detection:done": "clôturée",
        "patrol:done":"clôturée",
        "doing": "analyse en cours",
        "nothing to declare": "rien à signaler",
        "since last refresh": "depuis le dernier rafraichissement",
        "alert": "alerte",
    }
    const r = (opts?.type && x[opts.type+":"+text]) || x[text] || t(text) 
    if(opts?.nb>1) return plural(r) 
    return r
}

const t_n = (nb, text)=>(
    nb>0 ? nb + " " + capitalize(t(text,{nb}))
    : "Aucun " + t(text,{nb})
)

const t_n_fem = (nb, text)=>(
    nb>0 ? nb + " " + capitalize(t(text,{nb}))
    : "Aucune " + t(text,{nb})
)

export default {t_fem,t_n,t,t_n_fem}