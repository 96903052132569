import './Dialog.scss'

export const Dialog = ({close, buttons, header, body, onSubmit}) => (
    <div class="modal-dialog">
        <form class="modal-content" onSubmit={e=>{e.preventDefault();onSubmit?.()}}>
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{header}</h5>
            {close && <button type="button" class="btn-close" onClick={()=>close(false)}></button>}
        </div>
        <div class="modal-body">
            {body}
        </div>
        {buttons && <div class="modal-footer">
            {buttons}
        </div>}
        </form>
    </div>
)