import {query} from "../api/graphql";
import {ERR} from "../ui/Toasts";
import {t} from "../i18n";

const converter = require('json-2-csv')

export const download = async (source, format, name) => {
    switch (format) {
        case "json":
            const data = JSON.stringify(typeof(source)==="string" ? JSON.parse(source) : source, null, 4)
            let a = document.createElement('a');
            a.href = 'data:' + `data:text/json;charset=utf-8,` + encodeURIComponent(data);
            a.download = `${name}.json`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            break
        case "csv":
            converter.json2csv(typeof(source)==="string" ? JSON.parse(source) : source, (err, csv) => {
                if(err){
                    ERR(t("canceled"))
                }
                else{
                    let a = document.createElement('a');
                    a.href = 'data:' + `data:text/csv;charset=utf-8,` + encodeURIComponent(csv);
                    a.download = `${name}.csv`;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                }
            })
            break
    }
}

export const download_alerts_as_json = async (selectedItems, scope_id) => {
    let alerts = []
    for (let id of selectedItems) {
        const res = await query('alert', {id: id, scope_id: scope_id}, '{original}')
        const source = res.original
        const data = JSON.stringify(typeof(source)==="string" ? JSON.parse(source) : source, null, 4)
        alerts.push(data)
    }
    let a = document.createElement('a');
    a.href = 'data:' + `data:text/json;charset=utf-8,` + encodeURIComponent(alerts);
    a.download = `alerts.json`;
    document.body.appendChild(a);
    a.click();
    a.remove();
}

export const download_alerts_as_csv = async (selectedItems, scope_id) => {
    let alerts = []
    for (let id of selectedItems) {
        const res = await query('alert', {id: id, scope_id: scope_id}, '{original}')
        const source = res.original
        alerts.push(source)
    }
    converter.json2csv(alerts, (err, csv) => {
        if(err){
            ERR(t("canceled"))
        }
        else{
            let a = document.createElement('a');
            a.href = 'data:' + `data:text/csv;charset=utf-8,` + encodeURIComponent(csv);
            a.download = `alerts.csv`;
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
    })
}