import { capitalize } from "../util"
import {query, mutation, subscribe, gql_encode_vars} from "./graphql"

/////////////////////////////////////////////////////////////////
// LIVEQUERIES (=> subscriptions with same name)

export const getCurrentUser = () =>         query("current_user", {}, `{
    id, display_name, avatar
}`)

// Paginated collections : args=({..., search, limit, page, sort, order })
export const getScopes = args =>            query("scopes", args, `{
    count, pages
    items {
        id, display_name, logo
    }
}`)
export const getIncidents = args =>         query("incidents", args, `{
    count, pages
    items {
        id, scope_id, title, body
    }
}`)
export const getAlerts = args =>            query("alerts", args, `{
    count, pages
    items {
        id, scope_id, title, body
    }
}`)
export const getScenarios = args =>         query("scenarios", args, `{
    count, pages
    items {
        id, scope_id, title, body
    }
}`)        
export const getUsers = args =>             query("users", args, `{
    count, pages
    items {
        id, display_name, email, avatar
    }
}`)
export const getCases = args =>             query("cases", args, `{
    count, pages
    items {
        id, scope_id, title, body
    }
}`)
export const getRecommendations = args =>   query("recommendations", args, `{
    count, pages
    items {
        id, scope_id, title, body
    }
}`)

// Objects by id
export const getScope = ({id}) =>             query("scope", {id}, '{id, display_name, logo}')
export const getCase = ({id, scope_id}) =>    query("case", {id, scope_id}, '{id, title}')
export const getRecommendation = ({id, scope_id}) =>    query("recommendation", {id, scope_id}, '{id, title}')
export const getScenario = ({id, scope_id}) => query("scenario", {id, scope_id}, '{id, title}')
export const getAlert = ({id, scope_id}) =>   query("alert", {id, scope_id}, '{id, title}')
export const getIncident = ({id, scope_id}) => query("incident", {id, scope_id}, '{id, title}')
export const getReport = ({id}) => query("report", {id}, '{id, month, year, scope_id}')
export const getUser = ({id}) => query("user", {id}, '{id, display_name, email, avatar}')

export const getReports = ({scopeId}) =>      query("reports", {scopeId})

// Aggregated collections
export const getRecommendationsByStatus = ({scopeId}) =>   query("recommendations_by_status", {scopeId})
export const getCasesByStatus = ({scopeId}) =>             query("cases_by_status", {scopeId})
export const getAlertsByStatus = ({scopeId}) =>            query("alerts_by_status", {scopeId})



export const getAlertSiblings = async (scope_id, id, args) => (await query("alert",{id, scope_id},`{siblings${gql_encode_vars(args)}{count,total,search,pages,items{id,scope_id,title,first_detection,body}}}`))?.siblings
export const getAlertCases = async (scope_id, id, args) => (await query("alert",{id, scope_id},`{cases${gql_encode_vars(args)}{count,pages,items{id,scope_id,title,created_at,body}}}`))?.cases
export const getCaseAlerts = async (scope_id, id, args) => (await query("case",{id, scope_id},`{alerts${gql_encode_vars(args)}{items{id,scope_id,title,created_at,body},count,total,search}}`))?.alerts


/////////////////////////////////////////////////////////////////
// NON-LIVE QUERIES (no need for subscription)

export const getRelated = async (type, scope_id, id, related, vars) => (await (
    query(type, {id,scope_id}, `{related_${related}${gql_encode_vars(vars)} {
    items { id, title, body, scope_id },
    count, total, search
    } }`)
))?.[`related_${related}`]


export const getRelatedAlerts = async (type, scope_id, id, vars) => getRelated(type, scope_id, id, "alerts", vars)
export const getRelatedCases =  async (type, scope_id, id, vars) => getRelated(type, scope_id, id, "cases", vars)
export const getRelatedRecommendations = async (type, scope_id, id, vars) => getRelated(type, scope_id, id, "recommendations", vars)
export const getRelatedScenarios =  async (type, scope_id, id, vars) => getRelated(type, scope_id, id, "scenarios", vars)

export const getObjects = async ({search, scope_id, type}) => {
    if(!Array.isArray(type)) type = [type]
    const out = {}
    for(var t of type) {
        out[t] = {
            "alert":()=>getAlerts({search, scope_id}),
            "recommendation":()=>getRecommendations({search, scope_id}),
            "scenario":()=>getScenarios({search, scope_id}),
            "case":()=>getCases({search, scope_id})
        }[t]()
    }
    for(var t of type) out[t] = (await out[t]).items
    const ret = []
    for(var t of type) {
        ret.push(...out[t].map(x=>({...x, type:t})))
    }
    return ret
}





/////////////////////////////////////////////////////////////////
// MUTATIONS (return nothing)

// alert
export const createAlert = ({scope_id, title, body, date, severity}) => mutation("alert_create", {scope_id, title, body, date, severity})
export const setAlertTitle = (id, title) => mutation("alert_update", {id, title})
export const setAlertDescription = (id, body) => mutation("alert_update", {id, body})
export const setAlertStatus = (id, status) => mutation("alert_update", {id, status})
export const setAlertSeverity = (id, severity) => mutation("alert_update", {id, severity})
export const setRawAlert = (id, fields) => mutation("alert_update", {id, fields})
export const setAlert = (id, {timestamp, last_detection}) => mutation("alert_update", {id, timestamp, last_detection})
export const investigateAlert = (id, case_id) => mutation("alert_investigate", {id, case_id})
export const uninvestigateAlert = (id) => mutation("alert_uninvestigate", {id})

// case
export const createCase = ({scope_id, alerts, title, body, checklist="", type}) => mutation("case_create", {scope_id, alerts, title, body, checklist, type})
export const createIncident = ({scope_id, alerts, title, body, checklist=""}) => mutation("case_create", {scope_id, alerts, title, body, checklist, type: 'incident'})
export const setCaseType = (id, type) => mutation('case_update', {id, type})
export const setCaseTitle = (id, title) => mutation("case_update", {id, title})
export const setCaseDescription = (id, body) => mutation("case_update", {id, body})
export const setCaseConclusion = (id, conclusion) => mutation("case_update", {id, conclusion})
export const setCaseStep = (id, step) => mutation("case_update", {id, step})
export const resetCaseSteps = (id) => mutation("case_reset_steps", {id})
export const setCase = (id, {is_public, created_at, updated_at}) => mutation("case_update", {id, is_public, created_at, updated_at})
export const addCaseParticipant = (id, member_id) => mutation("case_participant_add", {id, member_id}, '')
export const removeCaseParticipant = (id, member_id) => mutation("case_participant_delete", {id, member_id}, '')

// recommendation
export const createRecommendation = ({scope_id, title, body}) => mutation("recommendation_create", {scope_id, title, body})
export const setRecommendationTitle = (id, title) => mutation("recommendation_update", {id, title})
export const setRecommendationDescription = (id, body) => mutation("recommendation_update", {id, body})
export const setRecommendationStatus = (id, status) => mutation("recommendation_update", {id, status})
export const setRecommendation = (id, {is_public, created_at, updated_at}) => mutation("recommendation_update", {id, is_public, created_at, updated_at})
export const setRecommendationPriority = (id, priority) => mutation("recommendation_update", {id, priority})

// scenario
export const createScenario = ({scope_id, title, body}) => mutation("scenario_create", {scope_id, title, body})
export const setScenarioDescription = (id, body) => mutation("scenario_update", {id,body})
export const setScenarioStatus = (id, status) => mutation("scenario_update", {id,status})
export const setScenarioTitle = (id, title) => mutation("scenario_update", {id,title})
export const setScenario = (id, {is_public, created_at, updated_at}) => mutation("scenario_update", {id, is_public, created_at, updated_at})

// scope
export const createScope = ({display_name, logo, locked, kibana_space}) => mutation("scope_create", {display_name, logo, locked, kibana_space}, '{id}')
export const setScope = (id, {display_name, logo, checklist, support, locked, kibana_space, magellan_url, coordination, control_points, vulnerabilities_monitoring}) => mutation("scope_update", {id, display_name, logo, checklist, support, locked, kibana_space, magellan_url, coordination, control_points, vulnerabilities_monitoring})

// user
export const createUser = ({email, avatar, display_name, is_admin}) => mutation("user_create", {email, avatar, display_name, is_admin})
export const setUser = (id, {display_name, kibana_auth, locked, email, avatar, settings}) => mutation("user_update", {id, display_name, locked, email, avatar, kibana_auth, settings})
export const toggleAdmin = (id) => mutation("user_toggle_admin", {id})
export const toggleSupervisor = (id) => mutation("user_toggle_supervisor", {id})
export const login = (email, password) =>   mutation("login", {email, password}, '{token}')
export const logout = () =>                 mutation("logout", {}, '')
export const setPassword = ({token, user_id, password}) => mutation("set_password", {token, user_id, password}, '{id, display_name, avatar}')
export const activate = ({token}) => mutation('activate', {token}, '{id, display_name, avatar}')
export const resendActivationEmail = (user_id) => mutation("resend_activation", {id:user_id}, '')
export const reset_password = (user_id) => mutation("reset_password", {user_id}, '')
export const requestPasswordReset = (email) => mutation("request_password_reset", {email}, '')

// tags
export const addTag = (type, ids, tag, color) => mutation('tag', {
    id:!Array.isArray(ids) ? ids : undefined,
    ids:Array.isArray(ids) ? ids : undefined,
    tag, type, color
}, '')
export const deleteTag = (type, id, tag) => mutation('untag', {id,type,tag}, '')

export const destroyTag = ({id}) => console.error("NOT IMPLEMENTED") // TODO IMPLEMENT
export const updateTag = ({id, tag, color}) => mutation('tag_update', {id, tag, color}, '')

// comments
export const addComment = async (type, id, files, {body, visibility}) => {
    const blobKeys = []
    await Promise.all(files.map(async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('object_type', type);
        formData.append('object_id', id);
        const response = await fetch("/files", {
            method: 'POST',
            body: formData
        })
        const json = await response.json()
        blobKeys.push(json.blob_key)
    }))
    return mutation('comment_create', {type:capitalize(type), id, body, visibility, files: blobKeys})
}
export const removeComment = (id) => mutation("comment_delete", {id})
export const setComment = (id, {body, visibility}) => mutation("comment_update", {id, body, visibility})
export function addCommentObject(id, objType, objId) {}
export function deleteCommentObject(id, objType, objId) {}

// members
export const addMember = ({scope_id, user_id, role}) => mutation("role_create", {scope_id, user_id, role})
export const removeMember = ({id}) => mutation("role_delete", {id})
export const setMember = (id, {role}) => mutation("role_update", {id, role})

// related

export const addRelated = (object_type, object_id, linked_object_type, linked_object_id) => mutation("link_create", {object_type, object_id, linked_object_type, linked_object_id}, '{linked_object{id},object{id}}')
export const removeRelated = (object_type, object_id, linked_object_type, linked_object_id) => mutation("link_delete", {object_type, object_id, linked_object_type, linked_object_id}, '{linked_object{id},object{id}}')

export const addRelatedAlert = (type, id, alertId) => addRelated(capitalize(type), id, "Alert", alertId)
export const addRelatedCase = (type, id, caseId) => addRelated(capitalize(type), id, "Case", caseId)
export const addRelatedRecommendation = (type, id, recommendationId) => addRelated(capitalize(type), id, "Recommendation", recommendationId)
export const addRelatedScenario = (type, id, scenarioId) => addRelated(capitalize(type), id, "Scenario", scenarioId)
export const removeRelatedAlert = (type, id, alertId) => removeRelated(capitalize(type), id, "Alert", alertId)
export const removeRelatedCase = (type, id, caseId) => removeRelated(capitalize(type), id, "Case", caseId)
export const removeRelatedRecommendation = (type, id, recommendationId) => removeRelated(capitalize(type), id, "Recommendation", recommendationId)
export const removeRelatedScenario = (type, id, scenarioId) => removeRelated(capitalize(type), id, "Scenario", scenarioId)


// report
export const setReport = (id, {cases, is_public, summary, control_points, vulnerabilities, cases_page_break, coordination}) => mutation("report_update", {id, summary, control_points, vulnerabilities, cases_page_break, coordination, cases, is_public}, '{id}')
export const generateReport = ({scope_id, year, month, overwrite_cases=false}) => mutation("report_create", {scope_id, year, month, overwrite_cases})

// config

export const setDrilldownConfig = (data) => mutation("config_drilldown_update", {data}, '')
export const setMattermostWebhook = (data) => mutation("mattermost_webhook_update", {data}, '')

export const assetsTest = (url) => query("assets_test", {url})

export const vulnerabilitySearch = (id) => query("vulnerability_search", {id})
export const createVulnerability = ({scope_ids, cve_id, body, original}) => mutation("vulnerability_create", {scope_ids, cve_id, body, original}, '{cve_id}')
export const updateVulnerability = ({scope_ids, original, id}) => mutation("vulnerability_update", {scope_ids, original, id}, '{cve_id}')
export const setVulnerabilityDescription = (id, body) => mutation("vulnerability_update", {id, body}, '{cve_id}')