import { reactive } from "@vue/reactivity"
import { model } from "../model"
import { t } from "../i18n"
import { Badge } from "./Badge"
import { Button } from "./Button"
import { ColorPicker } from "./ColorPicker"
import { Dialog } from "./Dialog"
import { DropdownCombo } from "./DropdownCombo"
import { ICON } from "./icons"
import { Input } from "./Input"
import { modal } from "./Modal"
import "./Tags.scss"

export const AddTagButton = ({allowed_tags, addTag, onCreate}) => (
    <DropdownCombo 
        menuClass="add-tag"
        iconbutton={ICON("add-tag")} 
        tooltip="Add tags" 
        items={allowed_tags}
        filter={filter=>allowed_tags?.filter(x=>x.tag?.toLowerCase().includes?.(filter?.toLowerCase()))}
        render={({tag, color})=><><span class="thumb" style={{backgroundColor:color}}/>{tag}</>}
        onSelect={tag=>addTag(tag.tag, tag.color)}
        onCreate={onCreate}
        createLabel={t("Create this tag")}
    />
)

/** @param {tags} list of strings or {tag,color} */
export const Tags = ({editable, tags, allowed_tags, onAdd, onDelete, onCreate}) => {
    tags = tags?.map(tag=>typeof(tag)==="string" ? ({tag}) : tag) 
    const available_tags = allowed_tags?.filter(t=>!tags?.find(x=>x.tag===t.tag))
    return <div class="tags">
        {tags?.map(({tag, color})=><Badge onDelete={editable && onDelete && (()=>onDelete(tag))} style={color && {backgroundColor:color}}>{t(tag)}</Badge>)}
        {editable && onAdd && <>
            <AddTagButton allowed_tags={available_tags} addTag={onAdd} onCreate={onCreate}/>
            {!tags?.length && <small>{t("Add tags")}</small>}
        </>}
    </div>
}



export function createTagDialog(tag, color) {
    if(!color) color = "#"+Math.floor(Math.random()*16777215).toString(16);
    const data = reactive({tag,color})
    const validate = t => t===tag || !model.scope?.tags.map(x=>x.tag).includes(t)
    return modal(({close})=>
        <Dialog close={close} onSubmit={()=>close(data)}
            header={t("Edit tag")}
            body={<>
                <ColorPicker value={data.color} onChange={e=>data.color=e.target.value} />
                <Input autofocus label="Name" value={data.tag} onInput={e=>data.tag=e.target.value} validate={validate} />
            </>}
            buttons={<>
                <Button secondary onClick={()=>close(null)}>{t("Cancel")}</Button>
                <Button type="submit" disabled={!validate(data.tag)}>{t("OK")}</Button>
            </>}
        />
    )
}