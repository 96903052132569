import { model } from "../model"
import { DataTable } from "../ui/DataTable"
import { optionsFromQueryParams } from "../utils/queryParams"
import {t} from "../i18n"
import { LiveQuery } from "../api/livequery"
import {goTo, link} from "../utils/routing"
import { AddButton } from "../ui/AddButton"
import { Badge } from "../ui/Badge";
import { capitalize } from "../util";
import './Vulnerabilities.scss'
import {Link} from "../utils/router";

export const Vulnerabilities = {
    setup() {
        const lq = LiveQuery("vulnerabilities", `{
            count, pages, items {
                id, cve_id, body, original, scopes {id, display_name}
            }
        }`, s=>model.vulnerabilities = s,
            ()=>[{
                scope_id:model.scopeId,
                ...optionsFromQueryParams(),
            }, {scope_id:model.scopeId}], null, true
        )

        function newVulnerability() { goTo("newvulnerability") }

        function get_products(x) {
            let res = {}
            if (x) {
                x.map(p => {
                    let y = p.split(':')
                    let [vendor, product] = [y[3], y[4]]
                    if (vendor in res) {
                        if (!res[vendor].includes(product)) res[vendor].push(product)
                    } else {
                        res[vendor] = [product]
                    }
                })
            }
            return res
        }

        function cvss_score(cvss) {
            if (cvss === 0) {
                return 'None'
            } else if (cvss < 4) {
                return 'Low'
            } else if (cvss < 7) {
                return 'Medium'
            } else if (cvss < 9) {
                return 'High'
            } else {
                return 'Critical'
            }
        }

        return ()=>{

            return <div id="vulnerabilities">
                <DataTable
                    loading={lq.loading}
                    header={<>
                        {!model.scopeId && <AddButton secondary onClick={newVulnerability}>{t("New Vulnerability")}</AddButton>}
                    </>}
                    data={model.vulnerabilities}
                    columns={[
                        {title:t('CVE'), render:x=><Link href={link(model.scopeId ? `scope/${model.scopeId}/vulnerability/${x.id}` : `vulnerability/${x.id}`)}>{x.cve_id}</Link>},
                        {title:t('Vendors'), render:x=> {
                          let vendors = Object.keys(get_products(x.original.vulnerable_product))
                          return <><Badge>{vendors.length}</Badge>{vendors.map(v => capitalize(v)).join()}</>
                        }},
                        {title:t('Products'), render:x=> {
                                let parsed_products = get_products(x.original.vulnerable_product)
                                let vendors = Object.keys(parsed_products)
                                let products = []
                                vendors.map(v => {
                                    products = products.concat(parsed_products[v])
                                })
                                return <><Badge>{products.length}</Badge>{products.map(p => capitalize(p.replace('_', ' '))).join()}</>
                            }},
                        {title:t('Updated'), render:x=>x.original.Modified?.split('T')[0]},
                        {title:t('CVSS v2'), render:x=>x.original.cvss && <Badge class={cvss_score(x.original.cvss)}>{x.original.cvss.toFixed(1)} {cvss_score(x.original.cvss).toUpperCase()}</Badge>},
                        !model.scopeId && {title:t('Scopes'), render:x=><Badge>{x.scopes.length}</Badge>},
                    ]}
                    subrow={x => <ul>
                        <li>{t('Description')} : {x.body}</li>
                        <li>{t('Summary')} : {x.original.summary}</li>
                    </ul>}
                />
            </div>
            }
    }
}