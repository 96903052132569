import { onUnmounted, reactive, watchEffect } from "vue"
import { UI } from "../App"
import { DBG_INFOS } from "../ui/DBG"
import { ERR } from "../ui/Toasts"

const { query, subscribe } = require(CONFIG.MOCK ? "./mock" : "./graphql")

const livequeries = reactive({})
DBG_INFOS.livequeries = livequeries

let GUID=0

/**
 * Creates a reactive graphql livequery that is automatically unsubscribed/resubscribed when watched
 * reactive variables change
 * @param {string} q : the GQL query name
 * @param {string} fields : requested fields, in GQL nested format
 * 
 * @param {function} cb : function called on every update from the server 
 * (called with 'null' on unsubscribe, but NOT on resubscription for new variables)
 * 
 * @param {()=>[vars,svars?]?} watchVariables : a function passed to watchEffect that 
 * hooks to reactive variables and return [query_variables,subscription_variables] to 
 * resubscribe with new variables or null (or any falsey value) to unsubscribe until variables change
 * if svars is undefined => svars=vars
 * 
 * @param {string?} s : the GQL subscription name (set to ${q} if not defined)
 */
export const LiveQuery = (q,fields,cb,watchVariables, s, unsubscribeWhenUnmounted=false, {
    onError, 
    fetchInterval=(UI.active_polling_interval||20)*1000,
    throttle_timeout = 2000
}={}) => {

    if(!s) s = q
    let subscribed = false
    let id = null
    const guid = GUID++
    let stopWatchingVariables = null

    const state = reactive({loading:false})

    const lq = {
        async subscribe(vars, svars) {
            if(!vars || Object.keys(vars).length<=0) vars = null
            if(!svars || Object.keys(svars).length<=0) svars = null
            const newid = JSON.stringify({q,vars,fields,s})

            if(subscribed) {
                if(newid===id) return;
                this.unsubscribe()
            }

            id = newid
            subscribed = true

            // Create the supporting subscription
            if(!livequeries[newid]) livequeries[newid] = {
                q,vars,fields,s,svars,
                listeners:[],
                notify(data) {
                    livequeries[newid]?.listeners?.forEach(l=>{
                        livequeries[newid].ticks++;
                        l.cb?.(data)
                    })
                    livequeries[newid]?.listeners?.forEach(l=>l.onLoading?.(false))
                },
            }

            // Actually connect to the subscription backend (backend rejection allowed, will be retried on next subscription)
            if(!livequeries[newid].sub) {
                try {
                    let to = null;
                    let throttled = false;
                    const sub = await subscribe(s, svars, ()=>{
                        async function doRefresh() {
                            throttled = true;
                            if(to) clearTimeout(to)
                            if(window.dbg_lq) console.log("%c"+"refresh"+"%c"+q+ (vars ? ('('+JSON.stringify(vars)+")") : ""), 'background:#00f5;color:black;padding:0px 4px;', 'color:green;padding:0px 4px;')
                            livequeries[newid]?.listeners?.forEach(l=>l.onLoading?.(true))
                            await doQuery()
                            throttled = false;
                            to = setTimeout(()=>{
                                to = null;
                                if(throttled) doRefresh()
                            }, throttle_timeout)
                        }
                        if(to || throttled) throttled = true;
                        else doRefresh()
                    })
                    if(!livequeries[newid]) return this;
                    livequeries[newid].sub = sub
                } catch(e) {
                    if(onError) onError(e)
                    else {
                        console.error(q, e);
                        ERR(e?.toString())
                    }
                }
            } 

            async function doQuery(cb = ()=>{}, onError = ()=>{}) {
                return query(q, vars, fields)
                    .then(x=>{
                        if(newid!==id || !livequeries[newid]) return;
                        if(window.dbg_lq) console.info(q, "output : ", x)
                        livequeries[newid]?.notify(x)
                        return cb(x)
                    })
                    .catch(onError)
                    .finally(()=>{
                        // Active polling
                        if(subscribed && fetchInterval && !!UI.enable_active_polling) {
                            livequeries[newid].fetchInterval = setTimeout(()=>{
                                doQuery(cb,onError)
                            }, fetchInterval)
                        }
                    })
            }

            if(window.dbg_lq) console.log("%c"+q, 'background:green;color:white;padding:5px;margin-top:15px;')
            if(window.dbg_lq) console.info("params : (",vars,",", svars,")")
            if(window.dbg_lq) console.info("fields: ", fields)

            // Listen to subscription
            if(livequeries[newid]) livequeries[newid].listeners.push({
                guid, cb,
                onLoading:x=>state.loading = x
            });

            state.loading = true
            doQuery(()=>state.loading=false, e=>{
                if(onError) onError(e)
                else {
                    console.error(q, e);
                    ERR(e?.toString())
                }
            })

            async function refresh() {
                livequeries[newid]?.listeners?.forEach(l=>l.onLoading?.(true))
                await doQuery()
            }
            this.refresh = refresh.bind(this)

            return this
        },

        unsubscribe(stopWatching=false) {
            state.loading = false;
            if(stopWatching) stopWatchingVariables?.()
            if(!subscribed) return;
            subscribed = false
            if(!livequeries[id]) return;
            livequeries[id].listeners.splice(livequeries[id].listeners.findIndex(l=>l.guid===guid),1)
            if(livequeries[id].listeners?.length<=0) {
                livequeries[id].sub?.unsubscribe()
                if(livequeries[id].fetchInterval) livequeries[id].fetchInterval = clearTimeout(livequeries[id].fetchInterval)
                delete livequeries[id]
            }
            return this
        },
    }

    stopWatchingVariables = watchEffect(() => {
        if(!lq) return;
        const vars = watchVariables()
        if(!vars) {
            if(subscribed) {
                lq.unsubscribe()
                cb?.(null)
            }
        } else {
            let [variables,sub_variables] = vars
            if(sub_variables===undefined) sub_variables = variables
            lq.subscribe(variables,sub_variables)
        }
    })

    if(unsubscribeWhenUnmounted) {
        onUnmounted(()=>lq?.unsubscribe())
    }

    state.lq = lq;
    return state
}