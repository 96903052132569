import {reactive, ref} from "@vue/reactivity"
import {onMounted, watchEffect} from "@vue/runtime-core";
import {isToday} from "./dates";
import {model} from "../model";
import './DatePicker.scss'

export const DatePicker = {
    props: ["onChange"],
    setup(props) {
        const data = reactive({
            date: '',
            picker: null,
        })

        const input = ref(null)

        flatpickr.l10ns.en.firstDayOfWeek = 1
        flatpickr.l10ns.fr.firstDayOfWeek = 1

        watchEffect(() => {
            if (data.picker) {
                data.picker.set('locale', model.lang)
            }
        })

        onMounted(() => {
                data.picker = flatpickr(input.value, {
                    inline: true,
                    enableTime: true,
                    time_24hr: true,
                    enableSeconds: true,
                    defaultDate: new Date(),
                    maxTime: new Date().getHours() + ":" + new Date().getMinutes() + ":" + new Date().getSeconds(),
                    minuteIncrement: 1,
                    monthSelectorType: 'static',
                    disable: [function (date) {
                        return date > Date.now()
                    }],
                    locale: model.lang,
                    onChange: function (selectedDates, dateStr, instance) {
                        const theDate = new Date(dateStr)
                        if (isToday(theDate)) {
                            const today = new Date();
                            const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds()
                            instance.set('maxTime', time)
                            if (instance.selectedDates[0] > today) {
                                instance.setDate(today)
                            }
                        } else {
                            instance.set('maxTime', '')
                        }
                        data.date = theDate.toString()
                    }
                })
            }
        )

        return () => <input value={data.date} ref={input} style={{display: 'none'}} onChange={props.onChange}/>
    }
}