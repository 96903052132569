import { range } from "../util";
import { IconButton } from "./Button";
import { ICON } from "./icons";
import './Severity.scss'

export const Severity = ({val, max=5, onChange}) => (
    <span class='severity'>
    {onChange && <IconButton style={{visibility:val>1 ? "visible" : "hidden"}} onClick={()=>onChange(val-1)}>{ICON("minus")}</IconButton>}
    {range(max).map(i => i<val ? 
        <span class='circle' />:
        <span class='dotted' /> 
    )}
    {onChange && <IconButton style={{visibility:val<max ? "visible" : "hidden"}}  onClick={()=>onChange(val+1)}>{ICON("plus")}</IconButton>}
    </span>
)
