import { UI } from "../App";
import { filter_null } from "../util";
import {reactive} from "vue";

export const graphql = reactive({
    is404: false
})

const url = "/graphql"
const DEFAULT_WS_URL = `${document.location.protocol==='https:'?'wss':'ws'}://${document.location.host}/cable`

let ws = null;
const subscriptions = {}

let promise_ws_connect = null;
let connected = false;
let connecting = false;
async function ws_connect(url = DEFAULT_WS_URL) {
    if(ws && connected) return ws;
    if(!promise_ws_connect) {
        promise_ws_connect = new Promise((resolve)=>{
            function connect() {
                if(connecting) return;
                ws = new WebSocket(url)
                ws.onopen = ()=>{}
                ws.onclose = ()=>{ 
                    connecting = false
                    if(connected) {
                        promise_ws_connect = null;
                        connected = false;
                        setTimeout(()=>ws_connect(url), 2000)
                    } else {
                        setTimeout(connect, 2000)
                    }
                }
                ws.onmessage = ({data}) => {
                    data = JSON.parse(data)
                    if(data.type==="welcome") {
                        connecting = false
                        connected = true;
                        resolve(ws)
                    }
                    else onWSMessage(data)
                }
            }
            connect()
        })
    }
    return promise_ws_connect
}

function onWSMessage(data) {
    const {identifier} = data
    if(identifier) subscriptions[identifier]?.onmessage(data)
}


const fetch_json = async (url, q, options={}) => fetch(url, {
    method:"post",
    headers: {"Content-type": "application/json"},
    body:JSON.stringify(q),
    ...options
}).then(async x=>{
    const r = await x.json()
    if (!x.ok || r.error) {
        if (r.error && (r.error.code === 'not-found' || r.error.code === 'insufficient-rights')) {
            graphql.is404 = true
            return {}
        } else {
            throw new Error(r.error?.message)
        }
    }
    else return r
})

export async function query(query, variables={}, fields) {
    UI.loading = true
    const q = `query ${query} {${build_gql(query, variables, fields)}}`
    const data = await fetch_json(url, {
        operationName:query,
        query:q,
    })
    UI.loading = false
    if(data.errors) data.errors.forEach(e => console.error(q, e.message, e))
    return Object.values(data.data || {})[0]
}

export async function mutation (query, variables, fields = '{id}') {
    UI.loading = true
    variables = filter_null(variables)

    if(window.dbg_lq) console.log("%c"+query, 'background:purple;color:white;padding:5px;margin-top:15px;')
    if(window.dbg_lq) console.info("params : ", variables)

    const data = await fetch_json(url, {
        operationName:query,
        query:`mutation ${query} {${build_gql(query, variables, fields)}}`
    })
    UI.loading = false
    if(data.error || data.errors) {
        if(window.dbg_lq) data.errors?.forEach(e=>console.error(query, e))
        if(window.dbg_lq) console.error(query, data.error)
        throw data.errors?.[0] || data.error
    }
    else return Object.values(data.data)[0]
}


export const subscribe = async (query, variables={}, cb=()=>{}) => new Promise(async (resolve, reject)=>{
    try {
        const ws = await ws_connect()
        const channelId = Math.round(Date.now() + Math.random() * 100000).toString(16)
        const identifier = JSON.stringify({channel:"GraphqlChannel",channelId})
        const sub = {
            confirmed:false,
            channelId, 
            identifier,
            ws,
            onmessage(data) {
                if(data.type==="confirm_subscription") { 
                    this.confirmed = true; 
                    ws.send(JSON.stringify({
                        command: "message",
                        data: JSON.stringify({
                            operationName:parse_gql(query).name,
                            action:"execute",
                            query:`subscription ${query} {${build_gql(query, variables, '{op}')}}`
                        }),
                        identifier
                    }))
                    resolve(sub)
                }
                else if(data.message) {
                    const res = data.message.result.data
                    if(res) cb(res)
                }
            },
            unsubscribe() {
                try {
                    ws.send(JSON.stringify({ command: "unsubscribe", identifier }))
                } catch(_){}
                delete subscriptions[identifier]
            }
        }
        subscriptions[identifier] = sub
        ws.send(JSON.stringify({ command:"subscribe", identifier }))
    }
    catch(e) {
        reject(e)
    }
})


export function parse_gql(gql) {
    const [type, name, ...rest] = gql.split(/[ \(\)]/)
    return {type,name,rest}
}

export function build_gql(query, variables, fields="") {
    variables = gql_encode_vars(variables)
    if(fields.trim()==='{}') console.error("Empty fields not allowed\n", query, variables, fields)

    return query + variables + fields
}

export function gql_encode_vars(variables) {
    if(variables) variables = Object.entries(variables).filter(([k,v])=>v!==undefined).map(([k,v])=>`${k}:${JSON.stringify(v)}`).join(", ")
    if(variables) variables = "(" + variables + ")"
    else variables = ""
    return variables
}
