import './Badge.scss'
import { IconButton } from './Button'
import { ICON } from './icons'
import { linkify } from '../utils/router'

export const Badge = ({icon,onDelete,href},{slots}) => linkify(
    <span class="badge">
        {icon}
        {slots?.default?.()}
        {onDelete && <IconButton onClick={onDelete}>{ICON("delete")}</IconButton>}
    </span>
    ,href)
