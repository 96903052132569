import { model, API } from '../model'
import { Avatar } from '../ui/Avatar'
import { Form } from '../ui/Form'
import {capitalize, object_filter_fields, validateEmail} from '../util'
import { t } from '../i18n'
import './Profile.scss'
import { reactive } from '@vue/reactivity'
import { LiveQuery } from '../api/livequery'
import { useRoute } from 'vue-router'
import { Spinner } from '../ui/Spinner'
import {Dropdown, DropdownItem} from "../ui/Dropdown";
import {DataTable} from "../ui/DataTable";
import {Link} from "../utils/router";
import {link} from "../utils/routing";


export const EditUser = {
    setup() {
        const id = useRoute()?.params?.id
        const data = reactive({user:null})

        LiveQuery('user', `{id, display_name, kibana_auth, avatar, email, locked, is_admin, is_supervisor, roles{id, role, scope{id}}}`, x=>data.user=x,
            ()=> id && [{id}], null, true
        )

        function save(x) {
            if (x.is_admin !== data.user.is_admin) API.toggleAdmin(x.id)
            if (x.is_supervisor !== data.user.is_supervisor) API.toggleSupervisor(x.id)
            API.setUser(data.user.id, object_filter_fields(x, ['is_admin']))
        }

        function setRole(id, role, scopeId) {
            if (id) {
                if (role === 'none') {
                    API.removeMember({id})
                } else {
                    API.setMember(id, {role})
                }
            } else if (role !== 'none' && scopeId) {
                API.addMember({user_id:data.user.id, scope_id:scopeId, role})
            }
        }

        const allowed_roles = ['manager', 'operator', 'analyst', 'project_manager', 'none']


        return ()=>{
            if(!data.user) return <Spinner/>

            let roles = {}
            data.user?.roles.forEach(r => {
                roles[r.scope?.id] = {id: r.id, role: r.role}
            })

            return <div id="profile">  
            <h1><Avatar class="big" user={data.user}/> {data.user.display_name}</h1>
            <Form object={data.user} fields={{
                display_name:{name:t("Name")},
                email:{validate:validateEmail},
                kibana_auth:{},
                avatar:{},
                is_admin:{name:t("Admin"), type:"switch", disabled:data.user.id===model.user.id},
                is_supervisor:{name:t("Supervisor"), type:"switch", disabled:data.user.id===model.user.id},
                locked:{name:t("Locked"), type:"switch", disabled:data.user.id===model.user.id}
            }} onSave={save}>
            </Form>
                <DataTable
                    noSearch
                    data={model.scopes}
                    columns={[
                        {title:t('Logo'), type:'logo', render:x=><img src={x.logo} alt=''/>},
                        {title:t('Name'), type:'name', render:x=><Link href={link(`/scope/${x.id}`)}>{x.display_name}</Link>},
                        {title:t('Role'), type:"role", render:x=>
                                <Dropdown class='role' button={capitalize(t(roles[x.id]?.role || "None"))}>
                                    {allowed_roles.map(r =>
                                        <DropdownItem onClick={() => setRole(roles[x.id]?.id, r, x.id)}>
                                            {capitalize(t(r))}
                                        </DropdownItem>)}
                                </Dropdown>
                        },
                    ]}
                />
            </div>
        }
    }
}