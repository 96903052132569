import { reactive, onUpdated, onMounted } from 'vue'
import { API, model } from '../model'
import { t } from '../i18n'
import { NewMessagesBoundary } from './NewMessagesBoundary'
import { capitalize, flat_keys } from '../util'
import { Avatar } from './Avatar'
import { Badge } from './Badge'
import {Button, DeleteButton, FileButton, IconButton} from './Button'
import { D } from './dates'
import { Dropdown, DropdownItem } from './Dropdown'
import { Editor } from './Editor'
import { ICON } from './icons'
import { Markdown } from './Markdown'
import './Timeline.scss'
import { TopBanner } from './TopBanner'

export const Timeline = {
    props:["editable", "items", "onSend", "visibilities", "setVisibility", "onSave", "allowed_tags", "buttons", "onDelete", "fetchUsers", "fetchObjects", "onDeleteObject","previousSelector"],
    mounted(){
        if($(".timeline .new-messages-boundary")[0]){
            $(".timeline .new-messages-boundary")[0]?.scrollIntoView({block: "center"})
        }else{
            $(".timeline .message:last-of-type")[0]?.scrollIntoView(true)
        }
    },
    setup(props) {

        let editor;
        let nb=0;
        let scrollToNewMessage = false;
        let isFirstNewMessage = 0;
        

        const data = reactive({
            showArrow: false,
            showBanner: false,
            dirty:false,
            editing:false,
            visibility:props.visibilities?.[0],
            files:[]
        })

        onUpdated(() => {
            handleScroll()
        })

        function send(e) {
            e.preventDefault();
            e.stopPropagation();
            const body = editor?.getData()
            if(!body) return;
            props?.onSend?.(body, data.visibility, data.files)
            editor.setData("")
            data.files = []
            scrollToNewMessage = true
            
        }

        function onNewMessage() {
            if(scrollToNewMessage) $(".timeline .message:last-of-type")[0]?.scrollIntoView(true)
            if($(".timeline .message:last-of-type").children()[0]?.classList[0] === "new-messages-boundary"){
                isFirstNewMessage = 0
            }
        }

        function checkNewMessage() {
            if(props.items?.length > nb) {
                setImmediate(onNewMessage())
            }
            nb = props.items?.length||0
        }

        function onChange() {
            data.dirty = !!editor?.getData()
        }

        function deleteTag(x,t) { API.deleteTag("comment", x.id, t) }

        function handleScroll(){
            let lastMsg = $(".timeline .message:last-of-type")
            let firstUnread = $(".timeline .new-messages-boundary")
            let docViewTop = $(window).scrollTop();
            let docViewBottom = docViewTop + $(window).height() + 100;
            let elemTop = lastMsg.offset().top;
            let elemBottom = elemTop + $(lastMsg).height();
            
            if(firstUnread[0]){
                let unreadTop = firstUnread.offset().top
                data.showBanner = (docViewTop>=unreadTop - 190);
            }

            data.showArrow = !(elemBottom <= docViewBottom) && (elemTop >= docViewTop);
        }
        

        return ()=>{
            const {editable} = props
            window.addEventListener('scroll', handleScroll);
            function buttons(x) {
                if(!props.buttons) return;
                if(typeof(props.buttons)==="function") return props.buttons(x)
                return props.buttons
            }
            const canEdit = (comment) => editable || comment.author.id===model.user.id
            return <>
            {data.showBanner && <TopBanner 
                body="Voir les messages non lus"
                position={$(props.previousSelector).offset().top - window.scrollY + $(props.previousSelector).outerHeight() - $(".timeline").offset().top}
                
                onClick={() => $(".timeline .new-messages-boundary")[0]?.scrollIntoView({block: "center"})}
                />}
                <div id="timeline" class="timeline"  ref="boundary">
                <IconButton class="goDown" style={{"display": data.showArrow ? "" : "none"}} 
                            onClick={() => {
                                data.showArrow = false;
                                $(".timeline .message:last-of-type")[0]?.scrollIntoView(true);
                                }}>{ICON("down")}</IconButton>
                {props.items?.map(x=>{
                    if(isFirstNewMessage === 0 && x.author.id !== model.user.id && !x.seen){
                        isFirstNewMessage = x.id
                        console.log("in")
                    }
                    return <>
                        <Avatar user={x.author}/>
                        <div class="message" class={[x.visibility && "visibility-"+x.visibility]}>
                        {isFirstNewMessage === x.id && <NewMessagesBoundary/>}
                        
                            <div class='content'>
                            <span class="author">{x.author.display_name}</span>
                            <i class="sep"/>
                            <span class="date">{D(x.created_at)}</span>
                            {+new Date(x.created_at) !== +new Date(x.updated_at) && <span class="date">({t('edited')} {D(x.updated_at, "elapsed")})</span>}

                            <div class='controls'>
                                {props.visibilities && canEdit(x) &&
                                <Dropdown tooltip="Visibility" button={<span class='visibility'>{ICON("eye")} {t(x.visibility)}</span>}>
                                    {props.visibilities.map(v=><DropdownItem onClick={()=>props.setVisibility?.(x, v)}>{v===x.visibility && ICON("ok")} {t(v)}</DropdownItem>)}
                                </Dropdown>}
                                {canEdit(x) && props.onSave && <IconButton onClick={()=>data.editing=x}>{ICON('edit')}</IconButton>}
                                {canEdit(x) && props.onDelete && <IconButton onClick={()=>props.onDelete(x)}>{ICON('trash')}</IconButton>}
                                {buttons(x)}
                            </div>


                            <br/>
                            <Markdown 
                                editing={data.editing===x}
                                source={x.body}
                                onSave={body=>{props.onSave?.(x, body); data.editing=false}}
                                onCancel={()=>data.editing=false}
                                fetchUsers={props.fetchUsers}
                                fetchObjects={props.fetchObjects}
                            />
                            </div>
                            {x.files?.map(f=><a class='download-file' href={`/files/${f.url}`} download>{ICON('attach')} {f.filename}</a>)}
                            {x.objects?.map(o=><div class="attached">
                                {o.icon || ICON(o.objType)} <span class="date">{D(o.created_at, 'short')}</span> {o.title}
                                {editable && props.onDeleteObject && <DeleteButton onClick={()=>props.onDeleteObject(x,o)}/>}
                            </div>)}
                        </div>
                    </>
                })}

                <form onSubmit={send} id="post-message" ref={checkNewMessage}>
                    <Editor onEditorInit={e=>editor=e}
                        class="toolbar-hover"
                        placeholder="Ajouter un message..." 
                        onInput={onChange}
                        fetchUsers={props.fetchUsers}
                        fetchObjects={props.fetchObjects}
                    />
                    <IconButton class="send" disabled={!data.dirty} onClick={send}>{ICON("send")}</IconButton>

                    <FileButton onChange={(e)=> data.files = Array.from(e.target.files)} class='attach'/>

                    {props.visibilities &&
                    <Dropdown tooltip="Visibility" button={<span class='visibility'>{ICON("eye")} {data.visibility !== props.visibilities[0] && t(data.visibility)}</span>}>
                        {props.visibilities.map(v=><DropdownItem onClick={()=>data.visibility = v}>{v===data.visibility && ICON("ok")} {t(v)}</DropdownItem>)}
                    </Dropdown>}
                    <div class='files-to-upload'>
                        {data.files.map((file) => <p>{file.name}</p>)}
                    </div>
                </form>
            </div>
            </>
        }
    },

}
