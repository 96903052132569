import { reactive } from 'vue'
import { range } from '../util'
import { Accordion, AccordionItem } from './Accordion'
import './DBG.scss'
import { ICON } from './icons'

export const DBG_INFOS = reactive({})

export const DBG = {
    setup() {
        setInterval(()=>{
            for (var k in DBG_INFOS.livequeries) DBG_INFOS.livequeries[k].ticks = 0
        }, 2000);

        return ()=>{
            const LQ = Object.values(DBG_INFOS.livequeries||{})
            return <div class="dbg toast show">
            <Accordion>
                <AccordionItem title={<>Livequeries ({LQ.length})</>}>
                    <ul>{LQ.map(({q,vars,sub,ticks=0, listeners})=>(
                        <li>{q} {vars ? `(${JSON.stringify(vars)})` : '()'} 
                            &nbsp; {sub ? ICON("ok") : ICON("alert")}
                            {sub?.confirmed && "confirmed"}
                            ({listeners.length} listeners)
                            {ticks>0 && range(ticks).map(i=><>{ICON("star")}</>)}
                        </li>
                    ))}</ul>
                </AccordionItem>
            </Accordion>
            </div>
        }
    }
}
