import { reactive, toRaw } from "@vue/reactivity"
import { watchEffect } from "@vue/runtime-core"
import { t, t_fem } from "../i18n"
import { model, API, hasRole } from "../model"
import { Accordion, AccordionItem, Section } from "../ui/Accordion"
import { Step } from "../ui/Chain"
import { DataTable } from "../ui/DataTable"
import { Description } from "../ui/Description"
import { Dropdown, DropdownItem } from "../ui/Dropdown"
import { Box } from "../ui/Grid"
import { H1 } from "../ui/H"
import { ICON } from "../ui/icons"
import { InProgressIcon } from "../ui/InProgressIcon"
import { Related } from "../ui/Related"
import { Severity } from "../ui/Severity"
import { Sidebar } from "../ui/Sidebar"
import { Spinner } from "../ui/Spinner"
import { createTagDialog, Tags } from "../ui/Tags"
import {capitalize, debounce, flat_keys, has_flat_keys} from "../util"
import { download } from "../utils/download"
import { JSONView } from "../ui/JSONView"
import { TableView } from "../ui/TableView"
import { Drilldown } from "../ui/Drilldown"
import "./Alert.scss"
import {Button, IconButton} from "../ui/Button"
import { goTo, gotoScope } from "../utils/routing"
import { Date } from "../ui/Date"
import {Tab, Tabs} from "../ui/Tabs"



const allowed_statuses = ["new", "muted", "false-positive", "investigating", "resolved"]


export const Alert = {
    setup() {
        const data = reactive({
            cases:[],
            siblings:{},
            vars:{
                cases:{},
                siblings:{page:1, order:"desc", sort:"updated_at"},
            }
        })

        function setTitle(t) { API.setAlertTitle(model.alertId, t) }
        function setDescription(t) { API.setAlertDescription(model.alertId, t) }
        function setStatus(s) { API.setAlertStatus(model.alertId, s) }

        function addTag(t, color) { API.addTag("alert", model.alertId, t, color) }
        function removeTag(t) { API.deleteTag("alert", model.alertId, t) }
        function createTag(tag, color) { createTagDialog(tag, color).then(({tag, color})=>addTag(tag, color)) }

        function setRawAlert(x) { API.setRawAlert(model.alertId, JSON.stringify(x)) }
        function setSeverity(x) { API.setAlertSeverity(model.alertId, x)}
        function setFirstDetection(timestamp) { API.setAlert(model.alertId, {timestamp})}
        function setLastDetection(last_detection) { API.setAlert(model.alertId, {last_detection})}

        function investigateAlert(r) { API.investigateAlert(model.alertId, r.id) }
        function uninvestigateAlert(r) { API.uninvestigateAlert(model.alertId) }
        function newCase() { gotoScope(`newcase?attach_alerts=${model.alertId}`); }

        const queryCases = async filter => API.getCases({scope_id:model.scopeId, search:filter, order:"desc", sort:"updated_at"})

        watchEffect(async ()=>data.cases = model.alert?.id && await API.getAlertCases(model.scopeId, model.alert?.id, data.vars.cases))
        watchEffect(async ()=>data.siblings = model.alert?.id && await API.getAlertSiblings(model.scopeId, model.alertId, data.vars.siblings))

        return ()=>{
            if(!model.alert) return <Spinner/>
            const {status, title, message, tags, body, severity, original, first_detection, last_detection} = model.alert
            const rule = original?.rule
            const event = original?.event?.original
            
            const allowed_tags = model.scope?.tags

            const nb_playbook_suggestions = 4||0

            const RW = hasRole("analyst")

            return <div id="alert" class="page with-sidebar">
            <div>
                <h1>
                    <div class="flex">
                        <div class="title-wrapper">
                            {ICON("alert")}
                            <H1 class="title" onSave={RW && setTitle} value={title}/>
                        </div>
                    </div>
                    <div class="actions">
                        {RW ? 
                        <Dropdown button={<AlertStatus status={status}/>}>
                            {allowed_statuses.map(s=><DropdownItem onClick={()=>setStatus(s)}>
                                {capitalize(t_fem(s))}
                            </DropdownItem>)}
                        </Dropdown>
                        : <AlertStatus status={status}/>}
                    </div>
                </h1>

                <Tags tags={tags} 
                    editable={RW}
                    onAdd={addTag} 
                    onCreate={createTag} 
                    allowed_tags={allowed_tags} 
                    onDelete={removeTag}
                />

                <Description 
                    title="Description"
                    editable={RW}
                    source={body}
                    onSave={setDescription}
                />

                <br/>
                <AlertFields/>

                {RW && <>
                    <br/>
                    
                    {event && <>
                        <h4>{t("Triggering event")}</h4>
                        <Tabs buttons={[<>
                            <IconButton tooltip={t("Download as CSV")}
                                        onClick={() => download(event, "csv", `alert_${model.alertId}_event`)}>
                                {ICON('csv')}
                            </IconButton>

                            <IconButton tooltip={t("Download as JSON")}
                                        onClick={() => download(event, "json", `alert_${model.alertId}_event`)}>
                                {ICON('download')}
                            </IconButton>
                        </>
                        ]}>
                            <Tab label='Table'><TableView source={event}/></Tab>
                            <Tab label='JSON'><JSONView source={event}/></Tab>
                        </Tabs>
                    </>}
                    
                    {original && <>
                        <h4>{t("Raw alert")}</h4>
                        <Tabs buttons={[<>
                            <IconButton tooltip={t("Download as CSV")}
                                        onClick={() => download(original, "csv", `alert_${model.alertId}`)}>
                                {ICON('csv')}
                            </IconButton>


                            <IconButton tooltip={t("Download as JSON")}
                                        onClick={() => download(original, "json", `alert_${model.alertId}`)}>
                                {ICON('download')}
                            </IconButton>
                            
                            </>]}>
                            <Tab label='Table'><TableView source={original}/></Tab>
                            <Tab label='JSON'><JSONView source={original}/></Tab>
                        </Tabs>
                    </>}
                </>}
            </div>

            <Sidebar>

                <Accordion>

                    <div class="dates">
                        <div>{t("First detection")}</div><div><Date onSave={RW && setFirstDetection} value={first_detection}/></div>
                        <div>{t("Last detection")}</div><div><Date onSave={RW && setLastDetection} value={last_detection}/></div>
                    </div>

                    <div id="severity">
                        <h3>{t("Severity")}</h3>
                        <Severity val={severity} onChange={RW && setSeverity}/>
                        <h1>{severity}</h1>
                    </div>

                    {!!rule && <AccordionItem title={t("Rule")}>
                        <DataTable class="fields" noHeader
                            data={Object.entries(rule).map(([k,v])=>({name:k,value:v}))}
                            columns={[
                                {render:x=><b>{t(x.name)}</b>},
                                {render:x=>
                                    Array.isArray(x.value) ? <ul>{x.value.map(x=><li>{x}</li>)}</ul>
                                    : x.value
                                },
                            ]}
                        />
                    </AccordionItem>}

                    <Related type='case' title="Cases" items={data.cases}
                        class="no-dates"
                        editable={RW}
                        query={queryCases}
                        onAdd={investigateAlert}
                        onDelete={uninvestigateAlert}
                        addTooltip="Attach to case"
                        update={debounce(x=>data.vars.cases.search = x.search)}
                        footer={RW && <Button secondary onClick={newCase}>{ICON('add')} {t("New case")}</Button>}
                    />

                    <Related type='alert' title={t("Aggregated alerts")} items={data.siblings}
                        editable={RW}
                        update={debounce(x=>data.vars.siblings.search = x.search)}
                        page={data.vars.siblings.page}
                        setPage={p=>data.vars.siblings.page=p}
                    />

                    {/* <Section title="Suggestions du playbook" badges={<Badge>{nb_playbook_suggestions}</Badge>}>
                        <DataTable noHeader data={["Faire ci ça ça","Autre truc","Faire machin","T'as regardé truc ?"]} />
                    </Section> */}

                </Accordion>

            </Sidebar>
        </div>
        }
    }
}

export const AlertStatus = ({status}) =>
    status==="new" ?  <Step icon={ICON("star")} class="final">{t_fem(status)}</Step>
    : status==='investigating' ? <Step icon={<InProgressIcon/>} class="final">{t_fem(status)}</Step>
    : status==="resolved" ? <Step icon={ICON("done")} done class="final">{t_fem(status)}</Step>                            
    : <Step icon={ICON("canceled")} class="final">{t_fem(status)}</Step>



// Fields

function process_fields(fields) {
    if(typeof(fields)==="string" && fields.trim()) fields = JSON.parse(fields)
    if(!fields) return [];

    delete fields["rule"]
    delete fields["fields"]
    delete fields["event"]
    delete fields["alert"]
    delete fields["tags"]

    // Handle flat keys format
    if(has_flat_keys(fields)) {
        var out = {}
        for(var k in fields) {
            var [key,rest] = k.split(".", 2)
            if(!out[key])  out[key] = {}
            out[key][rest] = fields[k]
        }
        return Object.entries(out)
    }
    // Handle nested keys format
    else return Object.entries(fields).filter(([k,v])=>typeof(v)!=="string").map(([k,v])=>[k,flat_keys(v)])
}

export const AlertFields = () => {
    let fields = process_fields(JSON.parse(JSON.stringify(model?.alert?.original)))
    return <div class="alert-fields">
    {fields?.map(([k,v])=>
        <Box>
            <h2>{capitalize(k)}</h2>
            <DataTable noHeader
                data={Object.entries(v)}
                columns={[
                    {render:([kk])=><b>{t(kk)}</b>},
                    {render:([kk,v])=>
                        Array.isArray(v) ? <ul>{v.map(e=><li><Drilldown k={k+"."+kk} v={e}/></li>)}</ul>
                        : <Drilldown k={k+"."+kk} v={v}/>
                    },
                ]}
            />
        </Box>
    )}
    </div>
}