const plural = (text)=>({

    "Case open": "Cases open",
    "Case closed": "Cases closed",
    "Recommendation pending": <>Recommendations<br/>&nbsp;pending</>,
    "Recommendation validated": <>Recommendations<br/>&nbsp;validated</>,
    "validated out of": "validated out of",
    "New": "New",
    "Investigating": "Investigating",

})[text]||(t(text)+"s")

const t = (text, opts)=> opts?.nb>1 ? plural(text) : {
    "Recommendation pending": <>Recommendation<br/>&nbsp;pending</>,
    "Recommendation validated": <>Recommendation<br/>&nbsp;validated</>,
    "alert:New": "New",
    "alert:Done": "Done",
    "alert:Ignored": "Ignored",
    "resolved": "done"
}[text]||text

const t_fem = (text, opts)=> t(text,opts)

const t_n = (nb, text)=><>{nb || "No"} {t(text,{nb})}</>
const t_n_fem = (nb, text)=>t_n(nb,text)


export default {t_fem,t_n,t,t_n_fem}