import { createApp } from 'vue';
import { App, setRoutes } from './App';
import './css/main.scss'
import './css/fonts.scss'
import { Alerts } from './pages/Alerts';
import { Cases } from './pages/Cases';
import { Incidents } from './pages/Incidents';
import { Incident } from './pages/Incident';
import { Members } from './pages/Members';
import { Recommendations } from './pages/Recommendations';
import { Scenarios } from './pages/Scenarios';
import { Scopes } from './pages/Scopes';
import { ScopeSettings } from "./pages/ScopeSettings";
import { Documents } from './pages/Documents';
import { Users } from './pages/Users';
import { Profile } from './pages/Profile';
import { Dashboard } from './pages/Dashboard';
import { Alert } from './pages/Alert';
import { Recommendation } from './pages/Recommendation';
import { Scenario } from './pages/Scenario';
import { Case } from './pages/Case';
import { Login } from './pages/login/Login';
import { Signup } from './pages/login/Signup';
import { ResetPassword } from './pages/login/ResetPassword';
import { createRouter } from './utils/router';
import { MonthlyReport } from './pages/MonthlyReport';
import { NewCase } from './pages/new/NewCase';
import { NewIncident } from './pages/new/NewIncident';
import { NewScope } from './pages/new/NewScope';
import { NewAlert } from './pages/new/NewAlert';
import { NewRecommendation } from './pages/new/NewRecommendation';
import { NewScenario } from './pages/new/NewScenario';
import { NewUser } from './pages/new/NewUser';
import { EditUser } from './pages/EditUser';
import { EditDrilldown } from "./pages/EditDrilldown";
import { NoScope } from './pages/NoScope';
import { Config } from "./pages/Config";
import {Assets} from "./pages/Assets";
import {Vulnerabilities} from "./pages/Vulnerabilities";
import {NewVulnerability} from "./pages/new/NewVulnerability";
import {Vulnerability} from "./pages/Vulnerability";

window.CONFIG = CONFIG
console.log("ASTRAIA VERSION : " + CONFIG.VERSION)

export const ROUTES = [    
    { path: "/:lang/scope/:scopeId/dashboard", name:"dashboard", component:Dashboard},
    { path: "/:lang/scope/:scopeId/reports/:year?/:month?", name:"reports", component:MonthlyReport},
    { path: "/:lang/scope/:scopeId/alerts", name:"alerts", component:Alerts },
    // { path: "/:lang/scope/:scopeId/incidents", name:"incidents", component:Incidents },
    { path: "/:lang/scope/:scopeId/cases", name:"cases", component:Cases },
    { path: "/:lang/scope/:scopeId/recommendations", name:"recommendations", component:Recommendations },
    { path: "/:lang/scope/:scopeId/scenarios", name:"scenarios", component:Scenarios },
    { path: "/:lang/scope/:scopeId/vulnerabilities", name:"vulnerabilities", component:Vulnerabilities },
    { path: "/:lang/scope/:scopeId/members", name:"members", component:Members },
    { path: "/:lang/scope/:scopeId/documents", name:"documents", component:Documents },
    { path: "/:lang/scope/:scopeId/settings", name:"settings", component:ScopeSettings, role:"analyst" },
    { path: "/:lang/scope/:scopeId/assets", name:"assets", component:Assets },
    { path: "/:lang/scope/:scopeId/", redirect:{name:"dashboard"} },
    { path: "/:lang/scope/:scopeId/:path(.*)", redirect:{name:"dashboard"}},
    { path: "/:lang/vulnerability/:vulnerabilityId/", component:Vulnerability },
    { path: "/:lang/scope/:scopeId/vulnerability/:vulnerabilityId/", component:Vulnerability },

    { path: "/:lang/scope/:scopeId/alert/:alertId", name:"alert", component:Alert},
    { path: "/:lang/scope/:scopeId/recommendation/:recommendationId", name:"recommendation", component:Recommendation},
    { path: "/:lang/scope/:scopeId/scenario/:scenarioId", name:"scenario", component:Scenario},
    { path: "/:lang/scope/:scopeId/case/:caseId", name:"case", component:Case},
    // { path: "/scope/:scopeId/incident/:incidentId", name:"incident", component:Incident},

    { path: "/:lang/scope/:scopeId/newcase", component:NewCase},
    { path: "/:lang/scope/:scopeId/newincident", component:NewIncident},
    { path: "/:lang/scope/:scopeId/newalert", component:NewAlert},
    { path: "/:lang/scope/:scopeId/newrecommendation", component:NewRecommendation},
    { path: "/:lang/scope/:scopeId/newscenario", component:NewScenario},
    { path: "/:lang/newvulnerability", component:NewVulnerability},
    { path: "/:lang/newscope", component:NewScope, admin:true},
    { path: "/:lang/newuser", component:NewUser, admin:true},
    { path: "/:lang/user/:id", component:EditUser, admin:true},


    { path: "/:lang/scopes", name:"/scopes", component:Scopes, multiscope:true },
    { path: "/:lang/alerts", component:Alerts, multiscope:true},
    { path: "/:lang/incidents", component:Incidents, multiscope:true },
    { path: "/:lang/cases", component:Cases, multiscope:true },
    { path: "/:lang/recommendations", component:Recommendations, multiscope:true },
    { path: "/:lang/scenarios", component:Scenarios, multiscope:true },
    { path: "/:lang/vulnerabilities", component:Vulnerabilities, multiscope:true },
    { path: "/:lang/users", component:Users, admin:true },
    { path: "/:lang/profile", component:Profile },

    { path: "/:lang/config", component:Config, role:'supervisor' },

    { path: "/:lang/noscope", name:"noscope", component:NoScope, menu:false },

    { name:"login", path:"/:lang/login", component:Login, anonymous:true},
    { name:"reset-password", path:"/:lang/reset-password/:token", component:ResetPassword, anonymous:true},
    { name:"signup", path: "/:lang/activate/:token", component:Signup, anonymous:true},

    { path: "/", redirect: ()=>({name:"/scopes",params:{lang:"fr"}})},
    { path: "/:path(.*)", redirect: ()=>({name:"/scopes",params:{lang:"fr"}})},
]

setRoutes(ROUTES)

export const router = createRouter(ROUTES, "/")

const app = createApp(App)
app.use(router)
app.mount('#app');