import { createWebHistory, createRouter as cr } from "vue-router";

let router;

export const getRouter = () => router

export function createRouter(routes, baseUrl="/") {
    return router = cr({
        history:createWebHistory(baseUrl),
        routes,
        base:baseUrl,
    })
}


export const Link = ({href, selectable=false},{slots}) =>  
    selectable ? <SelectableLink href={href}>{slots?.default?.()}</SelectableLink>
    : href?.includes?.("://") ? <a href={href} target="_blank">{slots?.default?.()}</a>
    : href ? <router-link to={href}>{slots?.default?.()}</router-link> 
    : <a onClick={e=>{e.preventDefault();return false;}}>{slots?.default?.()}</a>

export const linkify = (node, href) =>
    href ? <Link href={href}>{node}</Link> : node


export const getCurrentRouteName = () => getRouter().currentRoute?.value?.name


const SelectableLink = ({href}, {slots}) => {
    let dragged = false;
    let down = false
    let mousedown = null;
    return <Link draggable="false" href={href}>{slots?.default?.()}</Link>
}
