import "./TopBanner.scss"

// export const TopBanner = {
//     setup(props, {slots}){
//         return <div>
//             <p>{slots?.default?.()}</p>
//         </div>
//     }    
// }

export const TopBanner = ({body, onClick, position = 0}) => (
        <div onClick={e=>{e.preventDefault();onClick?.()}} class="top-banner">
            <div style={{"margin-top": position + "px"}}>{body}</div>
        </div>
)