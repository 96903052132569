import { model, API, getMembers, hasRole } from "../model"
import { Accordion } from "../ui/Accordion"
import { Avatar } from "../ui/Avatar"
import { Timeline } from '../ui/Timeline'
import { ICON } from "../ui/icons"
import { Chain } from '../ui/Chain'
import './Case.scss'
import { Spinner } from "../ui/Spinner"
import { Button, IconButton } from "../ui/Button"
import { H1 } from "../ui/H"
import { Dropdown, DropdownItem } from "../ui/Dropdown"
import { t } from "../i18n"
import {capitalize, debounce, filter_null} from "../util"
import { createTagDialog, Tags } from "../ui/Tags"
import { Description } from "../ui/Description"
import { Sidebar } from '../ui/Sidebar'
import { Related } from "../ui/Related"
import { reactive } from "@vue/reactivity"
import { watchEffect } from "@vue/runtime-core"
import { gotoScope } from "../utils/routing"
import { Date } from "../ui/Date"
import { Switch } from "../ui/Switch"
import { onMounted } from "vue"

const VISIBILITIES = ["public", "analysts", "private"]

export const ALLOWED_STEPS = {
    detection: [
        "qualification", null,
        "analyse", null,
        'false positive', 'escalade', 'ignore'
    ],
    audit: [
        "planification", null,
        "doing", null,
        "returned", "cancel",
    ],
    patrol: [
        "investigation", null,
        "analyse", null,
        'nothing to declare', 'false positive', 'escalade'
    ], 
    incident: [
        "containment", null,
        "mitigation", null,
        "recovery", null,
        "closed"
    ]
}

export const CASES_TYPES = [
    "detection", 
    "audit",
    "patrol",
    "incident"
]


export const Case = {
    setup() {
        const data = reactive({
            bannerPos: 0,
            alerts:[],
            related_cases:[],
            related_recommendations:[],
            related_scenarios:[],
            vars: {
                alerts:{},
                related_cases:{},
                related_recommendations:{},
                related_scenarios:{},
                participants:{},
            }
        })

        function setStep(s) { API.setCaseStep(model.caseId, s) }
        function resetSteps() { API.resetCaseSteps(model.caseId) }
        function setDescription(d) { API.setCaseDescription(model.caseId, d) }
        function setConclusion(d) { API.setCaseConclusion(model.caseId, d) }
        function setCreatedAt(created_at) { API.setCase(model.caseId, {created_at})}                    
        function setUpdatedAt(updated_at) { API.setCase(model.caseId, {updated_at})}  
        function setPublic(is_public) { API.setCase(model.caseId, {is_public})}

        function sendMessage(body, visibility, files) {
            API.addComment("case", model.caseId, files, {body, visibility})
            Array.from(body.matchAll(/#\\\[(.+?):(.+?)\\]\(.+?\)/gm)).forEach((x) => {
                const [_,type,id] = x
                type==="alert" ? API.investigateAlert(id, model.caseId) :
                API.addRelated("Case", model.caseId, capitalize(type), id)
            })
            Array.from(body.matchAll(/(^|\s)(@\S+)/gm)).forEach((x) => {
                const participants_ids = Array.from(model.case.participants, (x)=>x.id)
                const participant = queryMembers(x[0].slice(1).replace("\\_", " "))[0]
                if (!participants_ids.includes(participant.id)) addParticipant(participant)
            })
        }
        function setVisibility(comment, visibility) { API.setComment(comment.id, {visibility}) }
        function saveMessage(comment, body) { API.setComment(comment.id, {body}) }
        function deleteMessage(comment) { API.removeComment(comment.id) }

        function setTitle(title) { API.setCaseTitle(model.caseId, title) }
        function setType(type) { API.setCaseType(model.caseId, type) }

        function investigateAlert(x) { API.investigateAlert(x.id, model.caseId) }
        function uninvestigateAlert(x) { API.uninvestigateAlert(x.id, model.caseId) }
        function newAlert() { gotoScope(`newalert?cases=${model.caseId}`); }
        function addRelatedCase(r) { API.addRelatedCase("case", model.caseId, r.id) }
        function removeRelatedCase(r) { API.removeRelatedCase('case', model.caseId, r.id) }
        function newCase() { gotoScope(`newcase?related_cases=${model.caseId}`); }
        function newIncident() { gotoScope(`newincident?related_cases=${model.caseId}`); }
        function addRelatedRecommendation(r) { API.addRelatedRecommendation("case", model.caseId, r.id) }
        function removeRelatedRecommendation(r) { API.removeRelatedRecommendation('case', model.caseId, r.id) }
        function newRecommendation() { gotoScope(`newrecommendation?related_cases=${model.caseId}`); }
        function addRelatedScenario(r) { API.addRelatedScenario("case", model.caseId, r.id) }
        function removeRelatedScenario(r) { API.removeRelatedScenario("case", model.caseId, r.id) }
        function newScenario() { gotoScope(`newscenario?related_cases=${model.caseId}`); }

        const queryAlerts = filter => API.getAlerts({scope_id:model.scopeId, search:filter, order:"desc", sort:"updated_at"})
        const queryMembersWithoutParticipants = search => getMembers({search}).filter(p=>!model.case?.participants?.find(({id})=>p.id===id))
        const queryMembers = search => getMembers({search})
        const queryRecommendations = filter => API.getRecommendations({scope_id:model.scopeId, search:filter, order:"desc", sort:"updated_at"})
        const queryScenarios = filter => API.getScenarios({scope_id:model.scopeId, search:filter, order:"desc", sort:"updated_at"})
        const queryIncidents = filter => API.getIncidents({scope_id:model.scopeId, search:filter, order:"desc", sort:"updated_at"})
        const queryCases = filter => API.getCases({scope_id:model.scopeId, search:filter, order:"desc", sort:"updated_at"})

        function addParticipant(p) { API.addCaseParticipant(model.caseId, p.id) }
        function removeParticipant(p) { API.removeCaseParticipant(model.caseId, p.id) }

        function addTag(t, color) { API.addTag("case", model.caseId, t, color) }
        function deleteTag(t) { API.deleteTag("case", model.caseId, t) }

        function createTag(tag, color) { createTagDialog(tag, color).then(({tag, color})=>addTag(tag, color)) }

        function deleteCommentObject(comment, o) { API.deleteCommentObject(comment.id, o.objType, o.objId) }

        const fetchMembers = async (x) => (await queryMembers(x)).map(m=>"@"+m.user.display_name.replace(/\s/g, "_"))

        const fetchObjects = async (x) => 
            (await API.getObjects({search:x, scope_id:model.scopeId, type:["recommendation", "scenario", "alert"]}))
            .map(({id,type,title})=>'#['+type+":"+id+"]("+title+")")
        
        watchEffect(async ()=>data.alerts = model.case?.id && await API.getCaseAlerts(model.scopeId, model.case.id, data.vars.alerts))
        watchEffect(async ()=>data.related_cases = model.case?.id && await API.getRelatedCases("case", model.scopeId, model.case.id, data.vars.related_cases))
        watchEffect(async ()=>data.related_recommendations = model.case?.id && await API.getRelatedRecommendations("case", model.scopeId, model.case.id, data.vars.related_recommendations))
        watchEffect(async ()=>data.related_scenarios = model.case?.id && await API.getRelatedScenarios("case", model.scopeId, model.case.id, data.vars.related_scenarios))

        return ()=>{
            if(!model.case) return <Spinner/>

            const {steps, status, body, comments,  
                type, conclusion, participants, 
                tags, closed, created_at, updated_at, is_public
            } = model.case
            const allowed_tags = model.scope?.tags

            const RW = hasRole("analyst")
            
            return <div id="case" class="page with-sidebar">
            <div>
                <div class="sticky-header">
                <h1>
                    <div class="flex">
                        <div class="title-wrapper">
                            {RW  ? 
                                <Dropdown slim button={<CaseType type={type}/>}>
                                    {CASES_TYPES.map(type=><DropdownItem onClick={()=>setType(type)}><span class="type"><span class={[type]}/>{capitalize(t(type))}</span></DropdownItem>)}
                                </Dropdown>
                            : <CaseType type={type}/>}
                            <H1 class="title" onSave={RW && setTitle} value={model.case.title}/>
                            {/* {closed && '(fermée)'} */}
                        </div>
                    </div>
                    <div class="actions">

                    <Chain steps={steps} status={status} type={type}
                        allowed_steps={ALLOWED_STEPS[type]} 
                        onChange={(x) => {if (RW) {
                            const values = Object.values(filter_null(steps))
                            values.length === 1 && values[0] === x ? resetSteps() : setStep(x)
                        }}}
                />
                    </div>
                </h1>

                <div class="row">
                    {RW && <Switch name="Public" value={is_public} onClick={()=>setPublic(!is_public)}>{t("Public")}</Switch>}

                    <Tags tags={tags} 
                        editable={RW}
                        onAdd={addTag} 
                        onDelete={deleteTag} 
                        onCreate={createTag} 
                        allowed_tags={allowed_tags}
                    />
                </div>

                <Description 
                    title="Description"
                    editable={RW}
                    source={body}
                    onSave={setDescription}
                />

                <Description 
                    title="Conclusions"
                    class="conclusion"
                    editable={RW}
                    source={conclusion}
                    onSave={setConclusion}
                />
                <br/>
                </div>
                <div>
                    <Timeline 
                        items={comments} 
                        onSend={sendMessage}
                        onSave={saveMessage}
                        onDelete={deleteMessage} 

                        visibilities={RW && VISIBILITIES}
                        setVisibility={RW && setVisibility}
                        buttons={item=>RW && <CommentActions comment={item} allowed_tags={allowed_tags}/>}

                        fetchUsers={fetchMembers}
                        fetchObjects={fetchObjects}

                        onDeleteObject={RW && deleteCommentObject}

                        previousSelector={".sticky-header"}
                    />
                </div>
            </div>

            <Sidebar>

                <Accordion>

                    <div class="dates">
                        <div>{t("Created")}</div><div><Date onSave={RW && setCreatedAt} value={created_at}/></div>
                        <div>{t("Last activity")}</div><div><Date onSave={RW && setUpdatedAt} value={updated_at}/></div>
                    </div>

                    {(steps.includes('escalade') || type === 'incident') &&
                    <Related type='case' title={type === 'incident' ? "Cases" : "Incidents"} items={data.related_cases}
                             editable={RW}
                             query={type === 'incident' ? queryCases : queryIncidents}
                             onAdd={addRelatedCase}
                             onDelete={removeRelatedCase}
                             addTooltip={type === 'incident' ? "Add related case" : "Add related incident"}
                             update={debounce(x=>data.vars.related_cases.search = x.search)}
                             footer={<Button secondary onClick={type === 'incident' ? newCase : newIncident}>{ICON('add')} {t(type === 'incident'? "New case" : "New incident")}</Button>}
                    />}

                    <Related type='alert' title="Alerts" items={data.alerts}
                        editable={RW}
                        query={queryAlerts}
                        onAdd={investigateAlert}
                        onDelete={uninvestigateAlert}
                        addTooltip="Add alert"
                        update={debounce(x=>data.vars.alerts.search = x.search)}
                        footer={<Button secondary onClick={newAlert}>{ICON('add')} {t("New alert")}</Button>}
                    />

                    <Related type='recommendation' title="Recommendations" items={data.related_recommendations}
                        editable={RW}
                        query={queryRecommendations}
                        onAdd={addRelatedRecommendation}
                        onDelete={removeRelatedRecommendation}
                        addTooltip="Add related recommendation"
                        update={debounce(x=>data.vars.related_recommendations.search = x.search)}
                        footer={<Button secondary onClick={newRecommendation}>{ICON('add')} {t("New recommendation")}</Button>}
                    />

                    <Related type='scenario' title="Scenarios" items={data.related_scenarios}
                        class="no-dates"
                        editable={RW}
                        query={queryScenarios}
                        onAdd={addRelatedScenario}
                        onDelete={removeRelatedScenario}
                        addTooltip="Add related scenario"
                        update={debounce(x=>data.vars.related_scenarios.search = x.search)}
                        footer={<Button secondary onClick={newScenario}>{ICON('add')} {t("New scenario")}</Button>}
                    />

                    {/* <Section icon={ICON('risk')} title="Projection du risque">
                        <img style={{width:"350px"}} src={require("./manager/risk.png")}/>
                    </Section> */}

                    <Related type='users' title="Participants" items={participants}
                        editable={RW}
                        query={queryMembersWithoutParticipants}
                        onAdd={addParticipant} addTooltip="Add participant"
                        onDelete={removeParticipant}
                        render={p=><div class="participant"><Avatar class='small' user={p.user}/>{p.user.display_name}<b>{t(p.role)}</b></div>}
                        renderCombo={p=><div class="participant"><Avatar class='small' user={p.user}/>{p.user.display_name}<b>{t(p.role)}</b></div>}
                    />

                    {/*
                    
                    // @jfellus TODO : will come in 1.6

                    <Section title="Suggestions du playbook" badges={<Badge>{nb_playbook_suggestions}</Badge>}>
                        <DataTable noHeader data={["Faire ci ça ça","Autre truc","Faire machin","T'as regardé truc ?"]} />
                    </Section> */}

                </Accordion>

            </Sidebar>
        </div>
        }
    }
}

const CommentActions = {
    props:["comment", "allowed_tags"],
    setup(props) {
        const addTag = (t, c) => API.addTag("comment", props.comment.id, t, c)
        const createTag = (tag, color) => createTagDialog(tag, color).then(({tag, color})=>addTag(tag, color))
        const deleteTag = (t) => API.deleteTag("comment", props.comment.id, t)

        function addToPlaybook() {
            addTag("playbook")
        }

        function createRecommendation() {
            addTag("recommendation")
            gotoScope(`newrecommendation?body=${props.comment.body}`)
        }

        function addToReport() {
            addTag("added to report")
        }

        
        function sendMail() {
            const current_user_email = "me@ct-square.com"
            const subject = "Message from astraia"
            const {body} = props.comment
            addTag("Sent as email")
            location.href = `mailto:?cc=${current_user_email}&subject=${subject}&body=${body}`
        }

        return ()=>{
            const {tags} = props.comment
            return <>
                {/*{!tags?.includes("playbook") && <IconButton onClick={addToPlaybook} tooltip="Add to playbook">{ICON("playbook")}</IconButton>}*/}
                {/*{!tags?.includes("recommendation") && <IconButton onClick={createRecommendation} tooltip="Convert to recommendation">{ICON("recommendation")}</IconButton>}*/}
                {/*{!tags?.includes("report") && <IconButton onClick={addToReport} tooltip="Add to monthly report">{ICON("report")}</IconButton>}*/}
                {/*<IconButton onClick={sendMail} tooltip="Send as email">{ICON("mail")}</IconButton>*/}
                <Tags tags={tags}
                      editable={true}
                      onAdd={addTag}
                      onDelete={deleteTag}
                      onCreate={createTag}
                      allowed_tags={props.allowed_tags}
                />
            </>
        }
    }
}


export const CaseType = ({type}) => <span class="type"><span class={[type]}/>{t(type)}</span>



