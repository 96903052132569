import { ref } from "@vue/reactivity"
import { watchEffect } from "@vue/runtime-core"
import { t } from "../i18n"
import './dates.scss'

export const fr_hm = (d) => (
  d.getHours() + "h" + (d.getMinutes() >= 10 ? d.getMinutes() : "0"+d.getMinutes())
)

export const isThisYear = (d) => (
    new Date().getFullYear() === d.getFullYear()
)

export const isYesterday = (d) => {
  const _24h = new Date(new Date().getTime() - 1000*60*60*24)
  return isSameDay(d, _24h)
}

export const isSameDay = (d1,d2) => {
  return d1.getDate() == d2.getDate() &&
    d1.getMonth() == d2.getMonth() &&
    d1.getFullYear() == d2.getFullYear()
}

export const isToday = (d) => {
  return isSameDay(d, new Date())
}


// NOTE : This function only supports 2 languages : EN, FR
const i18n = (timestamp, long=false, always_time=false, abs=false) => {
  const d = new Date(timestamp)
  if(t('just now')==='just now') {
    return long ? long_en(d, always_time, abs) : short_en(d, always_time, abs)
  } else {
    return long ? long_fr(d, always_time, abs) : short_fr(d, always_time, abs)
  }
}

function long_en(d, always_time) {
  if(always_time || isToday(d) || isYesterday(d)) return d.toLocaleString("en-US", {day:'numeric',month:'short',year:'numeric',minute:'numeric',hour:'numeric'})
  else return d.toLocaleString("en-US", {day:'numeric',month:'short',year:'numeric'})
}

function short_en(d, always_time, abs) {
  if(!abs && isToday(d)) return d.toLocaleString("en-US", {minute:'numeric',hour:'numeric'})
  if(!abs && isYesterday(d)) return 'yesterday at ' + d.toLocaleString("en-US", {minute:'numeric',hour:'numeric'})
  if(isThisYear(d)) return d.toLocaleString("en-US", {day:'numeric',month:'short'})
  return d.toLocaleString("en-US", {day:'numeric',month:'short',year:'numeric'})
}

function long_fr(d, always_time) {
  if(always_time || isToday(d) || isYesterday(d)) return d.toLocaleString("fr-FR", {day:'numeric',month:'short',year:'numeric'}) + " à " + fr_hm(d)
  else return d.toLocaleString("fr-FR", {day:'numeric',month:'short',year:'numeric'})
}

function short_fr(d, always_time, abs) {
  if(!abs && isToday(d)) return fr_hm(d)
  if(!abs && isYesterday(d)) return "hier à " + fr_hm(d)
  if(isThisYear(d)) return d.toLocaleString("fr-FR", {day:'numeric',month:'short'})
  return d.toLocaleString("fr-FR", {day:'numeric',month:'short',year:'numeric'})
}


const elapsedMonths = (timestamp) => (
  new Date().getMonth()-new Date(timestamp).getMonth()
  + (new Date().getFullYear()-new Date(timestamp).getFullYear())*12)


const getTime = (date) => {
  try { 
    if(date instanceof Date) return date
    return new Date(date).getTime() 
  } 
  catch(e) { return null; }
}

export const D = (date, fmt) => {
  const d = getTime(date)
  if(!d) return null;
  if(fmt==="text") return i18n(d, true, true)
  if(fmt==='long') return <span class="date long">
    <span>{i18n(d, true, true)}</span>
    <LiveDate date={d}/>
  </span>

  if(fmt==="normal" || !fmt) return  <span class="date">{i18n(d,true)}</span>
  if(fmt==='short-abs') return <span class="date">{i18n(d, false, false, true)}</span>
  if(fmt==='short') return <span class="date">{i18n(d)}</span>

  return <LiveDate date={d}/>
}

const LiveDate = {
  props:['date'],
  setup(props) {
    const x = ref();
    function update() {

      function up(v, refresh_seconds) {
        setTimeout(update, refresh_seconds*1000)
        x.value = v
      }

      if(!props.date) return;
      const d = new Date(props.date)
      const ts = d.getTime()
      let s = Math.floor((new Date().getTime()-ts)/1000)
      if(s<0) return;
      if(s<60) return up(t("just now"), 1)
      // if(s<60) return up(t_ago(s, "second"), 1)
    
      s = Math.floor(s/60)
      if(s<60) return up(t_ago(s, "minute"), 60)
    
      s = Math.floor(s/60)
      if(s<24) return up(t_ago(s,"hour"), 24*60)
    
      s = Math.floor(s/24)
      if(s<7) return up(isYesterday(d) ? t("yesterday") : t_ago(s,"day"), 24*60)
    
      s = Math.floor(s/7)
      if(s<5) return up(t_ago(s, "week"), 24*60)
    
      s = elapsedMonths(ts)
      if(s<12) return up(t_ago(s, "month"), 24*60)
      s = Math.floor(s/12)
      return up(t_ago(s, "year"), 24*60)
    }
    watchEffect(()=>update(props.date))
    return ()=><span class="live date">{x.value}</span>
  }
}


const t_ago = (n,unit) => {
  if(t('just now')==='just now') { 
    const plural = {}
    if(n>1) unit = plural[unit] || (unit+"s")
    return `${n} ${unit} ago`
  }
  else {
    const plural = {
      "mois":"mois"
    }
    if(n>1) unit = plural[t(unit)] || (t(unit)+"s")
    return `il y a ${n} ${t(unit)}`
  }
}


const parseMonth = (x)=>
    ["janvier","fevrier","mars","avril","mai","juin","juillet","aout","septembre","octobre","novembre","decembre"]
    .findIndex(m=>m.startsWith(x.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase().replace(/[^a-z]/g,"")))+1

export function parseDate(d) {
  if(!d) return null;
  d = d.replaceAll("à", " ")
  d = d.replaceAll("h", "/")
  d = d.replaceAll(":", "/")
  d = d.replace(/[\s]+/g, "/")
  let [day,month,year,hour=0,min=0,sec=0] = d.split("/")
  day = parseInt(day) || 0
  month = (parseInt(month) || parseMonth(month))-1
  year = parseInt(year) || 0
  hour = parseInt(hour) || 0
  min = parseInt(min) || 0
  sec = parseInt(sec) || 0
  return new window.Date(year, month, day, hour,min,sec)
}