import {reactive} from '@vue/reactivity';
import {t} from '../i18n';
import {FileButton, IconButton} from './Button';
import './FilesManager.scss'
import {ICON} from './icons';
import {watchEffect} from "@vue/runtime-core";
import {Upload} from "./Upload";

export const FilesManager = {
    props: ['editable', 'files', 'params'],
    setup(props) {
        const data = reactive({
            uploaded_files:[],
            selected_files:[],
            editing: false,
        })


        const deleteFiles = () => {
            if (confirm("You are about to delete all selected files !\nAre you sure ?")) {
                data.selected_files.forEach(async (f) => {
                    const formData = new FormData();
                    formData.append('file', f);
                    await fetch("/files", {
                        method: 'DELETE',
                        body: formData
                    })
                })
                data.editing = false
                data.selected_files = []
            }
        }

        const selectFile = (file) => {
            if (data.selected_files.includes(file))
                data.selected_files.splice(data.selected_files.indexOf(file), 1);
            else
                data.selected_files.push(file)
        }

        watchEffect(() => {
            if (data.editing) {
                if (data._upload_files_input) data._upload_files_input.value = null
                data.uploaded_files = []
            } else {
                data.selected_files = []
            }
        })

        return () => <div class='documents-manager'>
            <h2>
                {ICON("document")} Documents
                {props.editable && <div>
                    {data.editing && <IconButton onClick={deleteFiles}>{ICON("trash")}</IconButton>}
                    <IconButton onClick={() => data.editing = !data.editing}>{ICON(data.editing ? 'close' : 'edit')}</IconButton>
                </div>}
            </h2>

            <div class='files'>
                {props.files.length > 0 ? props.files.map((f, i) => {
                    return data.editing ?
                        <a onClick={() => selectFile(f.url)}
                           class={data.selected_files.includes(f.url) ? 'selected' : ''}>
                            <div key={i}>
                                {ICON("document")}
                                <span>{f.filename}</span>
                            </div>
                        </a> :
                        <a href={`/files/${f.url}`} download>
                            <div key={i}>
                                {ICON("document")}
                                <span>{f.filename}</span>
                            </div>
                        </a>
                }) : <p class='no-file'>{t("You don't have any files")}</p>}
            </div>

            {props.editable && !data.editing && <div class='upload-files'>
                <div class='upload-files-buttons'>
                    {data.uploaded_files.length > 0 && <IconButton class="clear" onClick={() => {
                        data._upload_files_input.value = null
                        data.uploaded_files = []
                    }}>{ICON("undo")}</IconButton>}
                    <FileButton icon={ICON("circle-plus")} onChange={(x) => {
                        data._upload_files_input = x.target
                        data.uploaded_files = Array.from(x.target.files)
                    }}/>
                    <Upload files={data.uploaded_files} params={props.params} afterUpload={() => {
                        data._upload_files_input.value = null
                        data.uploaded_files = []
                    }}
                    />
                </div>
                <div className='files-to-upload'>
                    {data.uploaded_files.map((file) => <p>{file.name}</p>)}
                </div>
            </div>}
        </div>
    }
}



