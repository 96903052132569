import { onMounted, ref } from '@vue/runtime-core'
import { t } from '../i18n'
import { getRouter } from '../utils/router'
import './Button.scss'
import { ICON } from "./icons"

export const ToolButton = ({...rest}, {slots}) => <BaseButton class="btn toolbutton" {...rest}>{slots?.default?.()}</BaseButton>
export const IconButton = ({...rest}, {slots}) => <BaseButton class="btn iconbutton" {...rest} type="button">{slots?.default?.()}</BaseButton>


export const Button = ({secondary=false, ...rest}, {slots}) => <BaseButton 
  class={{btn:true,"btn-primary":!secondary,"btn-secondary":secondary}}
  type="button"
  {...rest}
  >
    {slots?.default?.()}
</BaseButton>

export const DeleteButton = <IconButton class="delete">{ICON("delete")}</IconButton>

export const FileButton = ({onChange, icon}) => <IconButton class="file" onClick={(e)=> {
  const input = e.target.querySelector('input')
  input && input.click()
}}>
  {icon || ICON("attach")}
  <input type="file" multiple hidden onChange={onChange}/>
</IconButton>

// Private

export const BaseButton = {
  props:['tooltip', 'to'],
  setup(props,{slots}) {
    const btn = ref()
    let timeout;

    onMounted(()=>{
      const e = btn.value
      if(!props.tooltip || !e) return;
      const tooltip = new bootstrap.Tooltip(e,{trigger:"manual"})
      $(e).click(()=>{
        clearTimeout(timeout)
        timeout = null;
        tooltip.hide()
      })
      $(e).mouseenter(()=>{
        if(timeout) clearTimeout(timeout)
        timeout = setTimeout(()=>{tooltip.show(); timeout=null;},500)
      })
      $(e).mouseleave(()=>{
        clearTimeout(timeout)
        timeout = null;
        tooltip.hide()
      })
    })

    return ()=>{
      const {tooltip, to, ...rest} = props
      const onClick = to && (()=>getRouter().push(to))
      return <button title={t(tooltip)} ref={btn} onClick={onClick} {...rest}>
          {slots?.default?.()}  
      </button>
    }
  }
}


