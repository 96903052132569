import { LiveQuery } from '../api/livequery'
import { model, API } from '../model'
import { AddButton } from '../ui/AddButton'
import { Avatar } from '../ui/Avatar'
import { DataTable } from '../ui/DataTable'
import { D } from '../ui/dates'
import { Spinner } from '../ui/Spinner'
import { Switch } from '../ui/Switch'
import { optionsFromQueryParams } from '../utils/queryParams'
import { goTo } from '../utils/routing'
import {Link} from "../utils/router"
import {link} from "../utils/routing"
import { t } from '../i18n'
import './Users.scss'
import { ICON } from '../ui/icons'
import { Button } from '../ui/Button'
import { INFO } from '../ui/Toasts'

export const Users = {
    setup() {
        
        const lq = LiveQuery("users", `{
                count, pages, items {
                    id, display_name, locked, avatar, email, created_at, is_admin, is_supervisor, activated, password_reset_requested, last_login, last_active
                }
            }`, x=>model.users=x, 
            ()=>[{
                ...optionsFromQueryParams(),
                limit: 20,
                sort:"display_name",
                order:"asc"
            }, {}], null, true)

        function newUser() { goTo("/newuser") }
        function toggleLocked(user) { API.setUser(user.id, {locked:!user.locked})}
        function toggleAdmin(user) {
            const message = `You are about to ${user.is_admin ? 'remove' : 'grant'} admin rights to ${user.display_name}\n`
            if (confirm(message + "Are you sure ?")) {
                API.toggleAdmin(user.id)
            }
        }
        function toggleSupervisor(user) {
            const message = `You are about to ${user.is_supervisor ? 'remove' : 'grant'} supervisor rights to ${user.display_name}\n`
            if (confirm(message + "Are you sure ?")) {
                API.toggleSupervisor(user.id)
            }
        }

        async function resend_activation_email(user) { 
            await API.resendActivationEmail(user.id) 
            INFO(t("Activation email sent to ") + user.email)
        }

        async function reset_password(user) { 
            await API.reset_password(user.id) 
            INFO(t("Password reset email sent to ") + user.email)
        }

        return ()=>{
            return <div id="users">
                <DataTable 
                    loading={lq.loading}
                    header={<>
                    <AddButton secondary onClick={newUser}>{t("New user")}</AddButton>
                    </>}
                    data={model.users}
                    columns={[
                        {title:t("Avatar"), render:x=><Avatar user={x}/>},                        
                        {title:t("Name"), render:x=><Link href={link(`/user/${x.id}`)}>{x.display_name}</Link>},
                        {title:t("Email"), render:x=><Link href={link(`/user/${x.id}`)}>{x.email}</Link>,},
                        {title:t("Admin"), noLink:true, render:x=><Switch value={x.is_admin} disabled={x.id === model.user.id} onClick={()=>toggleAdmin(x)}/>},
                        {title:t("Supervisor"), noLink:true, render:x=><Switch value={x.is_supervisor} disabled={x.id === model.user.id} onClick={()=>toggleSupervisor(x)}/>},
                        {title:t("Locked"), noLink:true, render:x=><Switch value={x.locked} disabled={x.id === model.user.id} onClick={()=>toggleLocked(x)}/>},
                        {title:t("Activated"), noLink:true, render:x=>x.activated ? ICON("ok") : ICON("ko")},
                        {title:t("Created"), render:x=>D(x.created_at)},
                        {title:t("Last signin"), render:x=>D(x.last_login)},
                        {title:t("Last activity"), render:x=>D(x.last_active)},
                        {render:x=><>
                            {x.password_reset_requested && <i>{t('Password reset requested')}</i>}
                            {x.activated ? <Button secondary onClick={()=>reset_password(x)}>{t("Reset password")}</Button>
                            : <Button secondary onClick={()=>resend_activation_email(x)}>{t("Resend activation email")}</Button>}
                            </>
                        },
                    ]}
                />
            </div>
        }
    }
}