import { reactive, ref } from '@vue/reactivity';
import { BaseButton, IconButton } from './Button'
import './Dropdown.scss'
import { Teleport } from './Modal';
import { createPopper } from '@popperjs/core';
import { Link } from '../utils/router';


export const Dropdown = {
    props:['button', 'tooltip', 'iconbutton', 'slim', 'solid', 'top', 'menuClass', 'onOpen', 'onClose', 'class'],
    setup(props, {slots}) {
        const data = reactive({open:false})
        const btn = ref()

        function open() {
            data.open = true
            props.onOpen?.()
        }

        function toggle() {
            if(data.open) close();
            else open()
        }

        function close() {
            data.open = false
            $(window).off("click", close)
            $(window).off("keydown", handleKeydown)
            props.onClose?.()
        }

        function handleKeydown(e) {
            if(e.keyCode===27) close();
        }

        function positionMenu(el) {
            if(!btn.value || !el) return;
            createPopper(btn.value, el, {
                placement:"bottom-start"
            });
            setImmediate(()=>{
                $(window).one("click", close);
                $(window).one("keydown", handleKeydown);
            })
        }

        return ()=>{
            const {button, tooltip, iconbutton, slim=false, solid=false, top} = props

            const Button = 
                iconbutton ? props => <IconButton {...props}>{iconbutton}</IconButton>
                : props => <BaseButton {...props}>{button}</BaseButton>

            return <>
            <Button ref={btn} secondary tooltip={tooltip} class="btn btn-secondary dropdown-toggle" class={{solid, slim}} class={props.class} type="button"
                onClick={(e)=>{toggle(); e.stopPropagation(); }}
            />
            {data.open &&
            <Teleport>
                <ul class="ui-dropdown-menu show" class={props.menuClass} ref={positionMenu} onClick={close}>
                    {top && <div class="top" 
                        onKeyUpCapture={e=>{e.stopPropagation();e.preventDefault();}}
                        onClickCapture={e=>{e.stopPropagation();e.preventDefault();}}
                    >{top}</div>}
                    <div class="content">
                        {slots.default?.()}
                    </div>
                </ul>
            </Teleport>}
        </>
        }
    }
}


export const DropdownItem = ({to},{slots}) => (
    <li>
        <Link class="dropdown-item" href={to}>
            {slots.default?.()}
        </Link>
    </li>
)
