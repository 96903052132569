import { t } from "../i18n"
import { model } from "../model"
import { toSnakeCase } from "../util"
import './Drilldown.scss'
import { Dropdown, DropdownItem } from "./Dropdown"
import { ICON } from "./icons"

export const Drilldown = ({k, v}) => {
    const tools = model.config?.drilldown?.[k]
    return <div class='drilldown'>
        <span>{v}</span>
        <div class='tools'>
        {tools?.length>0 && <Dropdown menuClass="drilldown-tools" iconbutton={ICON("case")} tooltip={t('Investigate via external tools')}>
            {tools.map(({icon,url,name})=> {
                let forged_url = url.replace(/%{([^%{}]+)}/, (_,match)=>({
                    value:()=>v,
                    value_snake_case:()=>toSnakeCase(v),
                    value_plus_case:()=>v.replace(/\s/g, "+"),
                    urlencode_value:()=>encodeURIComponent(v),
                    kibana:()=>forgeKibanaDrilldown(k,v),
                })[match]?.()||"")
                forged_url = forged_url.replace(/\){sort=(.*)}/, (_,match)=>`,sort:!(!(${match})))`)
                return <DropdownItem to={forged_url}>
                    <img src={icon}/>{name}
                </DropdownItem>
            }
        )}
        </Dropdown>}
        </div>
    </div>
}
    

// private

function forgeKibanaDrilldown(key, value) {
    const tfrom = alert.timestamp && new Date(+new Date(alert.timestamp) - 5*60*1000).toISOString()
    const tto = alert.timestamp && new Date(+new Date(alert.timestamp) + 5*60*1000).toISOString()
    return `s/${model.scope?.kibana_space}/app/discover#/?` + encodeKibanaQuery({
        query:{[key]:value}, from:tfrom, to:tto
    })
}

const encodeKuery = (params={}) => "'" + 
    Object.entries(params).map(([k,v])=>`${k}:%20${v}`).join(",%20") 
+ "'"

const encodeKibanaQuery = (params={}) => {
    if(!params._g || !params._a) return encodeKibanaQuery(createKibanaQuery(params))
    const encode = v => typeof v === "string" ? v : ("(" + 
        Object.entries(v).map(([k,v])=>`${k}:${encode(v)}`).join(',')
    + ")")
    return Object.entries(params).map(([k,v])=>`${k}=${encode(v)}`).join("&")
}

const createKibanaQuery = ({from,to,query}) => ({
    _g:from && to ? {
        filters:'!()',
        time:{
            from:"'"+from+"'",
            to:"'"+to+"'"
        }
    } : {},
    _a:{
        columns:'!(_source)',
        filters:'!()',
        interval:'auto',
        query:{
            language:'kuery',
            query: encodeKuery(query)
        },
    }
})
