import { t } from "../i18n";
import {Link} from "../utils/router"
import {link} from "../utils/routing"
import { Section } from "./Accordion";
import { Badge } from "./Badge";
import { Button } from "./Button";
import { DataTable } from "./DataTable";
import { D } from "./dates";
import { FilteredDropdown } from "./FilteredDropdown";
import { Flex } from "./Flex";
import { ICON } from "./icons";
import './Related.scss'


export const Related = ({editable, type, page, setPage, title, items=[], query, onAdd, footer, onDelete, render, renderCombo, addTooltip, update, noHeader, watch}) => {

    const default_combo_render = x => 
        typeof(x) === "string" ? x :
        Flex([
            [ <>{ICON(type)} <div class='flex'>{x.title}</div></>, D(x.created_at, "short") ],
            <div class="multiline-ellipsis">{x.description}</div>
        ])
    
    const default_columns = [
        {render:x=><>
            {ICON(type)} 
            {typeof(x)==="string" ? x : 
            <Link href={x.id && x.scope_id && link(`/scope/${x.scope_id}/${type}/${x.id}`)}>
                {x.title}
            </Link>}
        </>},
        {type:"date", render:x=>x.created_at||x.first_detection},
    ]

    return <Section class="related" title={t(title)} badges={<>
        <Badge>{(Array.isArray(items) ? items.length : items.count)||0}</Badge>
        {(items.search && items.total && <span>/<Badge>{items.total}</Badge></span>)||null}
        <_/>

        {editable && onAdd && query && <FilteredDropdown tooltip={addTooltip} iconbutton={ICON("add")} query={query} onSelect={onAdd}
            render={renderCombo || render || default_combo_render} 
            footer={footer}
        />}
    </>}>
        <DataTable class="no-search-single-page"
            data={items} 
            columns={render ? [{render}] : default_columns}  
            onDelete={editable && onDelete}
            update={update}
            noHeader={!update || noHeader}
            setPage={setPage}
            page={page}
        />
    </Section>
}


const _ = ()=><div class="flex"/>

