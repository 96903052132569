import { createApp } from "vue"
import './Modal.scss'
import {default as Teleporter} from "./teleport.vue"

export function modal(node=({close})=>{}) {
    return new Promise((resolve,reject)=>{
        const el = document.querySelector("body > #modal")
        function close(result) { 
            app.unmount()
            el.classList.remove("show")
            el.textContent = ""
            setTimeout(()=>{el.style.display="none"}, 500)
            resolve(result)
        }
        const app = createApp(()=>node({close}));
        app.mount(el)
        el.style.display="flex";
        setTimeout(()=>{el.classList.add("show")})
    })
}

export const Teleport = ({trigger},{slots}) => <Teleporter v-slots={{trigger:()=>trigger}}>{slots.default?.()}</Teleporter>